import moment from "moment";
import { ErrorOption } from "react-hook-form";

type Props = {
  formElements: Array<{
    slug: string;
    important: boolean;
  }>;
  formData: { [x: string]: any };
  setError: (
    name: string,
    error: ErrorOption,
    options?:
      | {
          shouldFocus: boolean;
        }
      | undefined
  ) => void;
};

export function checkForm({ formElements, formData, setError }: Props) {
  let error = false;
  function isContain(slug: string) {
    return formElements
      .map((e) => e.slug.toLowerCase())
      .includes(slug.toLowerCase());
  }
  function isImportant(slug: string) {
    return !!formElements.filter(
      (e) => e.slug.toLowerCase() === slug.toLowerCase()
    )?.[0]?.important;
  }

  if (isContain("arrival_day") && isImportant("arrival_day")) {
    if (!!!formData.arrival_day) {
      error = true;
      setError("arrival_day", {
        message: "Entrer le Jour d'arriver",
      });
    } else if (!moment(formData.arrival_day).format("DD/MM/YYYY")) {
      error = true;
      setError("arrival_day", {
        message: "Entrer correctement le jour d'arriver",
      });
    }
  }
  if (isContain("departure_day") && isImportant("departure_day")) {
    if (!!!formData.departure_day) {
      error = true;
      setError("departure_day", {
        message: "Entrer le Jour de depart",
      });
    } else if (!moment(formData.departure_day).format("DD/MM/YYYY")) {
      error = true;
      setError("departure_day", {
        message: "Entrer correctement le Jour de depart",
      });
    }
  }
  if (isContain("number_person") && isImportant("number_person")) {
    if (!!!formData.number_person) {
      error = true;
      setError("number_person", {
        message: "Entrer le Nombre de personne",
      });
    } else if (!(parseInt(formData.number_person) >= 1)) {
      error = true;
      setError("number_person", {
        message: "Doit etre superieur a 0",
      });
    }
  }

  if (isContain("arrival_time") && isImportant("arrival_time")) {
    if (!!!formData.arrival_time) {
      error = true;
      setError("arrival_time", {
        message: "Entrer l'heure de votre arriver",
      });
    } else if (!moment(formData.arrival_time).format("DD/MM/YYYY")) {
      error = true;
      setError("arrival_time", {
        message: "Entrer correctement l'heure de votre arriver",
      });
    }
  }

  if (isContain("departure_time") && isImportant("departure_time")) {
    if (!!!formData.departure_time) {
      error = true;
      setError("departure_time", {
        message: "Entrer l'heure de votre depart",
      });
    } else if (!moment(formData.departure_time).format("DD/MM/YYYY")) {
      error = true;
      setError("departure_time", {
        message: "Entrer correctement l'heure de depart",
      });
    }
  }

  return error;
}
