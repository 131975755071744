import React from "react";
import { ServiceModel } from "../../../../../../../../models/models";
import { useCoinStore } from "../../../../../../../../configs/stores/coin";
import CoinServiceModal from "../../../../../../../../components/modals/models/service";

type Props = {
  service: ServiceModel;
};

const CoinService = ({ service }: Props) => {
  const [isOpen, setisOpen] = React.useState(false);
  const addService = useCoinStore((e) => e.addService);
  const removeService = useCoinStore((e) => e.removeService);
  const addedServices = useCoinStore((e) => e.addedServices);
  const exist = addedServices.find((e) => e.service_id === service.service_id);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="keen-slider__slide number-slide bg-white max-w-[290px] min-w-[290px] h-[350px] flex flex-col border rounded-xl overflow-hidden">
        <div className="flex h-[60%] w-full relative">
          <img
            className="flex flex-1 object-cover"
            src={service.service_image.image_link.toString()}
            alt={service.service_name.toString()}
          />
          <div className="flex absolute w-full pb-3 pt-3 items-center pl-4 bottom-0 h-[40px] bg-gradient-to-b from-transparent via-black/30 to-black/50">
            <span
              onClick={() => setisOpen(true)}
              className="text-[13px] cursor-pointer text-white leading-tight underline underline-offset-2 line-clamp-2"
            >
              Afficher
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col px-2 pl-4 pr-3 pt-3 pb-2 space-y-3">
          <div className="flex flex-col space-y-1">
            <span className="flex text-[18px] font-[600]">
              {service.service_name}
            </span>
            <span className="text-[15px] leading-tight line-clamp-2">
              {service.service_description}
            </span>
          </div>
          <div className="flex flex-row justify-between items-center">
            <span
              onClick={() =>
                !exist ? addService(service) : removeService(service.service_id)
              }
              className="text-[15px] cursor-pointer text-primary w-fit px-2 py-[4px] rounded-md font-medium border"
            >
              {!exist ? "+ Ajouter au panier" : "Retirer du panier"}
            </span>
            <span className="text-[16px] font-medium line-clamp-1">
              {service.service_price} FCFA
            </span>
          </div>
        </div>
      </div>
      <CoinServiceModal
        open={isOpen}
        service={service}
        handleClose={closeLogin}
      />
    </React.Fragment>
  );
};

export default CoinService;
