import React from "react";
import {
  SearchResultType,
  useSearchStore,
} from "../../../../../configs/stores/search";
import "./style.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useDimensions } from "../../../../../hooks/useDimensions";

type Props = {
  item: SearchResultType;
};

const ResultItem = ({ item }: Props) => {
  const { searchTerm } = useSearchStore();
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  const { category, comments = 0, evaluation = 0, images = [] } = item;
  const company = category.category_company;
  const category_type = category.category_type;
  const startIndex = item.category.category_company.company_name
    .toLowerCase()
    .indexOf(searchTerm.toLowerCase());
  const endIndex = startIndex + searchTerm.length;
  function addItem() {}
  return (
    <a
      href={
        "/coin/" +
        item.category.category_company.company_slug +
        "?category=" +
        category_type.categorytype_slug
      }
      onClick={addItem}
      className="flex flex-row cursor-pointer transition-shadow duration-100 lg:space-x-3 bg-white"
    >
      <div className="hidden lg:flex min-h-[70px] min-w-[70px] h-[70px] w-[70px] border rounded-full overflow-hidden">
        <img
          src={company.company_logo.image_link.toString()}
          alt={company.company_name.toString()}
          className="flex flex-1 object-cover"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-1 pt-[6px] leading-none">
        <div className="flex flex-row max-lg:space-x-3">
          <div className="flex lg:hidden min-h-[35px] min-w-[35px] h-[35px] w-[35px] border rounded-full overflow-hidden">
            <img
              src={company.company_logo.image_link.toString()}
              alt={company.company_name.toString()}
              className="flex flex-1 object-cover"
            />
          </div>
          <div className="flex flex-col pt-[2px] space-y-[3px]">
            <span className="flex flex-row items-center font-semibold text-[12px] lg:text-[14px] uppercase">
              {startIndex !== -1 && startIndex !== endIndex ? (
                <>
                  {company.company_name.substring(0, startIndex)}
                  <span className="bg-primary leading-none text-white px-[1.5px] py-px pt-[1px] mx-[1.5px] rounded-[3px]">
                    {company.company_name.substring(startIndex, endIndex)}
                  </span>
                  {company.company_name.substring(endIndex)}
                </>
              ) : (
                company.company_name
              )}
            </span>
            <span className="flex font-medium  text-[13px] lg:text-[14px]">
              {category_type.categorytype_name}
            </span>
          </div>
        </div>
        <span className="text-[14px] pt-1 leading-tight line-clamp-2">
          {category.category_description}
        </span>
        <div ref={refDim} className="flex flex-1 pt-2">
          <div
            ref={ref}
            className="keen-slider flex"
            style={{ maxWidth: (dimensions?.width ?? 10) }}
          >
            {images.map((e) => {
              return (
                <div
                  key={e.image_id.toString()}
                  className="keen-slider__slide number-slide flex min-h-[100px] min-w-[140px] h-[100px] w-[140px] max-w-[140px] rounded-md overflow-hidden"
                >
                  <img
                    className="flex flex-1 object-cover"
                    src={e.image_link.toString()}
                    alt={company.company_name.toString()}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-row items-center space-x-3 pt-2 pb-4 border-b">
          <div className="flex flex-row items-center space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="flex w-[14px] h-[14px]"
            >
              <path
                fillRule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                clipRule="evenodd"
              />
            </svg>
            <span className="flex text-[15px] max-lg:text-[14px] font-normal line-clamp-1">
              {evaluation}
            </span>
          </div>
          <div className="flex flex-row items-center space-x-1">
            <span className="flex text-[15px] max-lg:text-[14px] font-normal line-clamp-1">
              {comments}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-[17px] h-[17px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
              />
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ResultItem;
