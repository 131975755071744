import React from "react";
import ModalCustom from "../../modal";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { ReservationFormComponentTitle } from "../../../../pages/home/pages/coin/body/laptop/reservationform/components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LoginModal from "../login";
import moment from "moment";
import PaymentModes from "./paymentModes";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useCoinStore } from "../../../../configs/stores/coin";
import { CategoryModel, CompanyModel } from "../../../../models/models";
import { ToastWarnNotifier, primaryColor } from "../../../../constants";
import CoinAddedPlacesModal from "../addedplaces";
import CoinAddedServicesModal from "../addedservices";
import { usePaymentHook } from "../../../../hooks/usePaymentHook";

type Props = {
  handleClose: () => void;
  open: boolean;
  coinForm: UseFormReturn<FieldValues, any, undefined>;
};

const ReservationResumeModal = ({
  handleClose = () => {},
  coinForm,
  open = false,
}: Props) => {
  const { startPayment } = usePaymentHook();
  const { isAuthenticated, refetchAllReservation } = useAuthStore();
  const [isLoadingReservationSaving, setIsLoadingReservationSaving] =
    React.useState(false);
  const coin = useCoinStore((e) => e.coin);
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  const cardPaymentForm = useCoinStore((e) => e.cardPaymentForm);
  const newReservation = useCoinStore((e) => e.newReservation);
  const [isCheckingPlaces, setisCheckingPlaces] = React.useState(false);
  const checkPlaces = useCoinStore((e) => e.checkPlaces);
  const category = (coin?.category ?? {}) as CategoryModel;
  const company = (coin?.company ?? {}) as CompanyModel;
  const addedServices = useCoinStore((e) => e.addedServices);
  const formValues = coinForm?.getValues() ?? {};
  const serviceBilling = category?.category_service_billing;
  const service_billing = {
    rate: !!serviceBilling
      ? serviceBilling.servicebilling_rate +
          serviceBilling.servicebilling_mode ===
        "percent"
        ? "%"
        : serviceBilling.servicebilling_currency.currency_isocode
      : null,
    price: !!serviceBilling
      ? addedServices.reduce<number>((prev, cur, index, arr) => {
          return prev + serviceBilling.servicebilling_mode === "percent"
            ? (cur.service_price *
                parseFloat(serviceBilling.servicebilling_rate)) /
                100
            : cur.service_price +
                parseFloat(serviceBilling.servicebilling_rate);
        }, 0)
      : 0,
  };
  const formValuesRefactorized = Object.keys(formValues)?.reduce<
    Array<{
      value: string;
      title: string;
      name: string;
    }>
  >((prev, curr) => {
    const value = moment(formValues[curr], moment.ISO_8601, true).isValid()
      ? curr.includes("day")
        ? moment(formValues[curr]).format("LL").toUpperCase()
        : moment(formValues[curr]).format("LT").toUpperCase()
      : !!formValues[curr] && String(formValues[curr]);
    const title =
      ReservationFormComponentTitle.find((e) => e.slug === curr)?.label ?? "";
    return !!value
      ? prev.concat({
          value,
          title,
          name: curr,
        })
      : prev;
  }, []);
  const [isOpen, setisOpen] = React.useState(false);
  const addedPlacesLength = addedPlaces.reduce<number>(
    (pre, cur, index, arr) => {
      return pre + cur.length;
    },
    0
  );
  const [isOpenAddedPlacesModal, setisOpenAddedPlacesModal] =
    React.useState(false);
  function closeAddedPlacesModal() {
    setisOpenAddedPlacesModal(false);
  }
  const [isOpenAddedServiceModal, setisOpenAddedServiceModal] =
    React.useState(false);
  function closeAddedServiceModal() {
    setisOpenAddedServiceModal(false);
  }
  function closeLogin() {
    setisOpen(false);
  }

  async function onSubmit() {
    if (category?.category_reservation_active === false) {
      ToastWarnNotifier({
        message: `Désolé, les réservations sont temporairement inactives chez ${company?.company_name} - ${category.category_type.categorytype_name}`,
      });
      return false;
    }
    
    if (!isAuthenticated) {
      setisOpen(true);
      return false;
    }

    setisCheckingPlaces(true);
    const place = addedPlaces.map((e) => ({
      place: e.place.place_id,
      length: e.length,
    }));
    const arrival_day = moment(formValues.arrival_day);
    const day = arrival_day.format("YYYY-MM-DD");
    const result = await checkPlaces(day, place);
    setisCheckingPlaces(false);
    if (!result.success) {
      return;
    }
    const departure_day = !!formValues?.departure_day
      ? moment(formValues.departure_day)?.format("YYYY-MM-DD")
      : "";
    const arrival_time = !!formValues?.arrival_time
      ? moment(formValues.arrival_time)?.format("HH:mm")
      : "";
    setIsLoadingReservationSaving(true);
    const res = await newReservation({
      reservation_category: category.category_id,
      reservation_currency: company.company_currency?.currency_id ?? undefined,
      reservation_price: 10000 + service_billing.price,
      reservation_arrival_day_timestamp: arrival_day.valueOf(),
      reservation_arrival_day: arrival_day.format("YYYY-MM-DD"),
      reservation_arrival_time: arrival_time,
      reservation_departure_day: departure_day,
      reservation_places: addedPlaces.map((e) => ({
        place: e.place.place_id,
        length: e.length,
      })),
      reservation_services: addedServices.map((e) => e.service_id.toString()),
    });
    refetchAllReservation();
    setIsLoadingReservationSaving(false);
    if (!res) {
      return;
    }
    const data = {
      cardPaymentForm,
      reservation: res,
    };
    startPayment(data);
  }
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormProvider {...coinForm}>
            <div className="flex w-[90vw] h-min min-w-[280px] max-w-[550px] max-md:w-[100vw] max-md:max-w-[90vw] max-md:px-[15px] flex-col space-y-4 px-[20px] py-3 pt-0">
              <div className="flex flex-col space-y-3 flex-1">
                <span className="flex text-[20px] font-medium">
                  Résumé de votre réservation
                </span>
                <a
                  href={`/coin/${company.company_slug}?category=${category.category_type.categorytype_slug}`}
                >
                  <span className="flex text-[16px] font-medium leading-tight text-primary">
                    {category.category_type.categorytype_name} -{" "}
                    {company.company_name}
                  </span>
                </a>
                <div className="flex flex-col h-[60vh] max-h-[500px] max-md:h-[65vh] max-md:max-h-[65vh] overflow-y-auto pr-2 space-y-3">
                  <div className="flex flex-col space-y-2">
                    {formValuesRefactorized.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row w-full space-x-3 justify-between items-center"
                        >
                          <span className="flex text-[16px]">
                            {item.title} :
                          </span>
                          <span className="flex text-[15px] font-semibold">
                            {item.value}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex rounded-[6px] items-center justify-between">
                    <div className="flex flex-col">
                      <span className="flex text-[15px]">
                        {addedPlacesLength !== 0
                          ? addedPlacesLength + " Place(s) ajoutée(s)"
                          : "Aucune place ajoutée"}
                      </span>
                      <span className="flex text-[13px] pl-3 font-medium">
                        Réservation: 10000 FCFA
                      </span>
                    </div>
                    <svg
                      onClick={
                        addedPlacesLength !== 0
                          ? () => {
                              setisOpenAddedPlacesModal(true);
                            }
                          : undefined
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke={addedPlacesLength !== 0 ? primaryColor : "#ccc"}
                      className="w-5 h-5 cursor-pointer active:stroke-active"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </div>
                  {addedServices.length !== 0 && (
                    <div className="flex rounded-[6px] items-center justify-between">
                      <div className="flex flex-col">
                        <span className="flex text-[15px]">
                          {addedServices.length} Service(s) ajouté(s)
                        </span>
                        {service_billing.rate && (
                          <span className="flex text-[13.5px] pl-3 font-medium">
                            {service_billing.rate} par service ajouté
                          </span>
                        )}
                        <span className="flex text-[13.5px] pl-3 font-medium">
                          Réservation: {service_billing.price} FCFA
                        </span>
                      </div>
                      <svg
                        onClick={() => {
                          setisOpenAddedServiceModal(true);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke={primaryColor}
                        className="w-5 h-5 cursor-pointer active:stroke-active"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </div>
                  )}
                  <div className="flex flex-row items-center text-primary justify-between py-2 border-t border-b">
                    <div className="flex leading-tight flex-col font-semibold">
                      <span className="flex text-[15px]">
                        Total Réservation
                      </span>
                    </div>
                    <span className="flex text-[14px] font-semibold">
                      {10000 + service_billing.price}
                      {" FCFA"}
                    </span>
                  </div>
                  <div className="flex leading-tight flex-col font-semibold pt-4">
                    <span className="flex text-[15px]">
                      NB: Apres avoir cliquer sur le bouton "Valider ma
                      réservation" vous avez 5min pour effectuer le paiement.
                      Au-dela du temps imparti vous perdez vos places réserver.
                      Merci pour votre comprehension
                    </span>
                  </div>
                  <PaymentModes />
                </div>
              </div>
              <button
                onClick={() => onSubmit()}
                className="flex w-full rounded-md bg-primary disabled:bg-gray-400 hover:bg-hover active:bg-active items-center justify-center py-[10px]"
              >
                <span className="flex font-semibold text-white no-underline">
                  {!isLoadingReservationSaving
                    ? !isCheckingPlaces
                      ? "Valider ma réservation"
                      : "Vérification des places..."
                    : "Patientez un instant..."}
                </span>
              </button>
            </div>
          </FormProvider>
        </LocalizationProvider>
        <LoginModal open={isOpen} handleClose={closeLogin} />
        <CoinAddedPlacesModal
          open={isOpenAddedPlacesModal}
          isCancelable={false}
          handleClose={closeAddedPlacesModal}
        />
        <CoinAddedServicesModal
          open={isOpenAddedServiceModal}
          handleClose={closeAddedServiceModal}
        />
      </React.Fragment>
    </ModalCustom>
  );
};

export default ReservationResumeModal;
