import React from "react";
import FormDayInput from "./days";
import FormTimeInput from "./time";
import FormNumberInput from "./number";

export const ReservationFormComponentTitle = [
  { slug: "arrival_day", label: "Jour d'arrivée" },
  { slug: "number_person", label: "Nombre de personne" },
  { slug: "departure_day", label: "Jour de depart" },
  { slug: "arrival_time", label: "Heure d'arrivée" },
  { slug: "departure_time", label: "Heure de depart" },
];

type Props = {
  slug: string;
  [x: string]: any;
};

const ReservationFormComponent = ({ slug, ...props }: Props) => {
  switch (slug) {
    case "arrival_day":
      return (
        <FormDayInput
          label={"Jour d'arrivée " + (props.required ? "" : "(facultatif)")}
          {...props}
          {...{ slug }}
        />
      );
    case "number_person":
      return (
        <FormNumberInput
          label={"Nombre de personne"}
          {...props}
          {...{ slug }}
        />
      );
    case "departure_day":
      return (
        <FormDayInput
          label={"Jour de depart " + (props.required ? "" : "(facultatif)")}
          {...props}
          {...{ slug }}
        />
      );
    case "arrival_time":
      return (
        <FormTimeInput
          label={"Heure d'arrivée " + (props.required ? "" : "(facultatif)")}
          {...props}
          {...{ slug }}
        />
      );
    case "departure_time":
      return (
        <FormTimeInput
          label={"Heure de depart " + (props.required ? "" : "(facultatif)")}
          {...props}
          {...{ slug }}
        />
      );
    default:
      return null;
  }
};

export default ReservationFormComponent;
