import React from "react";

const ShowMore = () => {
  return (
    <button className="flex outline-none flex-row items-center space-x-3 p-3 px-8 rounded-full bg-white active:bg-activeGrey shadow-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path
          fillRule="evenodd"
          d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
          clipRule="evenodd"
        />
      </svg>
      <span className="flex text-[18px] max-lg:text-[15px] text-black font-medium">
        Afficher plus de resultats
      </span>
      
    </button>
  );
};

export default ShowMore;
