import React from "react";
import ResultItem from "./item";
import { SearchResultType } from "../../../../../configs/stores/search";

type Props = {
  results: Array<SearchResultType>;
};

const SearchCompanyResults = ({ results }: Props) => {
  return (
    <div className="flex flex-1 flex-col space-y-3 px-4 lg:px-5 py-3 mr-1 overflow-y-auto overflow-hidden">
      <div className="flex flex-row pt-2 justify-between w-full">
        <span className="flex text-[13px]">Résultats de recherches</span>
      </div>
      <div className="flex flex-col space-y-3">
        {results.map((result) => {
          return (
            <ResultItem
              key={result.category.category_id.toString()}
              item={result}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SearchCompanyResults;
