import { MobileTimePicker } from "@mui/x-date-pickers";
// import moment from "moment";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentShowItemsLengthPerRow } from "../../../../../../../../functions/useLayoutDimension";

type Props = {
  [x: string]: any;
};

const FormTimeInput = ({ ...props }: Props) => {
  const { control } = useFormContext();
  const flex = useCurrentShowItemsLengthPerRow();
  return (
    <Controller
      control={control}
      render={({
        field: { onChange, value },
        formState: {
          errors: { [props.slug]: error = null },
        },
      }) => (
        <React.Fragment>
          <div className="flex flex-col w-full space-y-2">
            <MobileTimePicker
              label="Heure"
              format="hh:mm"
              // minTime={moment()}
              onChange={onChange}
              value={value}
              orientation={flex > 2 ? "landscape" : "portrait"}
              {...props}
            />
            {!!error && (
              <span className="flex text-red-500 text-[13px] ml-3 leading-none">
                {error.message?.toString()}
              </span>
            )}
          </div>
        </React.Fragment>
      )}
      name={props.slug}
      {...props}
    />
  );
};

export default FormTimeInput;
