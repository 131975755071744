import queryString from "query-string";
import { getUrl, postUrl } from "../../apiRequest";

export function getCoinDatas(
  companySlug: string,
  category?: string,
  user?: string
) {
  return getUrl(
    "/coin/category?" + queryString.stringify({ companySlug, category, user })
  );
}

export function updateEvaluation(value: string, category?: string) {
  return getUrl(
    "/coin/category/update/evaluation?" +
      queryString.stringify({ value, category })
  );
}

export function newComment(
  comment: string,
  category: string,
  response?: string
) {
  return postUrl("/coin/category/comment/new", { comment, category, response });
}

export function getAllComment(category: string) {
  return getUrl("/coin/category/comment/all?category=" + category);
}

export function getConnexeCategoriesByTypeSlug(
  categoryTypeSlug: string = "all",
  max: number = 6
) {
  return getUrl(
    "/category/all?categorytypeslug=" +
      categoryTypeSlug +
      "&max=" +
      max.toString()
  );
}

export function checkPlaces(category: string, day: string, places: Array<any>) {
  return postUrl("/coin/category/place/checkdisponibility", {
    category,
    day,
    places,
  });
}

export function newReservation(data: any) {
  return postUrl("/coin/category/reservation/new", data);
}

export function updateToPaiedReservation(data: any) {
  return postUrl("/coin/category/reservation/payment/update", data);
}
