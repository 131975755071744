import React from "react";
import ModalCustom from "../../modal";
import { useCoinStore } from "../../../../configs/stores/coin";
import { primaryColor } from "../../../../constants";

const CoinCommentsModal = ({ handleClose = () => {}, open = false }) => {
  const isLoading = useCoinStore((e) => e.isLoadingComments);
  const comments = useCoinStore((e) => e.comments);
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <div className="flex flex-col p-2 space-y-3 w-[450px] h-[500px] max-w-[90vw]">
          <div className="flex flex-row space-x-[15px] items-center">
            <span className="flex text-[18px] font-medium">
              {comments.length} Commentaires
            </span>
            {isLoading && (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={primaryColor}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={primaryColor}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </div>
          <div className="flex flex-col flex-1 border rounded-lg p-3 pl-4 space-y-5 overflow-y-scroll">
            {comments.map((e) => {
              return (
                <div
                  key={e.comment_id.toString()}
                  className="flex flex-row space-x-2"
                >
                  <div className="flex mt-[2px] h-[25px] w-[25px] rounded-full overflow-hidden">
                    <img
                      src={e.comment_user.user_profile.image_link.toString()}
                      alt={e.comment_user.user_surname.toString()}
                    />
                  </div>
                  <div className="flex p-3 pt-2 min-w-[30%] max-w-[80%] bg-slate-200 rounded-lg">
                    <span className="flex text-[15px]">{e.comment_text}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    </ModalCustom>
  );
};

export default CoinCommentsModal;
