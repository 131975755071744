import React from "react";
import { CategoryModel } from "../../../models/models";
import LoginModal from "../../modals/models/login";
import { useAuthStore } from "../../../configs/stores/auth";

type Props = {
  category: CategoryModel;
};

const FavoriteButton = ({ category }: Props) => {
  const [isOpen, setisOpen] = React.useState(false);
  const updateFavoris = useAuthStore((e) => e.updateFavoris);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const favoris = useAuthStore((e) => e.user?.user_favorites ?? []);
  function closeLogin() {
    setisOpen(false);
  }
  function checkFavorite() {
    if (!isAuthenticated) {
      setisOpen(true);
    } else {
      const exist = favoris.find(
        (e) => e.category_id === category?.category_id
      );
      updateFavoris(category, !exist ? "add" : "remove");
    }
  }
  return (
    <React.Fragment>
      <button
        onClick={checkFavorite}
        className="flex cursor-pointer select-none z-[2] bottom-4 right-4 h-[35px] w-[35px] rounded-full items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={
            !!favoris.find((fav) => fav.category_id === category?.category_id)
              ? "orange"
              : "rgb(255 255 255 / 0.3)"
          }
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className="w-8 h-8 transition-all duration-150"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
          />
        </svg>
      </button>
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default FavoriteButton;
