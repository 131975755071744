import React from "react";
import ModalCustom from "../../modal";
import { FormProvider, useForm } from "react-hook-form";
import ReservationFormComponent from "../../../../pages/home/pages/coin/body/laptop/reservationform/components";
import { checkForm } from "../../../../pages/home/pages/coin/body/laptop/reservationform/errors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LoginModal from "../login";
import ReservationResumeModal from "../reservationResume";
import moment from "moment";
import {
  ToastWarnNotifier,
  primaryColor,
} from "../../../../constants";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useCoinStore } from "../../../../configs/stores/coin";
import CoinAddedPlacesModal from "../addedplaces";
import CoinAddedServicesModal from "../addedservices";

const ReservationFormModal = ({ handleClose = () => {}, open = false }) => {
  const MIN_PRICE = 10000;
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const addedServices = useCoinStore((e) => e.addedServices);
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  const checkPlaces = useCoinStore((e) => e.checkPlaces);
  const [isCheckingPlaces, setisCheckingPlaces] = React.useState(false);
  const addedPlacesLength = addedPlaces.reduce<number>(
    (pre, cur, index, arr) => {
      return pre + cur.length;
    },
    0
  );
  const coinFormMethods = useForm();
  const formCompList = [
    { slug: "arrival_day", important: true },
    { slug: "arrival_time", important: true },
    { slug: "departure_day", important: false },
  ];
  const { setError } = coinFormMethods;
  const formElements = React.useMemo(
    () =>
      formCompList.map((item, index) => {
        return (
          <ReservationFormComponent
            key={item.slug + index.toString()}
            slug={item.slug}
            required={item.important}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  const [isOpenAddedPlacesModal, setisOpenAddedPlacesModal] =
    React.useState(false);
  function closeAddedPlacesModal() {
    setisOpenAddedPlacesModal(false);
  }
  const [isOpenAddedServiceModal, setisOpenAddedServiceModal] =
    React.useState(false);
  function closeAddedServiceModal() {
    setisOpenAddedServiceModal(false);
  }
  const [isOpenResume, setisOpenResume] = React.useState(false);
  function closeResume() {
    setisOpenResume(false);
  }
  async function onSubmit(data: any) {
    if (
      checkForm({
        formData: data,
        formElements: formCompList,
        setError,
      })
    ) {
      return false;
    }
    if (addedPlacesLength === 0) {
      ToastWarnNotifier({
        message: "Vous devez ajouter la place que vous souhaitez reserver",
        duration: 7000,
      });
      return false;
    }
    if (!isAuthenticated) {
      setisOpen(true);
      return false;
    }
    setisCheckingPlaces(true);
    const place = addedPlaces.map((e) => ({
      place: e.place.place_id,
      length: e.length,
    }));
    const arrival_day = moment(data.arrival_day);
    const day = arrival_day.format("YYYY-MM-DD");
    const result = await checkPlaces(day, place);
    setisCheckingPlaces(false);
    if (!result.success) {
      return;
    }
    setisOpenResume(true);
  }
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormProvider {...coinFormMethods}>
            <div className="flex w-[90vw] h-min min-w-[280px] max-w-[450px] flex-col space-y-4 px-[10px] py-3 pt-0">
              <span className="flex text-[20px] font-medium">
                Faire une réservation
              </span>
              <div className="flex flex-col space-y-4">{formElements}</div>
              <div className="flex border border-black/20 rounded-[6px] items-center px-3 min-h-[40px] py-3 justify-between">
                <span className="flex text-[15px] font-medium">
                  {addedPlacesLength !== 0
                    ? addedPlacesLength + " Place(s) ajoutée(s)"
                    : "Aucune place ajoutée"}
                </span>
                <svg
                  onClick={
                    addedPlacesLength !== 0
                      ? () => {
                          setisOpenAddedPlacesModal(true);
                        }
                      : undefined
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke={addedPlacesLength !== 0 ? primaryColor : "#ccc"}
                  className="w-5 h-5 cursor-pointer active:stroke-active"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </div>
              {addedServices.length !== 0 && (
                <div className="flex border border-black/20 rounded-[6px] px-3 items-center min-h-[40px] py-3 justify-between">
                  <span className="flex text-[15px] font-medium">
                    {addedServices.length} Service(s) ajouté(s)
                  </span>
                  <svg
                    onClick={() => {
                      setisOpenAddedServiceModal(true);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={primaryColor}
                    className="w-5 h-5 cursor-pointer active:stroke-active"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </div>
              )}
              <div className="flex border-t w-[95%] self-center" />
              <button
                onClick={coinFormMethods.handleSubmit(onSubmit)}
                className="flex flex-col w-full shadow-md rounded-md bg-primary disabled:bg-gray-400 hover:bg-hover active:bg-active items-center justify-center py-[10px]"
              >
                <span className="flex font-bold text-[18px] text-white no-underline">
                  {isCheckingPlaces ? "Vérification des places..." : "Réserver"}
                </span>
                <span className="flex text-[14px] text-white font-semibold">
                  Frais de réservation: {MIN_PRICE} FCFA
                </span>
              </button>
            </div>
          </FormProvider>
        </LocalizationProvider>
        <ReservationResumeModal
          open={isOpenResume}
          coinForm={coinFormMethods}
          handleClose={closeResume}
        />
        <LoginModal open={isOpen} handleClose={closeLogin} />
        <CoinAddedPlacesModal
          open={isOpenAddedPlacesModal}
          handleClose={closeAddedPlacesModal}
        />
        <CoinAddedServicesModal
          open={isOpenAddedServiceModal}
          handleClose={closeAddedServiceModal}
        />
      </React.Fragment>
    </ModalCustom>
  );
};

export default ReservationFormModal;
