import { TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  [x: string]: any;
};

const FormNumberInput = ({ ...props }: Props) => {
  const { control } = useFormContext();
  return (
    <React.Fragment>
      <Controller
        control={control}
        name={props.slug}
        render={({
          field: { onChange, onBlur, value },
          formState: {
            errors: { [props.slug]: error = null },
          },
        }) => (
          <React.Fragment>
            <div className="flex flex-col w-full space-y-2">
              <TextField
                label="Nombre"
                placeholder="1"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                defaultValue={""}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                {...props}
                required={false}
              />
              {!!error && (
                <span className="flex text-red-500 text-[13px] ml-3 leading-none">
                  {error.message?.toString()}
                </span>
              )}
            </div>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default FormNumberInput;
