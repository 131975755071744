import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  LangModel,
  LocalityModel,
  CategoryTypeModel,
} from "../../models/models";
import {
  getAllCategoryType,
  getAllCountries,
  getAllLangs,
  getDefaultDatas,
} from "./functions/basics";

const LOCAL_DATASTORE_NAME = "coinchiclient-basics";

type ContextProps = {
  isLoading: boolean;
  isLoadingCategoryTypes: boolean;
  categoryTypes: Array<CategoryTypeModel>;
  isLoadingLangs: boolean;
  langs: Array<LangModel>;
  isLoadingCountries: boolean;
  countries: Array<LocalityModel>;
  getAll: () => Promise<any>;
  getCategoryTypes: () => Promise<any>;
  getLangs: () => Promise<any>;
  getCountries: () => Promise<any>;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      isLoadingLangs: true,
      langs: [],
      isLoadingCategoryTypes: true,
      categoryTypes: [],
      isLoadingCountries: true,
      countries: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getDefaultDatas();
        const data = (res?.data ?? {
          categoryTypes: [],
          langs: [],
          countries: [],
        }) as {
          categoryTypes: Array<CategoryTypeModel>;
          langs: Array<LangModel>;
          countries: Array<LocalityModel>;
        };
        set(
          produce((state: ContextProps) => {
            state.categoryTypes = data.categoryTypes;
            state.langs = data.langs;
            state.countries = data.countries;
            state.isLoading = false;
          })
        );
        return data;
      },
      getCategoryTypes: async () => {
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingCategoryTypes) {
              state.isLoadingCategoryTypes = true;
            }
          })
        );
        const res = await getAllCategoryType();
        const data = (res?.data ??
          Array<CategoryTypeModel>()) as Array<CategoryTypeModel>;
        set(
          produce((state: ContextProps) => {
            state.categoryTypes = data;
            state.isLoadingCategoryTypes = false;
          })
        );
        return data;
      },
      getLangs: async () => {
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingLangs) {
              state.isLoadingLangs = true;
            }
          })
        );
        const res = await getAllLangs();
        const data = (res?.data ?? Array<LangModel>()) as Array<LangModel>;
        set(
          produce((state: ContextProps) => {
            state.langs = data;
            state.isLoadingLangs = false;
          })
        );
        return data;
      },
      getCountries: async () => {
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingCountries) {
              state.isLoadingCountries = true;
            }
          })
        );
        const res = await getAllCountries();
        const data = (res?.data ??
          Array<LocalityModel>()) as Array<LocalityModel>;
        set(
          produce((state: ContextProps) => {
            state.countries = data;
            state.isLoadingCountries = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
