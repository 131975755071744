import React from "react";
import { useAuthStore } from "../../../../../configs/stores/auth";
import FavorisCategory from "./item";
import FavorisLoading from "./loading";

const UserFavorites = () => {
  const [isRefetching, setIsRefetching] = React.useState(false);
  const favoris = useAuthStore((e) => e.user?.user_favorites ?? []);
  const refetchUser = useAuthStore((e) => e.refetchUser);
  function refetch() {
    setIsRefetching(true);
    refetchUser().then(() => {
      setIsRefetching(false);
    });
  }
  // console.log(favoris);
  return (
    <React.Fragment>
      <div
        className={`flex flex-row bg-green-500/0 pb-8 lg:py-4 w-full border-b justify-center min-h-[calc(100vh-170px)]`}
      >
        <div className="flex flex-col bg-blue-500/0 h-full w-full px-4 xl:px-5 2xl:px-20 max-w-[2520px]">
          <div className="flex flex-col space-y-10">
            <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row justify-between items-center bg-white px-8 py-4 rounded-xl">
              <span className="flex text-[20px] font-medium">Vos favoris</span>
              <button
                onClick={refetch}
                className="flex text-[15px] font-medium text-primary hover:text-hover active:text-active"
              >
                Actualiser les favoris
              </button>
            </div>
            <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-2 mini:grid-cols-3 normal:grid-cols-4 big:grid-cols-5 toobig:grid-cols-6 gap-6">
              {!isRefetching &&
                favoris
                  .filter((e) => typeof e !== "string")
                  .map((item) => {
                    return (
                      <FavorisCategory
                        key={item.category_id.toString()}
                        category={item}
                      />
                    );
                  })}
              {isRefetching &&
                Array.from({ length: 4 }).map((_, index) => {
                  return <FavorisLoading key={index.toString()} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFavorites;
