import React from "react";
import { ReservationModel } from "../../../../../models/models";
import { useAuthStore } from "../../../../../configs/stores/auth";
import moment from "moment";
// import { ReservationFormComponentTitle } from "../../coin/body/laptop/reservationform/components";

const ReservationsPaied = () => {
  const all = useAuthStore((e) => e.reservations);
  const reservations = all.filter((e) => e.reservation_state === "paied");
  return (
    <div className="collapse rounded-lg collapse-arrow border bg-white">
      <input type="checkbox" />
      <div className="collapse-title flex flex-row justify-between text-lg font-medium">
        <span className="flex text-[17px] ml-2">Payés</span>
        <span className="flex font-normal text-[16px]">
          {reservations.length.toString()}
        </span>
      </div>
      <div className="collapse-content space-y-[14px] flex flex-col">
        {/* <span className="flex text-[14px] italic">
          "Dans un instant, vous serez contacter pour finaliser la validation de
          votre réservation. Patientez quelques minutes..."
        </span> */}
        {reservations.map((item, index) => {
          return (
            <ReservationItem key={item.reservation_id} reservation={item} />
          );
        })}
      </div>
    </div>
  );
};

type PropsReservationItem = { reservation: ReservationModel };

function ReservationItem({ reservation }: PropsReservationItem) {
  const categoryType = reservation.reservation_category.category_type;
  return (
    <div className="flex flex-row items-start space-x-4 py-3 px-3 bg-teal-500/10 rounded-[10px]">
      <img
        alt={reservation.reservation_id.toString()}
        src={
          reservation.reservation_category?.category_company?.company_logo?.image_link?.toString() ??
          ""
        }
        className="flex rounded-full h-[70px] w-[70px]"
      />
      <div className="flex flex-col pt-2 leading-tight space-y-1">
        <span className="flex text-[17px] font-semibold">
          {reservation.reservation_category?.category_company.company_name}
        </span>
        <span className="flex text-[16px] font-medium">
          {categoryType.categorytype_name}
        </span>
        <span className="flex text-[16px]">
          {reservation.reservation_transaction_response.description}
        </span>
        <span className="flex text-[15px]">
          Jour d'arrivée: {moment(reservation.reservation_arrival_day).format("LL")}
          {" à "}
          {reservation.reservation_arrival_time}
        </span>
        <span className="flex text-[15px]">
          Date de paiement:{" "}
          {reservation.reservation_transaction_response.payment_date}
        </span>
        <span className="flex text-[15px] font-bold select-none text-teal-800">
          Payé: {reservation.reservation_price} FCFA
        </span>
      </div>
    </div>
  );
}

export default ReservationsPaied;
