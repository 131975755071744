import React from "react";
import ReactMapGL, { Marker, NavigationControl, Popup } from "react-map-gl";
import { useRefLayoutDimension } from "../../../../../../../functions/useLayoutDimension";
import { primaryColor } from "../../../../../../../constants";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import { CompanyModel } from "../../../../../../../models/models";

function CoinLocalisation() {
  const { element, width } = useRefLayoutDimension<HTMLDivElement>();
  const [showPopup, setShowPopup] = React.useState(false);
  const coin = useCoinStore((e) => e.coin);
  const company = (coin?.company ?? {}) as CompanyModel;
  const center = {
    longitude: parseFloat(company.company_geolocation.lng.toString()),
    latitude: parseFloat(company.company_geolocation.lat.toString()),
    zoom: parseFloat(company.company_geolocation.zoom.toString()),
  };
  return (
    <div ref={element} className="flex flex-col space-y-5 px-2 mt-5">
      <span className="flex text-[20px] leading-tight font-medium">
        Localisation de notre établissement
      </span>
      <div
        style={{ height: (width * 3) / 3.2 }}
        className="flex max-h-[450px] border rounded-lg"
      >
        <ReactMapGL
          initialViewState={center}
          style={{ width: "100%", height: "100%", borderRadius: 8 }}
          mapboxAccessToken={process.env.REACT_APP_MapboxAccessToken}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          attributionControl={false}
        >
          {showPopup && (
            <Popup
              longitude={center.longitude}
              latitude={center.latitude}
              anchor="bottom"
              onClose={() => setShowPopup(false)}
            >
              {company.company_name}
            </Popup>
          )}
          <Marker
            color={primaryColor}
            longitude={center.longitude}
            latitude={center.latitude}
            anchor="bottom"
            onClick={() => setShowPopup(!showPopup)}
          />
          <NavigationControl />
        </ReactMapGL>
      </div>
      <div className="flex w-full mx-3 my-2 mb-0 self-center h-[1px] bg-black/10" />
    </div>
  );
}

export default CoinLocalisation;
