import { Route, Outlet, Routes, Navigate } from "react-router-dom";
import MissingPage from "./pages/missing";
import LayoutPage from "./pages/layout";
import Home from "./pages/home";
import "./App.css";
import SignupPage from "./pages/signup";
import HomePage from "./pages/home/pages/home";
import VerifyLayout from "./pages/verify";
import VerifyEmail from "./pages/verify/pages/email";
import VerifyPhone from "./pages/verify/pages/phone";
import UserReservations from "./pages/home/pages/user/reservations";
import UserProfile from "./pages/home/pages/user/profile";
import UserFavorites from "./pages/home/pages/user/favorites";
import RequireAuth from "./middlewares/requireAuth";
import Coin from "./pages/home/pages/coin";
import MapPage from "./pages/map";
import MapMobilePage from "./pages/map-mobile";
import MentionLegalePage from "./pages/home/pages/mentionlegal";
import ConditionGeneralePage from "./pages/home/pages/conditiongeneral";
import PolitiqueConfidentialitePage from "./pages/home/pages/politiqueconfidentialite";

function StartRoutingApp() {
  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        <Route path="/">
          <Route element={<Home />}>
            <Route path="/" element={<HomePage />} />
            <Route path="coin">
              <Route path="" element={<Navigate to={"../"} replace />} />
              <Route path=":companySlug" element={<Coin />} />
            </Route>
            <Route
              path="user"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route path="" element={<Navigate to={"profile"} replace />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="reservations" element={<UserReservations />} />
              <Route path="favorites" element={<UserFavorites />} />
            </Route>
            <Route path="mention-legale" element={<MentionLegalePage />} />
            <Route path="condition-generale" element={<ConditionGeneralePage />} />
            <Route
              path="politique-de-confidentialite"
              element={<PolitiqueConfidentialitePage />}
            />
          </Route>
          <Route path="map">
            <Route path="" element={<MapPage />} />
          </Route>
          <Route path="map-mobile">
            <Route path="" element={<MapMobilePage />} />
          </Route>
        </Route>
        {RoutesWithoutAuth()}
        {/* Catch all */}
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
}

export function RoutesWithoutAuth() {
  return (
    <Route element={<Outlet />}>
      <Route element={<Outlet />}>
        <Route path="signup" element={<SignupPage />} />
      </Route>
      <Route path="verify" element={<VerifyLayout />}>
        <Route path="email" element={<VerifyEmail />} />
        <Route path="phone" element={<VerifyPhone />} />
      </Route>
    </Route>
  );
}

export default StartRoutingApp;
