import React from "react";
import ConfirmActionModal from "../../modals/models/confirmAction";
import { useAuthStore } from "../../../configs/stores/auth";

const LogOutAccount = () => {
  const logout = useAuthStore((e) => e.logout);
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    return await logout();
  }
  return (
    <React.Fragment>
      <button
        className="flex w-full h-10 hover:bg-slate-100 items-center pl-4"
        onClick={() => setisOpen(true)}
      >
        <span className="flex text-red-800 font-medium">Déconnexion</span>
      </button>
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment vous déconnecter ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default LogOutAccount;
