import React from "react";
import spinner from "../../../../assets/spinner.svg";
import { useSearchParams } from "react-router-dom";
import { postUrl } from "../../../../configs/apiRequest";
import { ToastSuccessNotifier } from "../../../../constants";
import { navigateLink } from "../../../../components/buttons/navigationLinkManager";

const VerifyEmail = () => {
  const [result, setresult] = React.useState<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>();
  const [isLoading, setisLoading] = React.useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  function submitForm() {
    setresult(undefined);
    setisLoading(true);
    postUrl("/auth/verify/email", { token }).then((reponse) => {
      setisLoading(false);
      setresult(reponse);
      if (reponse.success) {
        ToastSuccessNotifier({ message: reponse.message.toString() });
        setTimeout(() => {
          navigateLink("/");
        }, 3000);
      }
    });
  }
  return (
    <div className="flex select-none flex-col max-w-[80vw] items-center w-fit p-10 space-y-5 rounded-lg bg-white">
      <span className="flex text-[20px] max-w-[80%] font-medium text-center leading-tight">
        Vérification de votre adresse email
      </span>

      {!!result ? (
        <>
          <span
            style={{
              color: result.success ? "green" : "red",
            }}
            className="flex text-[16px] max-w-[300px] font-medium text-center leading-tight"
          >
            {result.message}
          </span>
          <button
            style={{
              display: !result.success ? "flex" : "none",
            }}
            onClick={submitForm}
            className="flex px-5 py-2 rounded-md bg-primary hover:bg-hover active:bg-active transition-all duration-100"
          >
            <span className="flex text-white">Relancer la vérification</span>
          </button>
          <span
            style={{
              display: !result.success ? "flex" : "none",
            }}
            className="flex text-[16px] max-w-[300px] text-center font-medium leading-tight"
          >
            En cas d'invalidité ou expiration de votre jeton, cliquez sur le
            lien ci-dessous pour le rafraîchir.
          </span>
          <a
            style={{
              display: !result.success ? "flex" : "none",
            }}
            className="flex rounded-md text-primary hover:text-hover active:text-active transition-all duration-100"
            href="https://www.Lecoinchicdumoment.com/user/profile"
          >
            <span className="flex">
              Cliquez ici pour rafraîchir
            </span>
          </a>
        </>
      ) : !isLoading ? (
        <>
          <button
            onClick={submitForm}
            className="flex px-5 py-2 rounded-md bg-primary hover:bg-hover active:bg-active transition-all duration-100"
          >
            <span className="flex text-white">Démarrer la vérification</span>
          </button>
        </>
      ) : (
        <>
          <div className="flex w-[250px] justify-center">
            <img src={spinner} alt="Not_found_token" />
          </div>
          <span className="flex text-[16px] font-medium text-center leading-tight">
            Patientez un instant...
          </span>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
