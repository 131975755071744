import React from "react";
import { useConnexeCurrentShowItemsLengthPerRow } from "../../../../../functions/useLayoutDimension";
import CategoryItemLaptopLoading from "../../../../../components/categoryItem/laptop/loading";
import CategoryItemConnexeLaptop from "../../../../../components/categoryItemConnexe/laptop";
import { useCoinStore } from "../../../../../configs/stores/coin";

const ConnexeEtablissment = () => {
  const MaxItemLenghtPerRow = useConnexeCurrentShowItemsLengthPerRow();
  const maxLenght = MaxItemLenghtPerRow / 2;
  const connexes = useCoinStore((e)=>e.connexes);
  const isLoading = useCoinStore((e)=>e.isLoadingConnexeCategories);

  return (
    <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-2 mini:grid-cols-3 normal:grid-cols-4 big:grid-cols-5 toobig:grid-cols-5 gap-6">
      {/* {!isLoading &&
        connexes
          .slice(0, maxLenght)
          .map((item) => {
            return (
              <CategoryItemConnexeLaptop
                key={"CategoryTypeRow_" + item.category.category_id.toString()}
                item={item}
              />
            );
          })}
      {isLoading &&
        Array.from({ length: 12 })
          .slice(0, maxLenght)
          .map((_, index) => {
            return (
              <CategoryItemLaptopLoading
                key={"CategoryTypeRow_" + index.toString()}
              />
            );
          })} */}
    </div>
  );
};

export default ConnexeEtablissment;
