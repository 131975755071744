import React from "react";
import { useCurrentShowItemsLengthPerRow } from "../../../../../functions/useLayoutDimension";
import CategoryItemLaptopLoading from "../../../../../components/categoryItem/laptop/loading";


const CategoryTypeRowLoading = () => {
  const MaxItemLenghtPerRow = useCurrentShowItemsLengthPerRow();
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-3">
        <div className="flex flex-row items-center">
          <div className="flex h-[24px] w-[150px] bg-activeGrey rounded-sm animate-pulse"/>
        </div>
        <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-2 mini:grid-cols-3 normal:grid-cols-4 big:grid-cols-5 toobig:grid-cols-6 gap-6">
          {Array.from({ length: MaxItemLenghtPerRow/2 }).map((_, index) => {
            return (
              <CategoryItemLaptopLoading
                key={"CategoryTypeRowLoading_" + index.toString()}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategoryTypeRowLoading;
