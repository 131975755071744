import React from "react";
import LogoHeader from "./logo";
import SearchInput from "./search";

const MapHeader = () => {
  return (
    <div className="flex flex-row absolute z-[1] top-5 ml-5 shadow-md h-[70px] lg:min-w-[400px] lg:w-[70%] lg:max-w-[800px] lg:pr-0 pr-5 min-w-0 w-full rounded-xl">
      <div className="flex flex-row items-center flex-1 space-x-8 px-6 pr-5 py-2 pl-6 bg-white rounded-xl"> 
        <LogoHeader />
        <SearchInput />
      </div>
    </div>
  );
};

export default MapHeader;
