import React from "react";
import SearchCompany from "../../modals/models/searchcompany";

const SearchIconButton = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function closeSearch() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div
        onClick={() => setisOpen(true)}
        className={`hidden max-lg:flex p-[9px] sm:px-4 sm:py-[6px] items-center space-x-2 rounded-full select-none transition-all bg-grey hover:bg-hoverGrey active:bg-activeGrey`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="flex w-[18px] h-[18px] fill-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <span className="flex text-[16px] font-medium max-sm:hidden">
          Chercher
        </span>
      </div>
      <SearchCompany open={isOpen} handleClose={closeSearch} />
    </React.Fragment>
  );
};

export default SearchIconButton;
