import React from "react";
import { useCoinStore } from "../../../../configs/stores/coin";
import { ServiceModel } from "../../../../models/models";
type Props = {
  service: ServiceModel;
};
const AddedServiceItem = ({ service }: Props) => {
  const removeService = useCoinStore((e) => e.removeService);
  function dec() {
    removeService(service.service_id);
  }
  return (
    <div className="flex flex-col justify-center space-y-2 leading-tight border-b pb-3">
      <div className="group flex flex-row space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5 group-hover:stroke-red-500 cursor-pointer"
          onClick={() => dec()}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
        <span className="text-[17px] font-medium"> - </span>
        <div className="flex flex-col space-y-1">
          <span className="text-[17px] font-medium">
            {service.service_name}
          </span>
          <span className="text-[14px] font-medium">
            Cout: {service.service_price} FCFA
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddedServiceItem;
