import { useQuery } from "@tanstack/react-query";
import {
  CategoryModel,
  CategoryTypeModel,
  CompanyModel,
  ImageModel,
} from "../models/models";
import { getCategoriesDatas } from "../contexts/authbasics/functions";
import { MINUTE_IN_MS } from "../constants";

type Props = {
  category?: string;
  max?: number | string;
  maxPerRow?: string | number;
};

export type CategoryData = {
  category: CategoryModel;
  company: CompanyModel;
  images: Array<ImageModel>;
  evaluation: string | String;
  comments: number | string;
};

export type CategoriesDatas = {
  type: CategoryTypeModel;
  categories: Array<CategoryData>;
};

export type CategoriesDatasResults = Array<CategoriesDatas>;

export function useCategoriesDatas(
  { category = "all", max = 30, maxPerRow = 12 }: Props,
  staleTime = 4 * MINUTE_IN_MS,
  enable = true
) {
  const {
    isLoading,
    data: result,
    ...args
  } = useQuery({
    queryKey: [
      "categoriesDatas",
      category === "all" ? `${category}_${max}` : `${category}_${maxPerRow}`,
    ],
    queryFn: () => getCategoriesDatas(category, String(max), String(maxPerRow)),
    staleTime,
    enabled:enable,
  });
  const results = (result?.data ?? {
    premiumCategories: [],
    categories: [],
  }) as {
    premiumCategories: CategoriesDatasResults;
    categories: CategoriesDatasResults;
  };
  return {
    isLoading,
    data: { ...results },
    ...args,
  };
}
