import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Empty from "../../assets/empty.svg";
import useLayoutDimension from "../../functions/useLayoutDimension";

const VerifyLayout = () => {
  const { height } = useLayoutDimension("#country");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex px-3 xl:px-5 2xl:px-20 h-[75px] space-x-4 p-4 bg-white items-center">
        <a className="flex flex-row space-x-[6px] h-full items-center" href="/">
          <img className="flex h-full" src={Logo} alt="Logo" />
          <div
            id="country"
            style={{ minWidth: height }}
            className="flex h-[70%] text-white px-[6px] bg-primary rounded-md items-center justify-center"
          >
            <span className="flex text-[14.5px] font-semibold items-center">
              CI
            </span>
          </div>
        </a>
        <div className="flex w-[2px] h-full bg-black/70" />
        <span className="flex text-[22px] font-semibold">Vérification</span>
      </div>
      <div className="flex flex-1 flex-col justify-center items-center bg-activeGrey">
        {!!token ? (
          <Outlet />
        ) : (
          <div className="flex flex-col max-w-[80vw] items-center w-fit p-10 space-y-5 rounded-lg bg-white">
            <div className="flex w-[250px]">
              <img src={Empty} alt="Not_found_token" />
            </div>
            <span className="flex text-[20px] max-w-[80%] font-medium text-center leading-tight">
                Requête invalide !
            </span>
            <a className="flex px-5 py-2 rounded-md bg-primary hover:bg-hover active:bg-active transition-all duration-100" href="/">
                <span className="flex text-white">Aller à l'accueil</span>
            </a>
          </div>
        )}
      </div>
      {/* <div className='flex h-[80px]'></div> */}
    </div>
  );
};

export default VerifyLayout;
