import React from "react";
import { CategoryModel } from "../../../../../models/models";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuthStore } from "../../../../../configs/stores/auth";
import { primaryColor } from "../../../../../constants";

type Props = {
  category: CategoryModel;
};

const FavorisCategory = ({ category }: Props) => {
  const updateFavoris = useAuthStore((e) => e.updateFavoris);
  const favoris = useAuthStore((e) => e.user?.user_favorites ?? []);
  const { category_company: company, category_type: type } = category;
  function checkFavorite() {
    const exist = favoris.find((e) => e.category_id === category?.category_id);
    updateFavoris(category, !exist ? "add" : "remove");
  }
  return (
    <div className="flex bg-white p-5 px-5 items-start space-x-4 flex-row w-[500px] max-w-full rounded-[10px]">
      <div className="flex border p-1 ring-2 ring-primary rounded-full overflow-hidden">
        <LazyLoadImage
          src={company.company_logo.image_link.toString()}
          className="flex h-[35px] w-[35px]"
          alt={company.company_slug.toString()}
          placeholderSrc={company.company_logo.image_miniature.toString()}
          height={35}
          width={35}
          effect="blur"
        />
      </div>
      <div className="flex flex-col space-y-1 pt-1 leading-tight">
        <span className="flex text-[18px] font-semibold">
          {company.company_name}
        </span>
        <span className="flex text-[15px] font-medium">
          {type.categorytype_name}
        </span>
        <div className="flex flex-row space-x-3 pt-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={
              !!favoris.find((fav) => fav.category_id === category?.category_id)
                ? primaryColor
                : "none"
            }
            viewBox="0 0 24 24"
            onClick={checkFavorite}
            className="w-7 h-7 transition-all cursor-pointer duration-150"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>
          <a
            href={
              "/coin/" +
              company.company_slug +
              "?category=" +
              type.categorytype_slug
            }
            className="flex flex-row items-center space-x-1"
          >
            <span className="flex text-[15px] underline underline-offset-2">
              Ouvrir
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[17px] h-[17px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FavorisCategory;
