import React from "react";
import Rating from "@mui/material/Rating";
import { primaryColor } from "../../../../../../../constants";
import LoginModal from "../../../../../../../components/modals/models/login";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import { useAuthStore } from "../../../../../../../configs/stores/auth";

const CoinEvaluation = () => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const coin = useCoinStore((e) => e.coin);
  const isLoading = useCoinStore((e) => e.isUpdating);
  const updateEvaluation = useCoinStore((e) => e.updateEvaluation);
  const userevaluation = coin?.userevaluation ?? "0";
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  function setClicked(value: number) {
    if (!isAuthenticated) {
      setisOpen(true);
    } else {
      updateEvaluation(value.toString());
    }
  }

  return (
    <React.Fragment>
      <div className="flex w-full flex-col space-y-3 px-2 bg-white pt-4">
        <div className="flex flex-row space-x-3 items-center">
          <span className="flex text-[20px] leading-tight font-medium">
            Evaluer cet etablissement :
          </span>
          <div className="flex flex-row items-center space-x-2">
            <Rating
              name="half-rating"
              defaultValue={0}
              value={
                isAuthenticated
                  ? userevaluation
                    ? parseFloat(userevaluation.toString())
                    : null
                  : null
              }
              color={primaryColor}
              precision={0.5}
              size="large"
              onChange={function (ev, val) {
                setClicked(val ?? 0);
              }}
            />
            {!isLoading ? (
              !!userevaluation &&
              !!isAuthenticated &&
              parseFloat(userevaluation.toString()) !== 0 && (
                <span className="flex text-[19px] font-semibold">
                  {userevaluation.toString()}
                </span>
              )
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={primaryColor}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={primaryColor}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </div>
        </div>
        <div className="flex w-full self-center h-[1px] bg-black/10" />
      </div>
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default CoinEvaluation;
