import React from "react";
import Logo from "../../../assets/logo.png";
import LogoIcon from "../../../assets/logoicon.png";
import useLayoutDimension from "../../../functions/useLayoutDimension";

const LogoHeader = () => {
  const { height } = useLayoutDimension("#country");
  return (
    <a
      className="flex flex-row space-x-[10px] max-sm:space-x-[10px] max-sm:-m-[10px] h-[55%] xl:h-[60%] items-center"
      href="/"
    >
      <img className="flex h-full w-auto max-sm:hidden" src={Logo} alt="Logo" />
      <img className="flex h-[95%] w-auto sm:hidden" src={LogoIcon} alt="Logo" />
      <div
        id="country"
        style={{ minWidth: height }}
        className="flex h-[70%] text-white sm:ml-2 px-[6px] pt-[1px] bg-primary rounded-md items-center justify-center"
      >
        <span
          className="flex text-[14.5px] max-sm:text-[13px] font-semibold items-center"
        >
          CI
        </span>
      </div>
    </a>
  );
};

export default LogoHeader;
