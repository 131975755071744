import React from "react";
import { useCategoryItemDimensions } from "../../../functions/generateCategoryWidth";

const CategoryItemLaptopLoading = () => {
  const CategoryItemDimensions = useCategoryItemDimensions();
  return (
    <React.Fragment>
      <div className="flex col-span-1 flex-col w-full min-h-[100px] relative overflow-hidden rounded-[18px] bg-white">
        <div className={`flex flex-col space-y-3`}>
          <div
            style={{
              height: CategoryItemDimensions,
            }}
            className="flex bg-activeGrey animate-pulse rounded-t-[18px]"
          />
          <div className="flex flex-col px-3 pb-3 space-y-3">
            <div className="flex h-[24px] w-full bg-activeGrey rounded-sm animate-pulse" />
            <div className="flex h-[24px] w-[70%] bg-activeGrey rounded-sm animate-pulse" />
            <div className="flex h-[24px] w-[40%] bg-activeGrey rounded-sm animate-pulse" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategoryItemLaptopLoading;
