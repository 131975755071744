import React from "react";
import { useCurrentShowItemsLengthPerRow } from "../../functions/useLayoutDimension";
import BigCarousel from "./bigcarousel";
import CategoryPresentationItemLoading from "./loading";
import { CategoryDataInterface } from "../../configs/stores/categories";

type Props = {
  isLoading: boolean;
  categories: Array<CategoryDataInterface>;
};

const CategoriesPresentation = ({
  isLoading = true,
  categories = [],
}: Props) => {
  const MaxItemLenghtPerRow = useCurrentShowItemsLengthPerRow();
  const maxLenght = MaxItemLenghtPerRow / 2 - 1;

  return (
    <React.Fragment>
      <div className="flex flex-col space-y-7 w-full">
        <div className="flex leading-tight flex-row max-xl:flex-col max-xl:space-y-3 max-xl:items-start items-center justify-between">
          <span className="flex text-[28px] max-sm:text-[24px] max-md:text-[26px] font-bold">
            Découvrez des coins chic à couper le souffle !
          </span>
          <span className="flex text-[17px] max-sm:text-[15px] max-md:text-[16px] font-medium">
            Plus de 5000 établissements à visiter dès maintenant !
          </span>
        </div>
        {categories.length !== 0 && (
          <BigCarousel categories={categories} maxLenght={maxLenght} />
        )}
        {categories.length === 0
          ? isLoading && (
              <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-1 mini:grid-cols-2 normal:grid-cols-3 big:grid-cols-4 toobig:grid-cols-5 gap-6">
                {Array.from({ length: maxLenght === 0 ? 1 : maxLenght }).map(
                  (e, index) => {
                    return (
                      <CategoryPresentationItemLoading
                        key={"CategoriesPresentation" + index.toString()}
                      />
                    );
                  }
                )}
              </div>
            )
          : null}
      </div>
    </React.Fragment>
  );
};

export default CategoriesPresentation;
