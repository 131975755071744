import React from "react";
import CoinHeaderMobile from "./mobile";
import CoinHeaderLaptop from "./laptop";

const CoinHeader = () => {
  return (
    <div className="flex flex-col">
      <CoinHeaderMobile />
      <CoinHeaderLaptop />
    </div>
  );
};

export default CoinHeader;
