import React from "react";
import ConnectedAccount from "./connected";
import NotConnectedAccount from "./notConnected";
import { useAuthStore } from "../../../configs/stores/auth";

const AccountHeader = () => {
  const isAuthenticated = useAuthStore((e)=>e.isAuthenticated);
  const user = useAuthStore((e)=>e.user);
  return (
    <div className="flex cursor-pointer">
      {isAuthenticated ? (
        !!user?.user_id && <ConnectedAccount />
      ) : (
        <NotConnectedAccount />
      )}
    </div>
  );
};

export default AccountHeader;
