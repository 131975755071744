import React from "react";
import ModalCustom from "../../modal";
import SearchCompanyInput from "./input";
// import SearchCompanyFilter from "./filter";
import SearchCompanyWelcome from "./welcome";
import SearchCompanyLoading from "./loading";
import SearchCompanyResults from "./results";
import SearchCompanyNoResults from "./noresults";
// import { useRecentsSearch } from "../../../../hooks/useRecentsSearch";
// import SearchCompanyRecents from "./recents";
import {
  SearchResultType,
  useSearchStore,
} from "../../../../configs/stores/search";
import { ToastWarnNotifier } from "../../../../constants";

const SearchCompany = ({
  handleClose = () => {},
  defaultOpenFilter = false,
  open = false,
}) => {
  const { searchTerm, isLoading, searchTermCompanyCategories } =
    useSearchStore();
  const [results, setResult] = React.useState<Array<SearchResultType>>([]);

  const showWelcome = searchTerm.length === 0;
  const showData = searchTerm.length !== 0 && results.length !== 0;
  const showSkeleton =
    results.length === 0 && isLoading && searchTerm.length !== 0;
  const showNoData =
    results.length === 0 && !isLoading && searchTerm.length !== 0;
  // const showRecents = false;
  function handleSubmit(e: any) {
    e.preventDefault();
    if (searchTerm.split(" ").join("").length === 0) {
      ToastWarnNotifier({
        message: "Champs vide !",
      });
      return;
    }
    searchTermCompanyCategories(searchTerm);
  }
  React.useEffect(() => {
    if (searchTerm.split(" ").join("").length !== 0) {
      searchTermCompanyCategories(searchTerm).then((reponses) => {
        setResult(reponses);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  return (
    <ModalCustom
      open={open}
      backdropFilter={4}
      hideCloseButton
      zeroPadding
      handleClose={handleClose}
    >
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <form
          onSubmit={handleSubmit}
          className="flex flex-col pb-1 h-[calc(100vh-10vw)] max-h-[75vh] max-w-[550px] w-[calc(100vw-7vw)] lg:max-w-[758px] lg:max-h-[650px]"
        >
          <SearchCompanyInput handleClose={handleClose} />
          {/* <SearchCompanyFilter defaultOpenFilter={defaultOpenFilter} /> */}
          {showWelcome && <SearchCompanyWelcome />}
          {showSkeleton && <SearchCompanyLoading />}
          {showData && <SearchCompanyResults results={results} />}
          {showNoData && <SearchCompanyNoResults />}
          {/*{showRecents && <SearchCompanyRecents recents={RecentsSearch} />} */}
        </form>
      </React.Fragment>
    </ModalCustom>
  );
};

export default SearchCompany;
