import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  CategoryModel,
  CategoryTypeModel,
  ImageModel,
} from "../../models/models";
import {
  getAllHomeCategories,
  getCategoriesByTypeSlug,
} from "./functions/category";

const LOCAL_DATASTORE_NAME = "coinchicclient-category";

export type CategoryDataInterface = {
  category: CategoryModel;
  images: Array<ImageModel>;
  evaluation: string;
  comments: string;
};

type ContextProps = {
  isLoadingPremiumCategories: boolean;
  isloadingCategories: boolean;
  premiumCategories: Array<{
    typeSlug: string;
    categories: Array<CategoryDataInterface>;
  }>;
  categories: Array<{
    typeSlug: string;
    categories: Array<CategoryDataInterface>;
  }>;
  isLoadingHomePremiumCategories: boolean;
  isloadingHomeCategories: boolean;
  premiumHomeCategories: Array<CategoryDataInterface>;
  categoriesHome: Array<{
    type: CategoryTypeModel;
    categories: Array<CategoryDataInterface>;
  }>;
  categoryTypeSlug: string;
  updateCategoryTypeSlug: (categoryTypeSlug: string) => Promise<any>;
  getAllHomeCategories: () => Promise<any>;
  getAllCategories: (categoryTypeSlug?: string, max?: number) => Promise<any>;
};

export const useCategoryStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoadingPremiumCategories: true,
      isloadingCategories: true,
      premiumCategories: [],
      categories: [],
      isLoadingHomePremiumCategories: true,
      isloadingHomeCategories: true,
      premiumHomeCategories: [],
      categoriesHome: [],
      categoryTypeSlug: "",
      updateCategoryTypeSlug: async (categoryTypeSlug) => {
        set(
          produce((state: ContextProps) => {
            state.categoryTypeSlug = categoryTypeSlug;
          })
        );
      },
      getAllHomeCategories: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingHomePremiumCategories = true;
            state.isloadingHomeCategories = true;
          })
        );
        const res = await getAllHomeCategories();
        const data = (res?.data ?? { premiums: [], categories: [] }) as {
          premiums: Array<CategoryDataInterface>;
          categories: Array<{
            type: CategoryTypeModel;
            categories: Array<CategoryDataInterface>;
          }>;
        };
        set(
          produce((state: ContextProps) => {
            state.premiumHomeCategories = data.premiums;
            state.categoriesHome = data.categories;
            state.isLoadingHomePremiumCategories = false;
            state.isloadingHomeCategories = false;
          })
        );
      },
      getAllCategories: async (categoryTypeSlug = "all", max = 30) => {
        // const state = get();
        // setTimeout(() => {
        //   state.getCategories(state.categoryTypeSlug, categoryMax);
        // }, 750);
        // await state.getPremiumCategories(state.categoryTypeSlug, premiumMax);

        set(
          produce((state: ContextProps) => {
            state.isLoadingPremiumCategories = true;
            state.isloadingCategories = true;
          })
        );
        const res = await getCategoriesByTypeSlug(categoryTypeSlug, max);
        const data = (res?.data ?? { premiums: [], categories: [] }) as {
          premiums: Array<CategoryDataInterface>;
          categories: Array<CategoryDataInterface>;
        };
        set(
          produce((state: ContextProps) => {
            state.premiumCategories = state.premiumCategories
              .filter((e) => e.typeSlug !== categoryTypeSlug)
              .concat({
                typeSlug: categoryTypeSlug,
                categories: data.premiums,
              });
            state.categories = state.categories
              .filter((e) => e.typeSlug !== categoryTypeSlug)
              .concat({
                typeSlug: categoryTypeSlug,
                categories: data.categories,
              });
            state.isLoadingPremiumCategories = false;
            state.isloadingCategories = false;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ctg")),
    }
  )
);
