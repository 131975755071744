import React from "react";

export function useDimensions<T extends HTMLElement>() {
  const ref = React.useRef<T | null>(null);
  const [dimensions, setDimensions] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    const updateDimensions = () => {
      const rrref = ref.current;
      if (rrref) {
        const rect = rrref.getBoundingClientRect();
        setDimensions(rect.toJSON ? rect.toJSON() : rect); // Use toJSON if available
      }
    };

    // Initial dimension calculation
    updateDimensions();

    // Recalculate dimensions on resize
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [ref]);

  return [ref, dimensions] as const;
}
