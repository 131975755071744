import React from "react";
import ReservationFormModal from "../../../../../../../components/modals/models/reservatinform";
import LoginModal from "../../../../../../../components/modals/models/login";
import { useAuthStore } from "../../../../../../../configs/stores/auth";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import { ToastWarnNotifier } from "../../../../../../../constants";

const CoinReserver = () => {
  const MIN_PRICE = 10000;
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  const category = useCoinStore((e) => e.coin?.category);
  const company = useCoinStore((e) => e.coin?.company);
  const addedPlacesLength = addedPlaces.reduce<number>(
    (pre, cur, index, arr) => {
      return pre + cur.length;
    },
    0
  );
  const [isOpenForm, setisOpenForm] = React.useState(false);
  function closeForm() {
    setisOpenForm(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex bottom-0 z-[999] fixed w-full border-t bg-white">
        <button
          onClick={() => {
            if (addedPlacesLength === 0) {
              ToastWarnNotifier({
                message:
                  "Pour faire votre réservation, veuillez ajouter la place que vous souhaitez réserver",
              });
              return false;
            }
            if (category?.category_reservation_active === false) {
              ToastWarnNotifier({
                message: `Désolé, les réservations sont temporairement inactives chez ${company?.company_name} - ${category.category_type.categorytype_name}`,
              });
              return false;
            }
            isAuthenticated ? setisOpenForm(true) : setisOpen(true);
          }}
          className="flex flex-1 flex-col h-auto py-2 rounded-lg bg-primary active:bg-active m-[10px] items-center justify-center"
        >
          <span className="flex font-semibold text-[18px] text-white">
            Faire une réservation
          </span>
          <span className="flex text-[14px] text-white font-semibold">
            Frais: {MIN_PRICE} FCFA
          </span>
        </button>
      </div>
      <ReservationFormModal open={isOpenForm} handleClose={closeForm} />
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default CoinReserver;
