import { QueryClient } from "@tanstack/react-query";
import {
  PersistedClient,
  persistQueryClient,
} from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { compress, decompress } from "lz-string";
import { decrypt, encrypt } from "./encryption";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  },
});

persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
    serialize: (data) =>
      compress(
        JSON.stringify({
          data: encrypt(
            JSON.stringify(data),
            process.env.REACT_APP_Key,
            process.env.REACT_APP_Iv
          ),
        })
      ),
    deserialize: (data) =>
      JSON.parse(
        decrypt(
          JSON.parse(decompress(data)).data,
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        )
      ) as PersistedClient,
  }),
  maxAge: Infinity,
});