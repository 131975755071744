import React from "react";
import FavorisButton from "./favorisButton";
import ShareCoinModal from "../../../../../../../components/modals/models/sharecoin";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import { CategoryModel } from "../../../../../../../models/models";
import CoinCommentsModal from "../../../../../../../components/modals/models/comments";

const CoinDescription = () => {
  const coin = useCoinStore((e) => e.coin);
  const category = (coin?.category ?? {}) as CategoryModel;
  const evaluation = coin?.evaluation ?? "";
  const comments = coin?.comments ?? [];
  const [showMore, setShowMore] = React.useState(false);
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComments, setisOpenComments] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  function closeComments() {
    setisOpenComments(false);
  }

  React.useEffect(() => {
    const descriptionElement = document.getElementById("coinDescriptionTextl");
    const showMoreElement = document.getElementById("afficherPlusl");
    if (descriptionElement && showMoreElement) {
      const lineHeight = parseInt(
        window
          .getComputedStyle(descriptionElement)
          .getPropertyValue("line-height")
      );
      const maxHeight = lineHeight * 4; // 4 lignes
      showMoreElement.addEventListener("click", function () {
        if (showMore) {
          descriptionElement.style.maxHeight = maxHeight + "px";
          showMoreElement.textContent = "Afficher toute la description";
        } else {
          descriptionElement.style.maxHeight = "none";
          showMoreElement.textContent = "Afficher moins";
        }
        setShowMore(!showMore);
      });
    }
  }, [showMore]);
  return (
    <React.Fragment>
      <div className="flex flex-col space-y-5 px-2">
        {!!category?.category_description && (
          <div className="flex flex-col space-y-1">
            <span
              id={"coinDescriptionTextl"}
              className={`flex text-[22px] 2xl:text-[28px] font-semibold leading-tight ${
                showMore ? "line-clamp-none" : "line-clamp-4"
              }`}
            >
              {category.category_description}
            </span>
            <span
              id={"afficherPlusl"}
              className="flex underline underline-offset-1 text-[12px] w-fit font-semibold cursor-pointer select-none"
            >
              Afficher toute la description
            </span>
          </div>
        )}
        <div className="flex flex-row text-[15.5px] space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="black"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clipRule="evenodd"
            />
          </svg>
          <span className="flex font-semibold">{evaluation} ·</span>
          <span
            className="flex font-semibold underline underline-offset-2 cursor-pointer hover:text-primary transition-all"
            onClick={() => setisOpenComments(true)}
          >
            {comments} commentaires
          </span>
        </div>
        <div className="flex flex-row gap-3">
          <FavorisButton />
          <button
            onClick={() => setisOpen(true)}
            className="flex px-2 flex-row space-x-2 justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
              />
            </svg>
            <span className="flex text-[15px] font-semibold">Partager</span>
          </button>
        </div>
        <div className="flex w-full mx-3 mb-0 self-center h-[1px] bg-black/10" />
        <div className="flex flex-row space-x-5 items-center">
          <span className="flex text-[17px] leading-tight mr-1">
            Rejoignez nous via nos réseaux sociaux :{" "}
          </span>
          <a
            href={"https://web.facebook.com/LeCoinChicDuMomentCI"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              className="h-7 w-7 cursor-pointer"
              onClick={function () {}}
            >
              <path d="M 25 3 C 12.861562 3 3 12.861562 3 25 C 3 36.019135 11.127533 45.138355 21.712891 46.728516 L 22.861328 46.902344 L 22.861328 29.566406 L 17.664062 29.566406 L 17.664062 26.046875 L 22.861328 26.046875 L 22.861328 21.373047 C 22.861328 18.494965 23.551973 16.599417 24.695312 15.410156 C 25.838652 14.220896 27.528004 13.621094 29.878906 13.621094 C 31.758714 13.621094 32.490022 13.734993 33.185547 13.820312 L 33.185547 16.701172 L 30.738281 16.701172 C 29.349697 16.701172 28.210449 17.475903 27.619141 18.507812 C 27.027832 19.539724 26.84375 20.771816 26.84375 22.027344 L 26.84375 26.044922 L 32.966797 26.044922 L 32.421875 29.564453 L 26.84375 29.564453 L 26.84375 46.929688 L 27.978516 46.775391 C 38.71434 45.319366 47 36.126845 47 25 C 47 12.861562 37.138438 3 25 3 z M 25 5 C 36.057562 5 45 13.942438 45 25 C 45 34.729791 38.035799 42.731796 28.84375 44.533203 L 28.84375 31.564453 L 34.136719 31.564453 L 35.298828 24.044922 L 28.84375 24.044922 L 28.84375 22.027344 C 28.84375 20.989871 29.033574 20.060293 29.353516 19.501953 C 29.673457 18.943614 29.981865 18.701172 30.738281 18.701172 L 35.185547 18.701172 L 35.185547 12.009766 L 34.318359 11.892578 C 33.718567 11.811418 32.349197 11.621094 29.878906 11.621094 C 27.175808 11.621094 24.855567 12.357448 23.253906 14.023438 C 21.652246 15.689426 20.861328 18.170128 20.861328 21.373047 L 20.861328 24.046875 L 15.664062 24.046875 L 15.664062 31.566406 L 20.861328 31.566406 L 20.861328 44.470703 C 11.816995 42.554813 5 34.624447 5 25 C 5 13.942438 13.942438 5 25 5 z"></path>
            </svg>
          </a>
        </div>
        <div className="flex w-full mx-3 mb-0 self-center h-[1px] bg-black/10" />
      </div>
      <ShareCoinModal open={isOpen} handleClose={closeLogin} />
      <CoinCommentsModal open={isOpenComments} handleClose={closeComments} />
    </React.Fragment>
  );
};

export default CoinDescription;
