import React from "react";
import Mobile from "./mobile";
import Laptop from "./laptop";

const MenuBar = () => {
  return (
    <>
      <Mobile />
      <Laptop />
    </>
  );
};

export default MenuBar;
