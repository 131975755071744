import React from "react";

type Props = {
  increment: () => void;
  quantity: number;
  decrement: () => void;
};

const PlaceQuantity = ({ quantity = 0, increment, decrement }: Props) => {
  return (
    <div className="flex flex-row border rounded-md overflow-hidden">
      <button
        className="flex w-[30px] flex-1 border-r items-center justify-center active:bg-activeGrey"
        onClick={() => {
          if (quantity && quantity > 0) {
            decrement();
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
        </svg>
      </button>
      <div className="flex h-[30px] w-[50px] items-center justify-center">
        <span className="flex text-[16px] font-medium">{quantity}</span>
      </div>
      <button
        className="flex w-[30px] flex-1 border-l items-center justify-center active:bg-activeGrey"
        onClick={() => {
          increment();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </button>
    </div>
  );
};

export default PlaceQuantity;
