import React from "react";
import ModalCustom from "../../modal";
import { useCoinStore } from "../../../../configs/stores/coin";
import { ServiceModel } from "../../../../models/models";

type Props = {
  open: boolean;
  service: ServiceModel;
  handleClose: () => void;
};

const CoinServiceModal = ({ handleClose, service, open = false }: Props) => {
  const addService = useCoinStore((e) => e.addService);
  const removeService = useCoinStore((e) => e.removeService);
  const addedServices = useCoinStore((e) => e.addedServices);
  const exist = addedServices.find((e) => e.service_id === service.service_id);
  return (
    <ModalCustom open={open} zeroPadding handleClose={handleClose}>
      <React.Fragment>
        <div className=" bg-white max-w-[330px] min-w-[330px] h-auto flex flex-col border rounded-xl overflow-hidden">
          <div className="flex h-[60%] w-full relative">
            <img
              className="flex flex-1 object-cover"
              src={service.service_image.image_link.toString()}
              alt={service.service_name.toString()}
            />
          </div>
          <div className="flex flex-1 flex-col px-2 pl-4 pr-3 pt-3 pb-2 space-y-3">
            <div className="flex flex-col space-y-1">
              <span className="flex text-[18px] font-[600]">
                {service.service_name}
              </span>
              <span className="text-[15px] leading-tight">
                {service.service_description}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center">
              <span
                onClick={() =>
                  !exist
                    ? addService(service)
                    : removeService(service.service_id)
                }
                className="text-[15px] cursor-pointer text-primary w-fit px-2 py-[4px] rounded-md font-medium border"
              >
                {!exist ? "+ Ajouter au panier" : "Retirer du panier"}
              </span>
              <span className="text-[16px] font-medium line-clamp-1">
                {service.service_price} FCFA
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    </ModalCustom>
  );
};

export default CoinServiceModal;
