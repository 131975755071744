import React from 'react'

const SearchInput = () => {
  return (
    <div className='flex h-[75%] w-[30vw] flex-1'>
        <input className='flex flex-1 outline-primary bg-grey/30 border-grey border-[3px] rounded-md px-3' placeholder='Rechercher' />
    </div>
  )
}

export default SearchInput