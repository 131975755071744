import React from "react";
import POLITIQUES_CONFIDENTIALITE_PDF from "../../../../assets/politiqueconfidentialite.pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PolitiqueConfidentialitePage() {
  return (
    <div className="flex flex-row justify-center w-full xl:space-y-8 xl:pt-3">
      <div className="flex flex-col pb-4 h-full w-full px-4 xl:px-5 2xl:px-20 justify-between items-center max-w-[1536px]">
        <div style={{ width: "100%", height: "auto" }}>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={POLITIQUES_CONFIDENTIALITE_PDF} />
          </Worker>
        </div>
      </div>
    </div>
  );
}

export default PolitiqueConfidentialitePage;
