import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./carousel.css";

// import required modules
import { EffectFade, Pagination, Navigation } from "swiper/modules"; // Autoplay
import { CarouselSlide } from "./side";

const PresentationChildCarousel = ({ images }) => {
  const [isHover, setisHover] = React.useState(false);
  function enter() {
    const wdth = window.innerWidth;
    wdth >= 950 && setisHover(true);
  }
  function out() {
    !!isHover && setisHover(false);
  }
  return (
    <div
      className="flex flex-1 h-full w-full flex-col"
      onMouseEnter={enter}
      onMouseOver={enter}
      onMouseLeave={out}
    >
      <Swiper
        style={{
          "--swiper-navigation-color": isHover ? "#fff" : "transparent",
          "--swiper-navigation-size": "24px",
          "--swiper-navigation-sides-offset": "16px",
          "--swiper-pagination-color": "rgba(250,250,250,0.9)",
          "--swiper-pagination-bullet-inactive-color": "#fff",
          "--swiper-pagination-bullet-inactive-opacity": "0.6",
          "--swiper-pagination-bottom": "70px",
          "--swiper-pagination-top": "auto",
          borderRadius: 18,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={true}
        loop={true}
        effect={"fade"}
        modules={[Navigation, EffectFade, Pagination]} //Autoplay
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: true,
        // }}
        className="mySwiper"
      >
        {images
          .filter((e) => !!e?.image_ispreview)
          .map((image, index) => {
            return (
              <SwiperSlide
                key={"PresentationChildCarousel" + image.image_id.toString()}
              >
                <CarouselSlide image={image} />
              </SwiperSlide>
            );
          })}
        {images
          .filter((e) => !e?.image_ispreview)
          .map((image, index) => {
            return (
              <SwiperSlide
                key={"PresentationChildCarousel" + image.image_id.toString()}
              >
                <CarouselSlide image={image} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default PresentationChildCarousel;
