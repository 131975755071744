import React from "react";
import SearchCompany from "../../modals/models/searchcompany";

const SearchInput = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function closeSearch() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div
        onClick={() => setisOpen(true)}
        className="hidden h-[65%] w-[43%] min-w-[250px] max-w-[450px] ml-3 lg:flex"
      >
        <div
          className={`flex transition-all cursor-text space-x-2 border rounded-[60px] h-[100%] w-[100%] p-1 pr-[12px] border-[#92929280] shadow-sm hover:shadow-md items-center`}
        >
          <div
            id="searchComp"
            className="flex flex-1 px-4 pr-0 outline-none bg-transparent min-w-0"
          >
            <span className="flex text-[rgba(150,150,150,.8)]">Rechercher un coin chic...</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={"rgba(150,150,150,.5)"}
            className="w-[22px] h-[22px] mr-1 cursor-pointer"
          >
            <path
              fillRule="evenodd"
              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <SearchCompany open={isOpen} handleClose={closeSearch} />
    </React.Fragment>
  );
};

export default SearchInput;
