import React from "react";
import { useSearchParams } from "react-router-dom";
import { useBasicsStore } from "../../../../configs/stores/basics";

const MenuCategories = () => {
  const [searchParams] = useSearchParams();
  const [currentCtg, setCurrentCtg] = React.useState("");
  const categoryTypes = useBasicsStore((e) => e.categoryTypes);
  const isLoading = useBasicsStore((e) => e.isLoading);
  React.useEffect(() => {
    setCurrentCtg(searchParams.get("category") ?? "");
  }, [searchParams]);

  React.useEffect(() => {
    const menuItCat = document.getElementById("menuItCat");
    const curDoc = document.getElementById("menuIt_" + currentCtg);
    if (curDoc && menuItCat) {
      menuItCat.scrollTo({
        behavior: "smooth",
        left: curDoc.offsetLeft - 15,
      });
    }
  }, [currentCtg]);

  return (
    <nav
      id={"menuItCat"}
      className="flex transition-all flex-row space-x-4 px-[19px] pr-[90px] py-[10px] bg-primary text-white text-[15px] font-medium overflow-x-auto overflow-hidden w-full scrollbar-opacity-0"
    >
      <a
        id={"menuIt_all"}
        style={{
          display: categoryTypes.length !== 0 ? "flex" : "none",
        }}
        className={`flex [text-decoration: none] transition-all hover:scale-[1.05] ${
          currentCtg === "all"
            ? "scale-[1.07] font-semibold hover:scale-[1.07]"
            : "font-normal"
        }`}
        href={"?category=all"}
      >
        <span className="whitespace-nowrap text-white text-[17px]">Tous</span>
      </a>
      {categoryTypes.length !== 0 &&
        categoryTypes
          .map((e) => ({
            ...e,
            categorytype_index: parseInt(
              e?.categorytype_index.toString() ?? "0"
            ),
          }))
          .sort((a, b) => a.categorytype_index - b.categorytype_index)
          .map((ctg, index) => {
            return (
              <a
                key={ctg.categorytype_id.toString()}
                id={"menuIt_" + ctg.categorytype_slug}
                className={`flex transition-all text-white hover:scale-[1.04] ${
                  currentCtg === ctg.categorytype_slug
                    ? " font-bold underline underline-offset-4"
                    : "font-normal"
                }`}
                href={"?category=" + ctg.categorytype_slug}
              >
                <span className="whitespace-nowrap text-white text-[17px]">
                  {ctg.categorytype_name}
                </span>
              </a>
            );
          })}
      {categoryTypes.length === 0
        ? isLoading &&
          Array.from({ length: 8 }).map((_, index) => {
            return (
              <div
                key={index.toString()}
                className="flex min-w-[140px]  w-[140px] h-[15px] my-1 rounded-sm bg-grey animate-pulse"
              />
            );
          })
        : null}
    </nav>
  );
};

export default MenuCategories;
