import React from "react";
import { ImageModel } from "../../../models/models";
import { useRefLayoutDimension } from "../../../functions/useLayoutDimension";
import { useLoadOnAppear } from "../../../functions/useLoadOnAppear";
import LogoIcon from "../../../assets/logoicon.png";
import FavoriteButton from "./favoriteButton";
import CategoryItemLaptopCarousel from "./carousel";
// import { CategoryData } from "../../../hooks/useCategoriesDatas";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CategoryDataInterface } from "../../../configs/stores/categories";

type Props = {
  item: CategoryDataInterface; //CategoryData; //SearchCategoryResult;
};

const CategoryItemLaptop = ({ item }: Props) => {
  const { width, element: elementDim } =
    useRefLayoutDimension<HTMLAnchorElement>();
  const { element } = useLoadOnAppear<HTMLDivElement>({});
  const { category, images } = item;
  const { category_company: company, category_type: categoryType } = category;
  return (
    <React.Fragment>
      <div className="flex flex-col relative" ref={element}>
        <div className="flex absolute right-[2px] top-[67%]">
          <FavoriteButton category={item.category} />
        </div>
        <a
          href={
            "/coin/" +
            company.company_slug +
            "?category=" +
            categoryType.categorytype_slug
          }
          ref={elementDim}
          className="flex flex-col col-span-1 space-y-3 w-full min-h-[100px] relative overflow-hidden rounded-[13px] rounded-t-[18px] bg-white mini:hover:shadow-md mini:hover:transition-shadow mini:hover:duration-200"
        >
          <div
            style={{
              height: width,
            }}
            className="flex flex-col relative"
          >
            <CompanyItemImages images={images} />
            <div className="flex absolute z-[2] bottom-4 left-4 shadow-md shadow-black/30 ring-white ring-2 rounded-full bg-transparent overflow-hidden">
              <LazyLoadImage
                src={company.company_logo.image_link.toString()}
                className="flex h-[35px] w-[35px]"
                alt={company.company_slug.toString()}
                placeholderSrc={company.company_logo.image_miniature.toString()}
                height={35}
                width={35}
                effect="blur"
              />
            </div>
          </div>
          <div className="flex flex-col leading-none space-y-2 px-3 pl-4 pb-3 items-start">
            <span className="text-[13px] font-bold line-clamp-1">
              {company.company_name.toUpperCase()}
            </span>
            <div className="flex flex-row justify-between w-full items-center">
              <span className="text-[16px] max-lg:text-[14px] font-medium line-clamp-1">
                {categoryType?.categorytype_name}
              </span>
              <div className="flex flex-row items-center space-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="flex w-[14px] h-[14px]"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="flex text-[15px] max-lg:text-[14px] font-normal line-clamp-1">
                  {item.evaluation}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-[6px]">
              <div className="flex flex-row items-center space-x-1 -mt-px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-[13px] h-[13px] -mt-[2px]"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-[13px] font-medium line-clamp-1">
                  {company.company_country?.locality_name ?? "localisation"}{" "}
                  {" - "} {company.company_locality_precision ?? "localisation"}
                </span>
              </div>
              <span className="text-[14px] leading-tight line-clamp-3 -mt-2">
                {item.category.category_description}
              </span>
              <div className="flex flex-row items-center space-x-2">
                <div className="flex flex-row items-center space-x-1">
                  <span className="flex text-[14px] leading-none font-normal line-clamp-1">
                    {item.comments}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[17px] h-[17px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
};

type PropsImgs = {
  images: Array<ImageModel>;
};

function CompanyItemImages({ images }: PropsImgs) {
  return (
    <div className={`flex flex-1 flex-col`}>
      {images.length !== 0 ? (
        <CategoryItemLaptopCarousel images={images} />
      ) : (
        <div className="flex flex-1">
          <RepeatLogo />
        </div>
      )}
    </div>
  );
}

const ImageLecoin = () => {
  const { width, element: elementDim } =
    useRefLayoutDimension<HTMLDivElement>();
  return (
    <div
      ref={elementDim}
      style={{ height: width }}
      className="flex flex-1 col-span-1"
    >
      <img
        src={LogoIcon}
        className="flex opacity-5 object-contain select-none pointer-events-none"
        alt={"LogoIcon"}
      />
    </div>
  );
};

function RepeatLogo() {
  return (
    <div className="grid h-full w-full grid-cols-3 grid-rows-3 gap-2 p-5">
      {Array.from({ length: 9 }).map((e, index) => {
        return <ImageLecoin key={index.toString()} />;
      })}
    </div>
  );
}

export default CategoryItemLaptop;
