import React from "react";
import MobileSkeleton from "./mobile";
import LaptopSkeleton from "./laptop";

const SkeletonDisposition = () => {
  return (
    <div className="flex flex-col w-full">
      <MobileSkeleton />
      <LaptopSkeleton />
    </div>
  );
};

export default SkeletonDisposition ;
