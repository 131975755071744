import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "./bigcarousel.css";
import PresentationChild from "./child";

const BigCarousel = ({ categories = [], maxLenght = 0 }) => {
  return (
    <div className="flex flex-col space-y-3">
      <Swiper
        loop={true}
        enabled={false}
        spaceBetween={25}
        slidesPerView={maxLenght}
        slidesPerGroupSkip={maxLenght}
        className="mySwiper"
      >
        {categories.map((item, index) => {
          return (
            <SwiperSlide
              key={"BigCarousel" + item.category.category_id.toString()}
            >
              <PresentationChild item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default BigCarousel;
