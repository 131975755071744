export function errorColor(error = false, data = {}) {
    const result = {
      usage: {
        border: error ? "border-1 border-red-700" : "",
        outline: error ? "outline-1 outline-red-700" : "",
        ring: error ? "ring-1 ring-red-700" : "",
        text: error ? "text-red-700" : "",
      },
      focus: {
        border: error ? "focus:border-red-700" : "focus:border-[#F08400]",
        outline: error ? "focus:outline-red-700" : "focus:outline-[#F08400]",
        ring: error ? "focus:ring-red-700" : "focus:ring-[#F08400]",
        text: error ? "focus:text-red-700" : "focus:text-[#F08400]",
      },
    };
    return { ...result, ...data };
  }
  