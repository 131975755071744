import React from "react";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import "./style.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useDimensions } from "../../../../../../../hooks/useDimensions";
import PlaceItem from "./item";

const CoinPlace = () => {
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const coin = useCoinStore((e) => e.coin);
  const places = coin?.places ?? [];
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  return (
    <div
      style={{
        display: places.length !== 0 ? "flex" : "none",
      }}
      className="flex flex-col px-6 space-y-4 mt-5"
    >
      <span className="flex text-[19px] leading-tight font-medium">
        Nous proposons à votre disposition :
      </span>
      <div className="flex flex-row w-full">
        <div ref={refDim} className="flex flex-1">
          <div
            ref={ref}
            className="keen-slider flex"
            style={{ maxWidth: dimensions?.width ?? 10 }}
          >
            {places.map((place) => {
              return (
                <PlaceItem key={place.place_id.toString()} place={place} />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex w-full mx-3 my-2 mb-0 self-center h-[1px] bg-black/10" />
    </div>
  );
};

export default CoinPlace;
