import React from "react";
import { usePremiumCategoryItemDimensions } from "../../functions/generateCategoryWidth";

const CategoryPresentationItemLoading = () => {
  const PremiumCategoryItemDimensions = usePremiumCategoryItemDimensions();
  return (
    <div
      style={{
        width: PremiumCategoryItemDimensions,
        height: PremiumCategoryItemDimensions,
      }}// max-w-[calc(100vw-43px)]
      className="flex col-span-1 w-full max-h-[55vh] relative rounded-xl border bg-activeGrey animate-pulse"
    />
  );
};

export default CategoryPresentationItemLoading;
