import { getUrl } from "../../apiRequest";

export function getPremiumCategoriesByTypeSlug(
  categoryTypeSlug: string = "all",
  max: number = 6
) {
  return getUrl(
    "/category/all?categorytypeslug=" +
      categoryTypeSlug +
      "&max=" +
      max.toString() +
      "&ispremium=true"
  );
}

export function getCategoriesByTypeSlug(
  categoryTypeSlug: string = "all",
  max: number = 30
) {
  return getUrl(
    "/category/all?categorytypeslug=" +
      categoryTypeSlug +
      "&max=" +
      max.toString()
  );
}

export function getHomePremiumCategories(max: number = 6) {
  return getUrl(
    "/category/all/homepage?max=" + max.toString() + "&ispremium=true"
  );
}

export function getHomeCategories(max: number = 30) {
  return getUrl("/category/all/homepage?max=" + max.toString());
}

export function getAllHomeCategories() {
  return getUrl("/category/all/homepage");
}
