import React from "react";

const DefaultFooter = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col w-full items-center border-t-2">
        <div className="flex flex-col w-full space-y-7 max-w-[2520px] px-5 xl:px-5 2xl:px-20">
          <div className="flex flex-col max-xl:space-y-6 xl:flex-row items-center justify-between py-8 px-3">
            <div className="flex w-full flex-col max-lg:space-y-5 lg:flex-row lg:w-fit items-center lg:space-x-4">
              <a
                href="https://pro.lecoinchicdumoment.com"
                target="_blank"
                className="flex"
                rel="noreferrer"
              >
                <span className="flex text-black">Professionnel</span>
              </a>
              <a href="/publicity" className="flex">
                <span className="flex text-black">Publicité</span>
              </a>
              <a href="/mention-legale" className="flex">
                <span className="flex text-black">Mention légale</span>
              </a>
              <a href="/condition-generale" className="flex">
                <span className="flex text-black">Condition générale</span>
              </a>
              <a href="/politique-de-confidentialite" className="flex">
                <span className="flex text-black">
                  Politique de confidentialité
                </span>
              </a>
            </div>
            <span className="flex text-[15px] text-center">
              Copyright © {new Date().getFullYear()} LeCoinChicDuMoment, SARL.
              All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DefaultFooter;
