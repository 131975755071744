import { getUrl, postUrl } from "../../apiRequest";

export function login(credentials = { login: "", password: "" }) {
  return postUrl("/auth/signin", credentials);
}

export function signup(credentials: any) {
  return postUrl("/auth/signup", credentials);
}

export function logout() {
  return getUrl("/auth/signout");
}

export function getUserData() {
  return getUrl("/auth/me");
}

export function updateFavoris(categoryId: string, action: string) {
  return getUrl("/auth/update/favoris?id=" + categoryId + "&action=" + action);
}

export function updateProfile(image_data: any) {
  return postUrl("/auth/update/profile", { image_data });
}

export function updateUser(update: any) {
  return postUrl("/auth/update", update);
}

export function getAllReservation() {
  return getUrl("/auth/reservation/all");
}
