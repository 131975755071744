import React from "react";
import { PlaceModel } from "../../../../../../../models/models";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import PlaceQuantity from "./quantity";

type Props = {
  place: PlaceModel;
};

const PlaceItem = ({ place }: Props) => {
  const [showMore, setShowMore] = React.useState(false);
  const addPlace = useCoinStore((e) => e.addPlace);
  const removePlace = useCoinStore((e) => e.removePlace);
  const incrementPlace = useCoinStore((e) => e.incrementPlace);
  const decrementPlace = useCoinStore((e) => e.decrementPlace);
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  const exist = addedPlaces.find((e) => e.place.place_id === place.place_id);
  function inc() {
    incrementPlace(place.place_id);
  }
  function dec() {
    decrementPlace(place.place_id);
  }
  return (
    <div
      key={place.place_id.toString()}
      className="keen-slider__slide number-slide bg-white max-w-[330px] w-[31.5%] min-w-[285px] h-[350px] flex flex-col border rounded-xl overflow-hidden"
    >
      <div className="flex h-[60%] w-full relative">
        <img
          className="flex flex-1 object-cover"
          src={place.place_images[0].image_link.toString()}
          alt={place.place_id.toString()}
        />
      </div>
      <div className="flex flex-1 flex-col px-2 pl-4 pr-3 pt-3 pb-2 space-y-3 overflow-y-auto">
        <div className="flex flex-col space-y-1">
          <span className="flex text-[18px] font-[600]">
            {place.place_name}
          </span>
          <span className={`text-[15px] leading-tight  ${showMore ? "" : "line-clamp-2"}`}>
            {place.place_description}
          </span>
        </div>
        <div className="flex flex-row space-x-2 items-center justify-between">
          {!exist ? (
            <span
              onClick={() =>
                !exist ? addPlace(place) : removePlace(place.place_id)
              }
              className="text-[15px] cursor-pointer text-primary w-fit px-2 py-[6px] rounded-md font-medium border"
            >
              {!exist ? "+ Ajouter au panier" : "Retirer du panier"}
            </span>
          ) : (
            <PlaceQuantity
              increment={inc}
              quantity={exist.length}
              decrement={dec}
            />
          )}
          <span
            onClick={() => setShowMore(!showMore)}
            className="text-[15px] cursor-pointer w-fit px-2 underline-offset-2 underline "
          >
            {showMore ? "Voir moins" : "Voir plus"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlaceItem;
