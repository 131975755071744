import React from "react";
import { usePopUpHook } from "../../../functions/popuphook";
import LoginModal from "../../modals/models/login";

const NotConnectedAccount = () => {
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLDivElement,
    HTMLDivElement
  >();
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div
          ref={refInput}
          onClick={switchMenu}
          className="flex flex-row p-[6px] pl-3 rounded-full border border-[rgba(150,150,150,.5)] space-x-3 max-xl:space-x-2 max-xl:pl-[10px] hover:shadow-md items-center"
        >
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="flex rounded-full h-[30px] w-[30px] max-xl:h-[24px] max-xl:w-[24px] bg-[rgba(150,150,150,.8)] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-5 h-5 max-xl:h-[16px] max-xl:w-[16px]"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        </div>
        <div
          ref={refPopUp}
          className="absolute select-none z-[700px] flex-col top-[69px] right-[22px] max-sm:right-[14px] 2xl:right-[82px] w-[200px] overflow-y-auto rounded-lg bg-white border py-1 shadow-xl shadow-black/5 text-[16px]"
          style={{
            visibility: open ? "visible" : "hidden",
          }}
        >
          <button
            className="flex w-full h-10 font-medium hover:bg-slate-100 items-center justify-center"
            onClick={() => setisOpen(true)}
          >
            <span className="flex">Connexion</span>
          </button>
          <a
            className="flex w-full h-10 font-medium hover:bg-slate-100 items-center justify-center"
            href="/signup"
            onClick={switchMenu}
          >
            <span className="flex">Inscription</span>
          </a>
        </div>
      </div>
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default NotConnectedAccount;
