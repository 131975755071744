import React from "react";
import { errorColor } from "../reactions/errorReaction";
import { isNumber, primaryColor } from "../../constants";
import { usePopUpHook } from "./popuphook";
import { LocalityModel } from "../../models/models";
import { useBasicsStore } from "../../configs/stores/basics";

function PhoneCountryInputSign({
  placeholder = "",
  defaultPhone = "",
  defaultCountry = Array<LocalityModel>()?.[0],
  errorState = { state: false, msg: "" },
  onSelectCountry = function (value: any) {},
  onTextChange = function (value: string) {},
}) {
  const {
    countries,
    isLoadingCountries: isLoading,
    getCountries,
  } = useBasicsStore();
  const [inputVal, setinputVal] = React.useState(defaultPhone);
  const [selectedCountry, setSelectedCountry] = React.useState<LocalityModel>(
    defaultCountry ?? undefined
  );
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >();
  const [errorColorStatus, setErrorColorStatus] = React.useState(
    errorColor(errorState.state)
  );
  React.useEffect(() => {
    onSelectCountry(selectedCountry?.locality_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);
  React.useEffect(() => {
    onTextChange(inputVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal]);
  React.useEffect(() => {
    setErrorColorStatus(errorColor(errorState.state));
  }, [errorState]);
  React.useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex relative flex-col w-full space-y-1">
      <div className="flex flex-col w-full relative space-y-1">
        <button
          className="absolute flex flex-row z-auto space-x-1 px-1 pl-1 pb-px pr-2 h-[80%] mt-2 justify-center bg-red-500/0 items-center w-[84px]"
          ref={refInput}
          onClick={switchMenu}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="black"
            className="w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
          <span className="flex w-fit text-[16px]">
            {selectedCountry?.locality_prefix ?? "000"}
          </span>
        </button>
        <input
          type={"text"}
          defaultValue={defaultPhone}
          className={`flex w-full py-3 px-4 pl-[84px] border rounded-full shadow-sm text-gray-900 placeholder-gray-500 text-[16px] max-sm:text-sm ${errorColorStatus.focus.border} ${errorColorStatus.focus.outline} ${errorColorStatus.focus.ring} ${errorColorStatus.usage.border} ${errorColorStatus.usage.outline} ${errorColorStatus.usage.ring}`}
          placeholder={placeholder}
          value={inputVal}
          onChange={function (t) {
            let txt = t.currentTarget.value.trim();
            (isNumber(txt) || txt === "") && setinputVal(txt);
          }}
        />
        {errorState.state && errorState.msg.length !== 0 && (
          <p
            className={`flex text-[10px] absolute -bottom-[17px] font-medium pl-5 ${errorColorStatus.usage.text}`}
          >
            {errorState.msg}
          </p>
        )}
      </div>
      <div
        ref={refPopUp}
        className="absolute select-none z-[400] bg-white max-h-[200px] w-full flex-col top-[56px] overflow-y-auto rounded-xl border overflow-hidden shadow-md shadow-black/5 text-[16px]"
        style={{
          visibility: open ? "visible" : "hidden",
        }}
      >
        {countries.length !== 0 ? (
          countries.map((cnt, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  setSelectedCountry(cnt);
                  // onSelectCountry(cnt);
                  switchMenu();
                }}
                className={`flex w-full pr-3 py-2 ${
                  cnt.locality_id === selectedCountry?.locality_id
                    ? "bg-primary text-white font-semibold hover:bg-hover"
                    : "hover:bg-slate-100 "
                }`}
              >
                <div className="flex w-[84px] justify-center">
                  +{cnt.locality_prefix}
                </div>
                <div className="flex flex-1">{cnt.locality_name}</div>
              </div>
            );
          })
        ) : isLoading ? (
          <div className="flex flex-row h-[50px] justify-center items-center space-x-4">
            <svg
              className="animate-spin h-5 w-5 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={primaryColor}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={primaryColor}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="flex">Chargement...</span>
          </div>
        ) : (
          countries.map((cnt, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  setSelectedCountry(cnt);
                  switchMenu();
                }}
                className={`flex w-full pr-3 py-2 ${
                  cnt.locality_id === selectedCountry?.locality_id
                    ? "bg-primary text-white font-semibold hover:bg-hover"
                    : "hover:bg-slate-100 "
                }`}
              >
                <div className="flex w-[84px] justify-center">
                  +{cnt.locality_prefix}
                </div>
                <div className="flex flex-1">{cnt.locality_name}</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
//
export default PhoneCountryInputSign;
