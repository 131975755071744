import React from "react";
import LaptopConnexe from "./laptop";
import MobileConnexe from "./mobile";

const EtablissmentConnexes = () => {
  return (
    <div className="flex flex-col w-full py-10">
      <MobileConnexe />
      <LaptopConnexe />
    </div>
  );
};

export default EtablissmentConnexes;
