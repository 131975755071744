const CinetPay = window.CinetPay;

const onlineApisUrl = "https://api.lecoinchicdumoment.com";
const localApisUrl = window.location.hostname.includes("local")
  ? "http://localhost:4010"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":4010"
  : onlineApisUrl;

export const CinetPay_Notify_Url = localApisUrl;

export const onlineUrl = "https://www.lecoinchicdumoment.com";
export const localUrl = window.location.hostname.includes("local")
  ? "http://localhost:3000"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":3000"
  : onlineUrl;

export const usedBaseUrl = localUrl;

export const config = {
  apikey: process.env.REACT_APP_apikey_cinetPay, // YOUR APIKEY
  site_id: process.env.REACT_APP_site_id_cinetPay, // YOUR_SITE_ID
  mode:
    window.location.hostname.includes("local") ||
    window.location.hostname.includes("192.168")
      ? "DEVELOPMENT"
      : "PRODUCTION",
};

CinetPay?.setConfig(config);

export { CinetPay };
export default CinetPay;
