import React from "react";
import LogoIcon from "../../../../assets/logoicon.png";

const SearchCompanyWelcome = () => {
  return (
    <div className="flex flex-1 select-none space-y-3 flex-col items-center justify-center">
      <img
        className="flex h-[30px] opacity-70"
        src={LogoIcon}
        alt="LogoIcon-LeCoinChicDuMoment"
      />
      {/* <span className="flex text-[15px]">Aucune recherche récente</span> */}
      <div className="flex space-y-1 text-[12px] lg:text-[14px] text-darkgrey/60 stroke-primary flex-col justify-center items-center">
        <span className="flex">
          Entrer le nom de l'établissement rechercher
        </span>
        <div className="flex flex-row items-center flex-wrap space-x-2">
          <span className="flex">puis appuyer sur </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
          <span className="flex">ou</span>
          <span className="flex bg-grey text-primary rounded-[3px] font-medium px-[6px] py-[2px] leading-tight text-[13px] h-fit">
            Entrer
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchCompanyWelcome;
