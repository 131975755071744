import React from "react";
import { primaryColor } from "../../../../constants";
import { useSearchStore } from "../../../../configs/stores/search";
import { useDebounce } from "@uidotdev/usehooks";

const SearchCompanyInput = ({ handleClose = () => {} }) => {
  const { onSearchTermChange, isLoading } = useSearchStore();
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchText, 200);
  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };
  function clear() {
    setSearchText("");
    onSearchTermChange("");
  }
  React.useEffect(() => {
    onSearchTermChange(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  return (
    <div className="flex flex-row space-x-3 px-3 pr-[10px] lg:space-x-5 lg:px-5 lg:pr-[18px] items-center border-b">
      <div
        onClick={() => handleClose()}
        className="flex cursor-pointer transition-all duration-200  rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="rgba(150,150,150,1)"
          className="w-5 h-5 lg:w-6 lg:h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="flex flex-1 flex-col">
        <input
          className="flex flex-1 w-full min-w-0 leading-none py-[15px] lg:pb-[20px] lg:pt-[24px] lg:text-[18px] font-medium outline-none placeholder:text-[rgba(150,150,150,.8)]"
          placeholder="Rechercher un coin chic..."
          type="text"
          value={searchText}
          autoFocus
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          display: searchText.length !== 0 ? "flex" : "none",
        }}
        onClick={() => clear()}
        className="flex cursor-pointer rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          className="w-[18px] h-[18px] stroke-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <button
        type="submit"
        className="flex cursor-pointer bg-grey hover:bg-hoverGrey active:bg-activeGrey transition-all duration-200 p-2 lg:p-[10px] rounded-full"
      >
        {isLoading ? (
          <svg
            className="animate-spin w-[15px] h-[15px] lg:w-[18px] lg:h-[18px] text-black/50"
            xmlns="http://www.w3.org/2000/svg"
            fill={"transparent"}
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke={primaryColor}
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill={primaryColor}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke={
              searchText.length !== 0 ? primaryColor : "rgba(150,150,150,.8)"
            }
            className=" w-[15px] h-[15px] lg:w-[18px] lg:h-[18px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default SearchCompanyInput;
