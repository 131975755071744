import React from "react";
import ModalCustom from "../../../../../../../components/modals/modal";
import { ReservationModel } from "../../../../../../../models/models";
// import { ReservationFormComponentTitle } from "../../../../coin/body/laptop/reservationform/components";
import ReservationCost from "./cost";
import ServicesAddedList from "./services";
import MenusAddedList from "./menus";
import PaymentModes from "./paymentModes";
// import { usePaymentHook } from "../../../../../../../hooks/usePaymentHook";

type PropsReservationItem = {
  reservation: ReservationModel;
  handleClose: () => void;
  open: boolean;
};

const ResumeReservation = ({
  handleClose = () => {},
  open,
  reservation,
}: PropsReservationItem) => {
  // const { startPayment } = usePaymentHook();
  const company = reservation.reservation_category.category_company;
  const category = reservation.reservation_category;
  function onSubmit() {
    // startPayment({
    //   cardPaymentForm: {
    //     ville: "",
    //     adresse: "",
    //     code_postal: "",
    //   },
    //   reservation,
    // });
  }
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <div className="flex w-[90vw] h-min min-w-[280px] max-w-[550px] max-md:w-[100vw] max-md:max-w-[90vw] max-md:px-[15px] flex-col space-y-4 px-[20px] py-3 pt-0">
          <div className="flex flex-col space-y-3 flex-1">
            <span className="flex text-[20px] font-medium">
              Résumer de votre réservation
            </span>
            <span className="flex text-[16px] font-medium leading-tight text-primary">
              {category.category_type.categorytype_name} -{" "}
              {company.company_name}
            </span>
            <div className="flex flex-col h-[60vh] max-h-[500px] max-md:h-[65vh] max-md:max-h-[65vh] overflow-y-auto pr-2 space-y-3">
              <div className="flex flex-col space-y-2">
                {/* {formValuesRefactorized.map((item, index) => {
                  const title = ReservationFormComponentTitle.filter(
                    (e) => e.slug === item.slug.toString()
                  )[0]?.label;
                  return (
                    <div
                      key={index}
                      className="flex flex-row w-full space-x-3 justify-between items-center"
                    >
                      <span className="flex text-[16px]">{title} :</span>
                      <span className="flex text-[15px] font-semibold">
                        {item.value}
                      </span>
                    </div>
                  );
                })} */}
              </div>
              <MenusAddedList reservation={reservation} />
              <ServicesAddedList reservation={reservation} />
              <div className="flex flex-row items-center justify-between py-2 border-t border-b">
                <div className="flex leading-tight flex-col font-semibold">
                  <span className="flex text-[15px]">Total commande</span>
                  <span className="flex text-[10.5px] font-normal">
                    NB: Payer à la consommation
                  </span>
                </div>
                {/* <span className="flex text-[14px] font-semibold">
                  {sommeAddedMenus + sommeAddedServices}
                  {" FCFA"}
                </span> */}
              </div>
              <ReservationCost reservation={reservation} />
              <PaymentModes />
            </div>
          </div>
          <button
            onClick={() => onSubmit()}
            className="flex w-full rounded-md bg-primary disabled:bg-gray-400 hover:bg-hover active:bg-active items-center justify-center py-[10px]"
          >
            <span className="flex font-semibold text-white no-underline">
              Finaliser ma réservation
            </span>
          </button>
        </div>
      </React.Fragment>
    </ModalCustom>
  );
};

export default ResumeReservation;
