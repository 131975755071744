import React from "react";
import {
  useConnexeCurrentShowItemsLengthPerRow,
  useCurrentShowItemsLengthPerRow,
} from "./useLayoutDimension";

export function useCurrentScreenPadding() {
  function funcCalPad0() {
    let padding = 0;
    const width = window.innerWidth;
    if (width < 1536) {
      padding = 20;
    } else {
      padding = 80;
    }
    return padding * 2;
  }
  const [padd, setpadd] = React.useState(funcCalPad0());

  React.useLayoutEffect(() => {
    function funcCalPad1() {
      let padding = 0;
      const width = window.innerWidth;
      if (width < 1536) {
        padding = 20;
      } else {
        padding = 80;
      }
      setpadd(padding * 2);
    }
    window.addEventListener("resize", funcCalPad1);
    // Désabonner l'écouteur lors du démontage du composant ou si l'élément référencé change
    return () => {
      window.removeEventListener("resize", funcCalPad1);
    };
  }, []);
  return padd;
}

export function useCurrentScreenWidthTransformed() {
  function func() {
    let width = window.innerWidth;
    if (width >= 2520) {
      width = 2520;
    }
    return width;
  }
  const [width, setwidth] = React.useState(func());
  React.useLayoutEffect(() => {
    function func1() {
      let width = window.innerWidth;
      if (width >= 2520) {
        width = 2520;
      }
      setwidth(width);
    }
    window.addEventListener("resize", func1);
    // Désabonner l'écouteur lors du démontage du composant ou si l'élément référencé change
    return () => {
      window.removeEventListener("resize", func1);
    };
  }, []);
  return width;
}

export function useCurrentScreenWidthTransformedCoinCarousel() {
  function func() {
    let width = window.innerWidth;
    if (width >= 1680) {
      width = 1680;
    }
    return width;
  }
  const [width, setwidth] = React.useState(func());
  React.useLayoutEffect(() => {
    function func1() {
      let width = window.innerWidth;
      if (width >= 1680) {
        width = 1680;
      }
      setwidth(width);
    }
    window.addEventListener("resize", func1);
    // Désabonner l'écouteur lors du démontage du composant ou si l'élément référencé change
    return () => {
      window.removeEventListener("resize", func1);
    };
  }, []);
  return width;
}

export function usePremiumCategoryItemDimensions() {
  const width = useCurrentScreenWidthTransformed();
  const _itemLenght = useCurrentShowItemsLengthPerRow() / 2 - 1;
  const itemLenght = _itemLenght === 0 ? 1 : _itemLenght;
  const PADDING = useCurrentScreenPadding();
  const SPaceBetween_Item = 25 * (itemLenght - 1);
  return (width - PADDING - SPaceBetween_Item) / itemLenght;
}

export function useCategoryItemDimensions() {
  const width = useCurrentScreenWidthTransformed();
  const itemLenght = useCurrentShowItemsLengthPerRow() / 2;
  const PADDING = useCurrentScreenPadding();
  const SPaceBetween_Item = 24 * (itemLenght - 1);
  return (width - PADDING - SPaceBetween_Item) / itemLenght;
}

export function useConnexeCurrentScreenWidthTransformed() {
  function func() {
    let width0 = window.innerWidth;
    if (width0 >= 1280 && width0* 0.85 < 1500) {
      return width0 * 0.85;
    } else if (width0* 0.85 >= 1500) {
      return 1500;
    } else {
      return width0 - 38;
    }
  }
  const [width, setwidth] = React.useState(func());
  React.useLayoutEffect(() => {
    function func1() {
      let width1 = window.innerWidth;
      if (width1 >= 1280 && width1* 0.85 < 1500) {
        setwidth(width1 * 0.85);
      } else if (width1* 0.85 >= 1500) {
        setwidth(1500);
      } else {
        setwidth(width1 - 38);
      }
    }
    window.addEventListener("resize", func1);
    // Désabonner l'écouteur lors du démontage du composant ou si l'élément référencé change
    return () => {
      window.removeEventListener("resize", func1);
    };
  }, []);
  return width;
}

export function useCategoryItemConnexeDimensions() {
  const width = useConnexeCurrentScreenWidthTransformed();
  const itemLenght = useConnexeCurrentShowItemsLengthPerRow() / 2;
  const SPaceBetween_Item = 24 * (itemLenght - 1);
  return (width - SPaceBetween_Item) / itemLenght;
}
