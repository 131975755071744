import React from "react";
import { ReservationModel } from "../../../../../models/models";
// import { ReservationFormComponentTitle } from "../../coin/body/laptop/reservationform/components";
import ResumeReservation from "./components/resume";
import { useAuthStore } from "../../../../../configs/stores/auth";
import moment from "moment";

const ReservationsWait = () => {
  const all = useAuthStore((e) => e.reservations);
  const reservations = all.filter(
    (e) => e.reservation_state.toString() === "wait"
  );
  return (
    <React.Fragment>
      <div className="collapse rounded-lg collapse-arrow border bg-white">
        <input type="checkbox" />
        <div className="collapse-title flex flex-row justify-between text-lg font-medium">
          <span className="flex text-[17px] ml-2">En attente de paiement</span>
          <span className="flex font-normal text-[16px]">
            {reservations.length.toString()}
          </span>
        </div>
        <div className="collapse-content space-y-[14px] flex flex-col">
          {reservations.map((item, index) => {
            return (
              <ReservationItem
                key={item.reservation_id.toString()}
                reservation={item}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

type PropsReservationItem = { reservation: ReservationModel };

function ReservationItem({ reservation }: PropsReservationItem) {
  const refetch = useAuthStore((e) => e.refetchAllReservation);
  const [remainingTime, setRemainingTime] = React.useState(calculateTimeLeft());
  const [isOpen, setisOpen] = React.useState(false);
  const [isOutDated, setIsOutDated] = React.useState(true);
  function closeResume() {
    setisOpen(false);
  }
  const categoryType = reservation.reservation_category.category_type;

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = calculateTimeLeft();
      setRemainingTime(newRemainingTime);
      if (parseInt(newRemainingTime.seconds) < 0) {
        setTimeout(() => {
          refetch();
        }, 5000);
        setIsOutDated(true);
        setRemainingTime({
          minutes: "00",
          seconds: "00",
        });
        clearInterval(interval);
      } else {
        if (isOutDated) {
          setIsOutDated(false);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function calculateTimeLeft() {
    const now = moment();
    const saveTime = moment(reservation.reservation_created_date);
    const expirationTime = saveTime.add(5, "minutes");
    const duration = moment.duration(expirationTime.diff(now));
    return {
      // days: duration.days(),
      // hours: duration.hours(),
      minutes: duration.minutes().toString(),
      seconds: duration.seconds().toString(),
    };
  }
  return (
    <React.Fragment>
      <div className="flex flex-col items-start space-y-3 py-3 px-3 bg-slate-500/10 rounded-[10px]">
        <span className="flex text-[15px] font-medium">
          Temps restant:{" "}
          {`${remainingTime.minutes} minutes ${remainingTime.seconds} seconds`}
        </span>
        <div className="flex flex-row items-start space-x-4">
          <img
            alt={reservation.reservation_id.toString()}
            src={
              reservation.reservation_category?.category_company?.company_logo?.image_link?.toString() ??
              ""
            }
            className="flex rounded-full h-[70px] w-[70px]"
          />
          <div className="flex flex-col pt-2 leading-tight space-y-1">
            <span className="flex text-[17px] font-semibold">
              {reservation.reservation_category?.category_company.company_name}
            </span>
            <span className="flex text-[16px] font-medium">
              {categoryType.categorytype_name}
            </span>
            <span className="flex text-[16px]">
              {"Réservation de " +
                reservation.reservation_places
                  .map((e) => `(x${e.length}) ${e.place.place_name}, `)
                  .reduce<string>((prev, curr, i, arr) => prev + curr, "") +
                (reservation.reservation_services.length !== 0
                  ? " ; Services réservés: " +
                    reservation.reservation_services.reduce<string>(
                      (prev, curr, i, arr) => prev + ", " + curr.service_name,
                      ""
                    )
                  : "")}
            </span>
            <span className="flex text-[15px]">
              Jour d'arrivée:{" "}
              {moment(reservation.reservation_arrival_day).format("LL")}
              {" à "}
              {reservation.reservation_arrival_time}
            </span>
            <span className="flex text-[15px] font-semibold select-none">
              Frais: {reservation.reservation_price} FCFA
            </span>
            {!isOutDated ? (
              <span
                onClick={() => setisOpen(true)}
                className="flex text-[15px] font-semibold select-none underline text-primary active:text-active cursor-pointer"
              >
                Finaliser ma réservation
              </span>
            ) : (
              <span
                onClick={() => setisOpen(true)}
                className="flex text-[15px] font-semibold select-none text-red-500"
              >
                Réservation annulée
              </span>
            )}
          </div>
        </div>
      </div>
      <ResumeReservation
        open={isOpen}
        handleClose={closeResume}
        reservation={reservation}
      />
    </React.Fragment>
  );
}

// type Props = {
//   documentSaveTime: string;
//   expirationDuration: number;
// };

// const Countdown = ({ documentSaveTime, expirationDuration }: Props) => {
//   return (
//     <span>
//       {`${remainingTime.minutes} minutes ${remainingTime.seconds} seconds`}
//     </span>
//   );
// };

export default ReservationsWait;
