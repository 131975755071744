import { useCategoryItemDimensions } from "../../../functions/generateCategoryWidth";
import { ImageModel } from "../../../models/models";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type CarouselSlideProp = {
  image: ImageModel;
};

export function CarouselSlide({ image }: CarouselSlideProp) {
  const CategoryItemDimensions = useCategoryItemDimensions();
  return (
    <div className="flex flex-col w-full select-none">
      <LazyLoadImage
        src={image.image_link.toString()}
        alt={image.image_id.toString()}
        placeholderSrc={image.image_miniature.toString()}
        height={CategoryItemDimensions}
        width={"100%"}
        effect="blur"
      />
    </div>
  );
}
