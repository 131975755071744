import React from "react";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
// import PlaceItem from "./item";
import PlaceItem2 from "./item2";

const CoinPlace = () => {
  const coin = useCoinStore((e) => e.coin);
  const places = coin?.places ?? [];
  return (
    <div
      style={{
        display: places.length !== 0 ? "flex" : "none",
      }}
      className="flex flex-col px-2 space-y-4 mt-5"
    >
      <span className="flex text-[20px] leading-tight font-medium">
        Nous proposons à votre disposition :
      </span>
      {/* <div className="flex flex-col space-y-3">
        {places.map((place) => {
          return <PlaceItem key={place.place_id.toString()} place={place} />;
        })}
      </div> */}
      <div className="flex flex-row flex-wrap gap-4">
        {places.map((place) => {
          return <PlaceItem2 key={place.place_id.toString()} place={place} />;
        })}
      </div>
      <div className="flex w-full mx-3 my-2 mb-0 self-center h-[1px] bg-black/10" />
    </div>
  );
};

export default CoinPlace;
