import React from "react";

export function useGetScrollPos(pos=0) {
  const [atZero, setAtZero] = React.useState(true);
  React.useEffect(() => {
    function handleScroll() {
      if (window.scrollY === pos && atZero === false) {
        setAtZero(true);
      } else {
        if (atZero) {
          setAtZero(false);
        }
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [atZero,pos]);
  return { atZero };
}
