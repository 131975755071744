import React from "react";
import CoinLogo from "./logo";
import IconButton from "@mui/material/IconButton";
import { useSearchParams } from "react-router-dom";
import { useMuiPopup } from "../../../../../../components/popup";
import OurContactModal from "../../../../../../components/modals/models/ourcontacts";
import { useCoinStore } from "../../../../../../configs/stores/coin";
import { CompanyModel } from "../../../../../../models/models";

const CoinHeaderMobile = () => {
  const [searchParams] = useSearchParams();
  const { refElement, Popup, switchMenu } = useMuiPopup<HTMLDivElement>();
  const coin = useCoinStore((e) => e.coin);
  const company = (coin?.company ?? {}) as CompanyModel;
  const categories = coin?.categories ?? [];
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex xl:hidden -mt-5 flex-col bg-white">
        <div className="flex flex-row border-b px-5 pl-4 py-4 justify-between items-center">
          <div className="flex flex-row items-center space-x-4">
            <CoinLogo />
            <div className="flex flex-col pt-[1px] space-y-[2px]">
              <span className="font-bold text-[15px] leading-tight">
                {company.company_name}
              </span>
              <span className="leading-tight font-medium text-[13px]">
                {company?.company_country?.locality_name ?? "localisation"}{" "}
                {" - "} {company?.company_locality_precision ?? "localisation"}
              </span>
            </div>
          </div>
          <div className="flex transition-all flex-row items-center space-x-5">
            <div
              onClick={switchMenu}
              className="hidden md:flex flex-row gap-2 items-center px-2 py-2 active:bg-slate-400/20 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
              <span ref={refElement} className="flex text-[17px] font-semibold">
                {
                  categories.filter(
                    (item) =>
                      searchParams.get("category") ===
                      item.category_type?.categorytype_slug?.toString()
                  )[0]?.category_type.categorytype_name
                }
              </span>
            </div>
            <div
              onClick={() => setisOpen(true)}
              className="hidden md:flex bg-primary flex-row items-center px-5 py-2 active:bg-active rounded-md"
            >
              <span className="flex text-[16px] mt-px text-white font-medium">
                Contactez-nous
              </span>
            </div>
            <Popup
              elevation={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="flex flex-col py-1 space-y-2 px-1 min-w-[120px]">
                {categories.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={"?category=" + item.category_type.categorytype_slug}
                    >
                      <div className="group flex px-3 py-2 rounded-md hover:bg-hover">
                        <span
                          className={`flex transition-all ${
                            searchParams.get("category") ===
                            item.category_type.categorytype_slug
                          }
                            ? "font-bold text-hover group-hover:text-white"
                            : "text-black"
                        }`}
                        >
                          {item.category_type.categorytype_name}
                        </span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </Popup>
          </div>
        </div>
        <SwithServ />
      </div>
      <OurContactModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

const SwithServ = () => {
  const [searchParams] = useSearchParams();
  const { refElement, Popup, switchMenu } = useMuiPopup<HTMLDivElement>();
  const coin = useCoinStore((e) => e.coin);
  const categories = coin?.categories ?? [];
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div
        ref={refElement}
        className="flex md:hidden pr-2 transition-all flex-row justify-between items-center space-x-2"
      >
        <div
          onClick={switchMenu}
          className="flex flex-row space-x-2 items-center px-2 py-2 active:bg-slate-400/50 rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
          <span className="flex text-[18px] font-semibold text-black">
            {
              categories.filter(
                (item) =>
                  searchParams.get("category") ===
                  item.category_type.categorytype_slug
              )[0]?.category_type.categorytype_name
            }
          </span>
        </div>
        <IconButton
          edge="end"
          color="default"
          onClick={() => setisOpen(true)}
          aria-label="delete"
          size="small"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={"black"}
            className="w-5 h-5 m-2"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd"
            />
          </svg>
        </IconButton>
        <Popup
          elevation={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="flex flex-col py-1 space-y-2 px-1 min-w-[200px] max-w-[80%]">
            {categories.map((item, index) => {
              return (
                <a
                  key={index}
                  href={"?category=" + item.category_type.categorytype_slug}
                >
                  <div className="group flex px-3 py-2 rounded-md hover:bg-hover">
                    <span
                      className={`flex transition-all ${
                        searchParams.get("category") ===
                        item.category_type.categorytype_slug
                          ? "font-bold text-hover group-hover:text-white"
                          : "text-black"
                      }`}
                    >
                      {item.category_type.categorytype_name}
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
        </Popup>
      </div>
      <OurContactModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default CoinHeaderMobile;
