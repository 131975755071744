// import axios from "axios";

// export const onlineApisUrl = "https://api.lecoinchicdumoment.com";
// export const localApisUrl = window.location.hostname.includes("local")
//   ? "http://localhost:4010"
//   : window.location.hostname.includes("192.168")
//   ? "http://" + window.location.hostname + ":4010"
//   : onlineApisUrl;

// export const usedBaseUrl = localApisUrl;

// export const api = axios.create({
//   baseURL: usedBaseUrl + "/v1",
//   withCredentials: true,
// });

// // Intercepteur pour ajouter automatiquement l'access token à chaque requête sortante
// api.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

import axios from "axios";
import { decrypt, encrypt } from "./encryption";
import { useAuthStore } from "./stores/auth";
import { useLocation, useNavigate } from "react-router-dom";

export const onlineApisUrl = "https://api.lecoinchicdumoment.com";
// export const localApisUrl = window.location.hostname.includes("local")
//   ? "http://localhost:4010"
//   : window.location.hostname.includes("192.168")
//   ? "http://" + window.location.hostname + ":4010"
//   : onlineApisUrl;

export const localApisUrl = window.location.hostname.includes(
  "lecoinchicdumoment"
)
  ? onlineApisUrl
  : "http://" + window.location.hostname + ":4010";

export const usedBaseUrl = localApisUrl;

export const api = axios.create({
  baseURL: usedBaseUrl + "/v1/client",
  // withCredentials: true,
});

export function useAuthApiInterceptor() {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const refreshToken = useAuthStore((e) => e.refreshToken);
  const tokens = useAuthStore((e) => e.tokens);
  const logout = useAuthStore((e) => e.logout);
  const navigate = useNavigate();
  const location = useLocation();

  // Interceptiion des requetes sortantes
  api.interceptors.request.use(
    (config) => {
      if (!!tokens) {
        const lastTokens = decrypt(
          tokens,
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        const newTokens = encrypt(
          JSON.stringify(lastTokens),
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        config.headers.Authorization = `Bearer ${newTokens}`; // Ajouter le token à l'en-tête
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Interceptiion des requetes entrantes
  api.interceptors.response.use(
    (response) => {
      if (!!response.data?.tokens) {
        refreshToken(response.data.tokens);
      }
      return response;
    },
    async (error) => {
      if (error?.response?.status === 403) {
        if (isAuthenticated) {
          await logout(() => {
            navigate("login", { state: { from: location }, replace: true });
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return true;
}
