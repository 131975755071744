import React from "react";
import ModalCustom from "../../modal";
import { useCoinStore } from "../../../../configs/stores/coin";
import AddedPlaceItem from "./item";

const CoinAddedPlacesModal = ({
  handleClose = () => {},
  isCancelable = true,
  open = false,
}) => {
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <div className="flex flex-col p-5 pt-2 space-y-4 w-[450px] max-w-[80vw]">
          <div className="flex w-full justify-start pt-0 pb-1">
            <h2 className="flex text-center text-lg font-semibold tracking-tight max-sm:text-xl">
              Places ajoutées
            </h2>
          </div>
          <div className="flex flex-col space-y-[24px] pr-2 max-h-[350px] overflow-y-auto">
            {addedPlaces.map((e) => {
              return (
                <AddedPlaceItem
                  key={e.place.place_id.toString()}
                  isCancelable={isCancelable}
                  place={e.place}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    </ModalCustom>
  );
};

export default CoinAddedPlacesModal;
