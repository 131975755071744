import React from "react";
import { ReservationModel } from "../../../../../../../models/models";

type Props = {
  reservation:ReservationModel;
}

const MenusAddedList = ({reservation}:Props) => {
  const [isOpen, setisOpen] = React.useState(false);
  // const length = menus.reduce((prev, curr) => prev + curr.length, 0);
  // const sommme = menus.reduce(
  //   (prev, curr) =>
  //     prev + parseFloat(curr.menu.menu_price.toString()) * curr.length,
  //   0
  // );
  return (
    <div className="flex flex-col space-y-2">
      <div
        onClick={() => setisOpen(!isOpen)}
        className="group cursor-pointer flex flex-row items-center space-x-2 justify-between h-[30px] pl-[6px]"
      >
        <div className="flex flex-row items-center space-x-[14px]">
          <div className="flex ring-[6px] pl-px pt-px group-hover:ring-slate-300 ring-slate-200 group-hover:bg-slate-300 max-md:group-hover:ring-slate-200 max-md:group-hover:bg-slate-200  bg-slate-200 duration-300 transition-all rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="rgba(0,0,0,.7)"
              className="w-4 h-4"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              )}
            </svg>
          </div>
          <div className="flex flex-row items-center space-x-1">
            <span className="flex text-[16px] font-medium">Menus</span>
            <span className="flex text-[14px] font-medium">
              {/* ({length !== 0 ? "x" + length : "vide"}) */}
            </span>
          </div>
        </div>
        {/* <span className="flex text-[15px] font-semibold">{sommme} FCFA</span> */}
      </div>
      <div
        style={{ display: isOpen ? "flex" : "none" }}
        className="flex flex-col space-y-2 pl-[8px] text-[14px] border-l ml-[14px]"
      >
        {/* {menus.map(({ menu, length }, index) => {
          return (
            <div
              key={menu.menu_id.toString()}
              className="flex flex-row justify-between"
            >
              <span className="line-clamp-1">{menu.menu_name}</span>
              <div className="flex flex-row items-center space-x-1">
                <span className="flex">
                  {menu.menu_price} FRCFA
                </span>
                <span className="flex leading-none pb-[1.5px]">x</span>
                <span className="flex">{length}</span>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default MenusAddedList;
