import React from "react";
import { ANY } from "../../constants";
import Popover from "@mui/material/Popover";

export function useMuiPopup<Type extends HTMLElement>() {
  const [open, setOpen] = React.useState(false);
  const refElement = React.useRef<Type>(null);
  function switchMenu() {
    setOpen(!open);
  }
  function close() {
    setOpen(false);
  }
  function Popup({
    children = <></>,
    anchorOrigin = ANY,
    transformOrigin = ANY,
    elevation = 4,
  }) {
    return (
      <Popover
        sx={{
          marginTop: "5px",
        }}
        open={open}
        anchorEl={refElement.current}
        onClose={close}
        elevation={elevation}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
          ...transformOrigin,
        }}
      >
        {children}
      </Popover>
    );
  }
  return { refElement, open, switchMenu, Popup: Popup };
}
