import React from "react";

type Props = {
  threshold?: number;
};

export function useLoadOnAppear<T extends HTMLElement>({
  threshold = 0,
}: Props) {
  const targetRef = React.useRef<T>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const options = {
      root: null, // Utilise le viewport comme root
      rootMargin: "0px", // Aucune marge autour de la zone racine
      threshold: threshold, // Se déclenche lorsque 10% de la cible est visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Arrêter l'observation une fois que la cible est visible
        }
      });
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Nettoyage de l'observateur lors du démontage
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [threshold]);

  return { element: targetRef, isVisible };
}
