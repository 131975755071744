import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { searchTermCompanyCategories } from "./functions/search";
import { CategoryModel, ImageModel } from "../../models/models";

const LOCAL_DATASTORE_NAME = "coinchiclient-search";

export type SearchResultType = {
  category: CategoryModel;
  images: Array<ImageModel>;
  comments: number;
  evaluation: number;
};

type ContextProps = {
  isLoading: boolean;
  searchTerm: string;
  filter: Array<{ name: ""; constrain: any }>;
  onSearchTermChange: (value: string) => void;
  searchTermCompanyCategories: (
    search: string,
    filter?: Object
  ) => Promise<any>;
};

export const useSearchStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      filter: [],
      categories: [],
      searchTerm: "",
      isLoading: false,
      onSearchTermChange: (value: string) => {
        set(
          produce((state: ContextProps) => {
            state.searchTerm = value;
          })
        );
      },
      searchTermCompanyCategories: async (search) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const state = get();
        const filter = state.filter.reduce((prev, curr, index, arr) => {
          return { ...prev, [curr.name]: curr.constrain };
        }, {});
        const res = await searchTermCompanyCategories(search, filter);
        const data = (res.data ?? []) as Array<SearchResultType>;
        set(
          produce((state: ContextProps) => {
            state.isLoading = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_sch")),
    }
  )
);
