import { getUrl, postUrl } from "../../configs/apiRequest";
import queryString from "query-string";

export function getRoleTypesData() {
  return getUrl("/role/type/all");
}

export function getLangsData() {
  return getUrl("/lang/all");
}

export function getDefaultDatas() {
  return getUrl("/coin/default/all");
}

export function getCategoriesDatas(
  category: string = "all",
  max: string = "30",
  maxPerRow: string = "12"
) {
  return getUrl(
    "/coin/category/all?" + queryString.stringify({ category, max, maxPerRow })
  );
}

export function getCategoryDatas(
  companySlug: string,
  category?: string,
  user?: string
) {
  return getUrl(
    "/coin/category?" + queryString.stringify({ companySlug, category, user })
  );
}

export function getCountriesData() {
  return getUrl("/locality/search/type?value=pays");
}

export function getManagerRolesData(data: any) {
  return getUrl("/role/manager?id=" + data);
}

export function postCompanyData(data: any) {
  return postUrl("/company/auth/signup", data);
}

export function updateCompanyImageData(id: string, data: any) {
  return postUrl("/company/update/logo?id=" + id, data);
}

export function getCategoryTypesData() {
  return getUrl("/categorytype/all");
}

export function getCoinCompanyData(slug: string) {
  return getUrl("/company/coin/company?slug=" + slug);
}

export function getCoinCategoriesData(id: string) {
  return getUrl("/company/coin/categories?id=" + id);
}

export function getCoinCategoryData(id: string, user?: string) {
  return getUrl(
    "/company/coin/categorydefault?id=" + id + (user ? "&user=" + user : "")
  );
}

export function getCategoriesData(id: string) {
  return getUrl("/company/category/all?id=" + id);
}

export function postCategoryData(id: string, category: string) {
  return postUrl("/company/category/new?id=" + id, { category });
}

export function getCurrenciesData() {
  return getUrl("/currency/all");
}

export function getSearchCategoriesData(search: object) {
  return getUrl("/search?" + queryString.stringify(search));
}

export function getCategoryData(data: string) {
  return getUrl("/company/category/get?value=" + data);
}

export function getCategoryImages(data: any) {
  return getUrl("/image/all?id=" + data);
}

export function getMenucardsData(id: string) {
  return getUrl("/company/category/menucard/coin?id=" + id);
}
export function getServicesData(id: string) {
  return getUrl("/company/category/service/coin?id=" + id);
}
export function getEvaluationData(id: string) {
  return getUrl("/company/category/evaluation/coin?id=" + id);
}
export function getCommentsData(id: string) {
  return getUrl("/company/category/comment/coin?id=" + id);
}

export function getFavorisData() {
  return getUrl("/user/favoris/get");
}

export function getReservationsData() {
  return getUrl("/user/reservation/get");
}

export function updateFavorisData(id: string, action: string) {
  return postUrl("/user/favoris/update", { id, action });
}

export function updateEvaluationData(id: string, evaluation: string) {
  return postUrl("/user/evaluate", { id, evaluation });
}

export function updateUserData(update: any) {
  return postUrl("/user/auth/update", { update });
}

export function updateUserProfileData(image_data: any) {
  return postUrl("/user/auth/update/profile", { image_data });
}
