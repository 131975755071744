import React from "react";
import PlaceQuantity from "../../../../pages/home/pages/coin/body/laptop/place/quantity";
import { useCoinStore } from "../../../../configs/stores/coin";
import { PlaceModel } from "../../../../models/models";
import { ToastWarnNotifier } from "../../../../constants";
type Props = {
  place: PlaceModel;
  isCancelable?: boolean;
};
const AddedPlaceItem = ({ place, isCancelable = true }: Props) => {
  const addPlace = useCoinStore((e) => e.addPlace);
  const removePlace = useCoinStore((e) => e.removePlace);
  const incrementPlace = useCoinStore((e) => e.incrementPlace);
  const decrementPlace = useCoinStore((e) => e.decrementPlace);
  const addedPlaces = useCoinStore((e) => e.addedPlaces);
  const exist = addedPlaces.find((e) => e.place.place_id === place.place_id);
  function inc() {
    incrementPlace(place.place_id);
  }
  function dec() {
    if (
      !isCancelable &&
      addedPlaces.length === 1 &&
      addedPlaces[0].length === 1
    ) {
      ToastWarnNotifier({
        message: "Action non autorisée",
      });
      return;
    }
    decrementPlace(place.place_id);
  }
  return (
    <div className="flex flex-col justify-center space-y-2 leading-tight border-b pb-3">
      <div className="flex flex-col space-y-3">
        <span className="text-[17px] font-medium">- {place.place_name}</span>
        <div className="flex flex-row justify-end space-x-2 items-center">
          {!exist ? (
            <span
              onClick={() =>
                !exist ? addPlace(place) : removePlace(place.place_id)
              }
              className="text-[15px] cursor-pointer text-primary w-fit px-2 py-[6px] rounded-md font-medium border"
            >
              {!exist ? "+ Ajouter au panier" : "Retirer du panier"}
            </span>
          ) : (
            <PlaceQuantity
              increment={inc}
              quantity={exist.length}
              decrement={dec}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddedPlaceItem;
