import React from "react";
import { errorColor } from "../reactions/errorReaction";

export function TextInputSign({
  title = "",
  defaultValue = "",
  placeholder = "",
  type = "text",
  errorState = { state: false, msg: "" },
  onTextChange = function (value: string) {},
}) {
  const errorColorStatus = errorColor(errorState.state);
  return (
    <div className="flex flex-col w-full space-y-1 relative">
      <input
        type={type}
        defaultValue={defaultValue}
        className={`flex w-full py-3 px-4 border rounded-full shadow-sm text-gray-900 placeholder-gray-500 text-[16px] max-sm:text-sm ${errorColorStatus.focus.border} ${errorColorStatus.focus.outline} ${errorColorStatus.focus.ring} ${errorColorStatus.usage.border} ${errorColorStatus.usage.outline} ${errorColorStatus.usage.ring}`}
        placeholder={placeholder}
        onChange={function (t) {
          onTextChange(t.currentTarget.value);
        }}
      />
      {errorState.state && errorState.msg.length !== 0 && (
        <p
          className={`flex text-[10px] absolute -bottom-[17px] font-medium pl-5 ${errorColorStatus.usage.text}`}
        >
          {errorState.msg}
        </p>
      )}
    </div>
  );
}

export function TextInput({
  title = "",
  placeholder = "",
  type = "text",
  rounded = false,
  defaultValue = "",
  errorState = { state: false, msg: "" },
  onTextChange = function (value: string) {},
}) {
  const [errorColorStatus, setErrorColorStatus] = React.useState(
    errorColor(errorState.state)
  );
  React.useEffect(() => {
    setErrorColorStatus(errorColor(errorState.state));
  }, [errorState]);
  return (
    <div className="flex flex-col w-full space-y-2">
      {title && <p className="flex text-[16px]">{title}</p>}
      <input
        type={type}
        defaultValue={defaultValue ?? ""}
        className={`flex w-full py-[10px] px-4 border ${
          rounded ? "rounded-full" : "rounded-md "
        } shadow-sm text-gray-900 placeholder-gray-500 text-[16px] font-normal max-sm:text-sm ${
          errorColorStatus.focus.border
        } ${errorColorStatus.focus.outline} ${errorColorStatus.focus.ring} ${
          errorColorStatus.usage.border
        } ${errorColorStatus.usage.outline} ${errorColorStatus.usage.ring}`}
        placeholder={placeholder}
        onChange={function (t) {
          onTextChange(t.currentTarget.value);
        }}
      />
      {errorState.state && errorState.msg.length !== 0 && (
        <p
          className={`flex text-[12px] font-medium ${errorColorStatus.usage.text}`}
        >
          {errorState.msg}
        </p>
      )}
    </div>
  );
}

export default TextInputSign;
