import React from "react";
import IconEtab from "../../assets/iconEtab.svg"

const AddEtablissment = () => {
  return (
    <>
      <a
        className={`hidden p-2 px-4 space-x-2 items-center rounded-full select-none transition-all bg-primary hover:bg-hover 2xl:flex`}
        href={"https://pro.lecoinchicdumoment.com/"}
        target="_blank" rel="noreferrer"
      >
        <img src={IconEtab} alt="IconEtab"/>
        <span className="flex text-white text-[15px] font-medium whitespace-nowrap">
          Établissement
        </span>
      </a>
      <a
        className={`flex p-[9px] px-3 rounded-full select-none transition-all bg-primary hover:bg-hover max-lg:hidden 2xl:hidden`}
        href={"https://pro.lecoinchicdumoment.com/"}
        target="_blank" rel="noreferrer"
      >
        <img src={IconEtab} alt="IconEtab"/>
      </a>
    </>
  );
};

export default AddEtablissment;
