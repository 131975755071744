import React from "react";
import CoinBodyLaptop from "./laptop";
import CoinBodyMobile from "./mobile";

const CoinBody = () => {
  return (
    <div className="flex flex-col">
      <CoinBodyLaptop />
      <CoinBodyMobile />
    </div>
  );
};

export default CoinBody;
