import React from "react";
import CategoryTypeRow from "./item";
import CategoriesPresentation from "../../../../../components/presentation";
import CategoryTypeRowLoading from "./loadingItem";
import { useCategoryStore } from "../../../../../configs/stores/categories";
import { useOneEffect } from "../../../../../hooks/useOnEffect";
import _ from "lodash";
import { Helmet } from "react-helmet";

const HomeAllCategories = () => {
  const isloadingHomeCategories = useCategoryStore(
    (e) => e.isloadingHomeCategories
  );
  const categoriesHome = useCategoryStore((e) => e.categoriesHome);
  const isLoadingHomePremiumCategories = useCategoryStore(
    (e) => e.isLoadingHomePremiumCategories
  );
  const premiumHomeCategories = useCategoryStore(
    (e) => e.premiumHomeCategories
  );
  const getAllHomeCategories = useCategoryStore((e) => e.getAllHomeCategories);
  useOneEffect(() => {
    getAllHomeCategories();
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Le Coin Chic Du Moment - Réserver un coin chic maintenant</title>
        <meta
          name="description"
          content="Trouvez les meilleures adresses touristiques du pays et réservez-les facilement dès maintenant pour vos moments inoubliables."
        />
        <meta
          name="keywords"
          content="le coin chic du moment, LeCoinChicDuMoment, lecoinchicdumoment, chicdumoment, chic coin, reservation en ligne, reservation, plateforme, carte d'etablissement, coin, coin chic, chic coin, coins, chics, espace, rapide, facile, simple, categorie, boutique en ligne, achats en ligne, hotel, restaurant, residence meublee, circuit touristique, espace enfant, espace event, night club"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.lecoinchicdumoment.com/" />
      </Helmet>
      <div className="flex flex-col w-full items-center pb-[50px]">
        <div className="flex flex-col w-full space-y-7 max-w-[2520px] px-5 xl:px-5 2xl:px-20">
          <CategoriesPresentation
            categories={premiumHomeCategories}
            isLoading={isLoadingHomePremiumCategories}
          />
          <div className="flex pt-[20px]">
            <span className="flex text-[25px] font-semibold">
              Nos catégories
            </span>
          </div>
          <div className="flex flex-col space-y-10">
            {categoriesHome.length !== 0 &&
              _.sortBy(categoriesHome, (o) => {
                return o.type.categorytype_index;
              }).map((item) => {
                return (
                  <CategoryTypeRow
                    key={
                      "HomeAllCategories_" +
                      item.type.categorytype_id.toString()
                    }
                    item={item}
                  />
                );
              })}
            {categoriesHome.length === 0
              ? isloadingHomeCategories &&
                Array.from({ length: 10 }).map((_, index) => {
                  return (
                    <CategoryTypeRowLoading
                      key={"HomeAllCategoriesLoading_" + index.toString()}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeAllCategories;
