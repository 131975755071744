import React from "react";
import MenuCategories from "./categories";
// import FiltersBar from "./filtres";
import { useGetScrollPos } from "../../../functions/useGetScrollPos";

const Laptop = () => {
  const { atZero } = useGetScrollPos();
  return (
    <React.Fragment>
      <div className="hidden md:flex bg-white fixed top-[34px] xl:top-[44px] my-4 left-0 w-full z-[222] justify-center">
        <div
          className={`flex flex-row w-full max-w-[2520px] py-[11px] px-4 xl:px-5 2xl:px-20 space-x-4 items-center ${
            !atZero ? "shadow-md" : ""
          }`}
        >
          <MenuCategories />
          {/* <FiltersBar /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Laptop;
