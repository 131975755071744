import CinetPay from "../configs/cinetpay";
import { useAuthStore } from "../configs/stores/auth";
import { useCoinStore } from "../configs/stores/coin";
import { ToastErrorNotifier, ToastSuccessNotifier } from "../constants";
import { ReservationModel } from "../models/models";

type Props = {
  onClose?: () => void;
};

type PropStartPayment = {
  reservation: ReservationModel;
  cardPaymentForm: {
    ville: string;
    adresse: string;
    code_postal: string;
  };
};

const onlineApisUrl = "https://api.lecoinchicdumoment.com";
const localApisUrl = window.location.hostname.includes("local")
  ? "http://localhost:4010"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":4010"
  : onlineApisUrl;

export const CinetPay_Notify_Url = localApisUrl + "/v1/client";

export function usePaymentHook({ onClose = () => {} }: Props = {}) {
  const user = useAuthStore((e) => e.user);
  const refetchAllReservation = useAuthStore((e) => e.refetchAllReservation);
  const setCardPaymentForm = useCoinStore((e) => e.setCardPaymentForm);
  const updateToPaiedReservation = useCoinStore(
    (e) => e.updateToPaiedReservation
  );
  function startPayment({
    reservation,
    cardPaymentForm = {
      ville: "",
      adresse: "",
      code_postal: "",
    },
  }: PropStartPayment) {
    const { adresse = "", ville = "", code_postal = "" } = cardPaymentForm;

    const checkoutForm = {
      transaction_id: Math.floor(Math.random() * 100000000).toString(), // YOUR TRANSACTION ID
      amount: reservation.reservation_price.toString(),
      currency: "XOF",
      channels: "ALL",
      description:
        "Réservation de " +
        reservation.reservation_places
          .map((e) => `(x${e.length}) ${e.place.place_name}, `)
          .reduce<string>((prev, curr, i, arr) => prev + curr, "") +
        (reservation.reservation_services.length !== 0
          ? " ; Services réservés: " +
            reservation.reservation_services.reduce<string>(
              (prev, curr, i, arr) => prev + ", " + curr.service_name,
              ""
            )
          : ""),
      notify_url:
        CinetPay_Notify_Url + "/coin/category/reservation/payment/notify",
      return_url: `https://www.lecoinchicdumoment.com/coin/${reservation.reservation_category.category_company.company_slug}?category=${reservation.reservation_category.category_type.categorytype_slug}`,
      // Fournir ces variables pour le paiements par carte bancaire
      customer_id: user?.user_id, // Le nom du client
      customer_name: user?.user_name, // Le nom du client
      customer_surname: user?.user_surname, // Le prenom du client
      customer_email: user?.user_email, // L'email du client
      customer_phone_number: user?.user_phone.toString(), // L'email du client
      customer_address: adresse.length > 1 ? adresse : "non definie", // Addresse du client
      customer_city: ville.length > 1 ? ville : "non definie", // La ville du client
      customer_country: user?.user_locality.locality_code, // le code ISO du pays
      customer_state: user?.user_locality.locality_code, // le code ISO l'état
      customer_zip_code: code_postal.length === 5 ? code_postal : "BP000", // code postal
      metadata: reservation.reservation_id,
      invoice_data: {
        resevation_id: reservation.reservation_id,
        utilisateur: user?.user_name + " " + user?.user_surname,
        etablissement:
          reservation.reservation_category.category_type.categorytype_name +
          " de " +
          reservation.reservation_category.category_company.company_name,
      },
    };
    CinetPay.getCheckout(checkoutForm);
    CinetPay.waitResponse(function (data: any) {
      ["ville", "code_postal", "adresse"].forEach((e) => {
        setCardPaymentForm(e, "");
      });
      if (data.status === "REFUSED") {
        ToastErrorNotifier({
          message: "Votre paiement a échoué",
        });
      } else if (data.status === "ACCEPTED") {
        updateToPaiedReservation({
          id: reservation.reservation_id,
          response: data,
        }).then(() => {
          refetchAllReservation();
        });
        ToastSuccessNotifier({
          message: "Votre paiement a été effectué avec succès",
          duration: 8000,
        });
        setTimeout(() => {
          // window.location.reload();
        }, 8000);
      }
    });
    CinetPay.onError(function (data: any) {
      ToastErrorNotifier({
        message: "Une erreur s'est produite !",
      });
      ToastErrorNotifier({
        message: "Veuillez recharger la page !",
      });
    });
    CinetPay.onClose(function () {
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    });
  }
  return { startPayment };
}
