import React from "react";
import TextInputSign from "../../../../../components/inputs/textInput";
// import PhoneCountryInputSign from "../../../../../components/inputs/phoneCountryInput";
import {
  ToastSuccessNotifier,
  // isEmail,
  // isNumber,
} from "../../../../../constants";
import { useAuthStore } from "../../../../../configs/stores/auth";

const Form = () => {
  const user= useAuthStore((e)=>e.user);
  const updateUser = useAuthStore((e)=>e.updateUser);
  const [formData, setFormData] = React.useState({
    user_name: user?.user_name??"",
    user_surname: user?.user_surname??"",
    // user_email: user?.user_email??"",
    // user_phone: user?.user_phone??"",
    // user_locality: user?.user_locality?.locality_id.toString()??"",
    rememberMe: false,
  });
  const [errorState, setErrorState] = React.useState({
    user_name: { state: false, msg: "" },
    user_surname: { state: false, msg: "" },
    // user_email: { state: false, msg: "" },
    // user_phone: { state: false, msg: "" },
    // user_locality: { state: false, msg: "" },
  });
  const [isLoading, setisLoading] = React.useState(false);
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (!slug.includes("remember")) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.user_name.length === 0) {
      errorOccured = true;
      let msg = "Entrer un nom correcte";
      setErrorState((e) => ({ ...e, user_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_name: { state: false, msg: "" } }));
    }
    if (formData.user_surname.length === 0) {
      errorOccured = true;
      let msg = "Entrer un prénom correcte";
      setErrorState((e) => ({ ...e, user_surname: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_surname: { state: false, msg: "" } }));
    }
    // if (
    //   formData.user_phone.length === 0 ||
    //   !isNumber(formData.user_phone.toString())
    // ) {
    //   errorOccured = true;
    //   let msg = "Entrer un numero correcte";
    //   setErrorState((e) => ({ ...e, user_phone: { state: true, msg } }));
    // } else {
    //   setErrorState((e) => ({ ...e, user_phone: { state: false, msg: "" } }));
    // }
    // if (formData.user_locality.length === 0) {
    //   errorOccured = true;
    //   let msg = "Sélectionner un pays";
    //   setErrorState((e) => ({
    //     ...e,
    //     user_locality: { state: true, msg },
    //   }));
    // } else {
    //   setErrorState((e) => ({
    //     ...e,
    //     user_locality: { state: false, msg: "" },
    //   }));
    // }
    // if (
    //   formData.user_email.length === 0 ||
    //   !isEmail(formData.user_email.toString())
    // ) {
    //   errorOccured = true;
    //   let msg = "Entrer une adresse email correcte";
    //   setErrorState((e) => ({ ...e, user_email: { state: true, msg } }));
    // } else {
    //   setErrorState((e) => ({ ...e, user_email: { state: false, msg: "" } }));
    // }
    return errorOccured;
  }
  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setisLoading(true);
    updateUser(formData).then((reponse) => {
      if (reponse.success) {
        ToastSuccessNotifier({
          message: "Mise a jour reussie",
        });
        setisLoading(false);
      } else {
        ToastSuccessNotifier({
          message: "Echec de l'enregistrement",
        });
      }
    });
  }
  return (
    <div className="flex flex-col space-y-5 w-full lg:w-[450px] pt-3 lg:pt-5">
      <span className="flex font-medium text-[16px] self-center">Utilisateur</span>
      <TextInputSign
        type={"text"}
        defaultValue={user?.user_name.toString()}
        errorState={errorState.user_name}
        placeholder="Nom"
        onTextChange={onValueChange("user_name")}
      />
      <TextInputSign
        type={"text"}
        defaultValue={user?.user_surname.toString()}
        errorState={errorState.user_surname}
        placeholder="Prénom"
        onTextChange={onValueChange("user_surname")}
      />
      {/* <PhoneCountryInputSign
        errorState={{
          state: errorState.user_phone.state || errorState.user_locality.state,
          msg: errorState.user_phone.msg + " " + errorState.user_locality.msg,
        }}
        placeholder="Numero de téléphone"
        defaultPhone={user?.user_phone.toString()}
        defaultCountry={user?.user_locality}
        onSelectCountry={onValueChange("user_locality")}
        onTextChange={onValueChange("user_phone")}
      />
      <TextInputSign
        type={"email"}
        defaultValue={user?.user_email.toString()}
        errorState={errorState.user_email}
        placeholder="Adresse email"
        onTextChange={onValueChange("user_email")}
      /> */}
      <button
        disabled={isLoading}
        onClick={submitForm}
        className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-white text-[18px] bg-primary hover:bg-hover rounded-[20px] shadow-sm border"
      >
        <span className="-mt-[1px]">
          {!isLoading ? "Enregistrer" : "Enregistrement de"} la modification
          {!isLoading ? "" : "..."}
        </span>
      </button>
    </div>
  );
};

export default Form;
