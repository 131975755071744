import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCoinStore } from "../../../../../../configs/stores/coin";
import { CompanyModel } from "../../../../../../models/models";

const CoinLogo = () => {
  const coin = useCoinStore((e) => e.coin);
  const company = (coin?.company ?? {}) as CompanyModel;
  return (
    <div className="flex h-[40px] w-[40px] lg:h-[52px] lg:w-[52px] rounded-full justify-center items-center">
      {company?.company_logo ? (
        <div className="flex h-full w-full rounded-full border shadow-md border-[#ccc] bg-white overflow-hidden">
          <LazyLoadImage
            className="flex w-full h-full rounded-full"
            src={company.company_logo.image_link.toString()}
            alt={company.company_slug.toString()}
            placeholderSrc={company.company_logo.image_miniature.toString()}
            height={"100%"}
            width={"100%"}
            effect="blur"
          />
        </div>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={"white"}
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
};

export default CoinLogo;
