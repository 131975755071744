import React from "react";
import ReactMapGL, {
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
  Layer,
  Source,
} from "react-map-gl";
import {
  CategoryData,
  useCategoriesDatas,
} from "../../../hooks/useCategoriesDatas";
import { primaryColor } from "../../../constants";
import * as turf from "@turf/turf";
import usePersistState from "../../../hooks/usePersistState";

const MapTile = () => {
  const {
    data: { categories },
  } = useCategoriesDatas({
    category: "all",
    max: 30,
    maxPerRow: 30,
  });
  const [center, setCenter] = usePersistState("u_geo", {
    lon: 0,
    lat: 0,
  });
  const radius = 5;
  const circle = turf.circle([center.lon, center.lat], radius, {
    properties: { foo: "bar" },
    units: "kilometers",
    steps: 100,
  });
  const line = turf.lineString(circle.geometry.coordinates[0]);

  function getCurrentLocation() {
    var options = {
      enableHighAccuracy: true,
      // timeout: 5000,
      maximumAge: 0,
    };

    function success(pos: any) {
      var crd = pos.coords;
      setCenter({
        lat: parseFloat(crd.latitude),
        lon: parseFloat(crd.longitude),
      });
    }

    function error(err: any) {
      console.warn(`ERREUR (${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  React.useEffect(() => {
    getCurrentLocation();
    const timer = setTimeout(() => {
      getCurrentLocation();
    }, 3000);
    return clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: window.innerHeight,
      }}
      className="flex w-full h-auto"
    >
      <ReactMapGL
        minZoom={13}
        initialViewState={{
          longitude: center.lon,
          latitude: center.lat,
          zoom: 13,
        }}
        style={{ width: "100%", height: "100%", borderRadius: 8 }}
        mapboxAccessToken={process.env.REACT_APP_MapboxAccessToken}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        attributionControl={false}
      >
        {categories
          .reduce(
            (prev, cur) => prev.concat(cur.categories),
            Array<CategoryData>()
          )
          .filter(
            (e) =>
              parseFloat(e.company.company_geolocation.lng.toString()) !== 0
          )
          .map((category) => {
            return (
              <Marker
                key={category.category.category_id.toString()}
                color={primaryColor}
                longitude={parseFloat(
                  category.company.company_geolocation.lng.toString()
                )}
                latitude={parseFloat(
                  category.company.company_geolocation.lat.toString()
                )}
                anchor="top"
                // onClick={() => setShowPopup(!showPopup)}
              />
            );
          })}
        <div style={{ position: "absolute", bottom: 200, left: 700 }}>
          <ScaleControl maxWidth={220} unit={"metric"} />
        </div>
        <Source id="my-data" type="geojson" data={circle}>
          <Layer
            id="point-90-hi"
            type="fill"
            paint={{
              "fill-color": primaryColor,
              "fill-opacity": 0.07,
              "fill-outline-color": "yellow",
            }}
          />
        </Source>
        <Source id="my-ata" type="geojson" data={line}>
          <Layer
            id="point-9-hi"
            type="line"
            paint={{
              "line-color": primaryColor,
              "line-width": 2,
            }}
          />
        </Source>
        <NavigationControl
          position="bottom-right"
          showCompass={true}
          showZoom={true}
        />
        <Popup
          longitude={parseFloat(center.lon.toString())}
          latitude={parseFloat(center.lat.toString())}
          anchor="bottom"
          closeButton={false}
          closeOnClick={false}
        >Votre position</Popup>
        <Marker
          color={"black"}
          longitude={parseFloat(center.lon.toString())}
          latitude={parseFloat(center.lat.toString())}
          anchor="top"
        />
      </ReactMapGL>
    </div>
  );
};

export default MapTile;
