import React from "react";
import CoinMenu from "./menu";
import {
  MenuCardModel,
  MenuModel,
} from "../../../../../../../../models/models";
import { useDimensions } from "../../../../../../../../hooks/useDimensions";
import { useKeenSlider } from "keen-slider/react";

type Props = {
  menucard: { menucard: MenuCardModel; menus: Array<MenuModel> };
};

const CoinMenuCard = ({ menucard: { menucard, menus } }: Props) => {
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const [isOpen, setisOpen] = React.useState(true);
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  return (
    <div className="flex flex-col w-full">
      <div
        onClick={() => setisOpen(!isOpen)}
        className="flex cursor-pointer select-none items-start flex-row bottom-0 w-full space-x-2"
      >
        {menus.length !== 0 ? (
          isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              className="w-10 h-10 ml-1 mt-3 stroke-primary"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              className="w-10 h-10 ml-1 mt-3 stroke-primary"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )
        ) : null}
        <div className="flex flex-row space-x-4 items-start">
          <div className="flex min-h-[60px] h-[60px] min-w-[60px] w-[60px] border rounded-full overflow-hidden">
            <img
              className="flex h-full w-full"
              src={menucard.menucard_image.image_link.toString()}
              alt={menucard.menucard_id.toString()}
            />
          </div>
          <div className="flex flex-col space-y-1 pt-2">
            <span className="flex text-black text-[19px] font-semibold leading-none">
              {menucard.menucard_name}
            </span>
            <div className="flex flex-col space-y-1 leading-none">
              <div className="flex flex-1">
                <span className="text-black leading-tight text-[14.5px]">
                  {menucard.menucard_description}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: isOpen && menus.length !== 0 ? "flex" : "none" }}
        className="flex flex-row space-x-5 pt-4 pb-3"
      >
        <div className="flex w-[2px] ml-[15px] h-auto bg-primary/30" />
        <div className="flex flex-row w-full py-2">
          <div ref={refDim} className="flex flex-1">
            <div
              ref={ref}
              className="keen-slider flex"
              style={{ maxWidth: dimensions?.width ?? 10 }}
            >
              {menus.map((e) => {
                return <CoinMenu menu={e} key={e.menu_id.toString()} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinMenuCard;
