import React from "react";
import { useCoinStore } from "../../../../../../../configs/stores/coin";

const CoinConvenience = () => {
  const coin = useCoinStore((e) => e.coin);
  const conveniences = coin?.category?.category_convenience ?? [];
  return (
    <div
      style={{
        display: conveniences.length !== 0 ? "flex" : "none",
      }}
      className="flex flex-col px-5 space-y-4 pt-4 bg-white"
    >
      <span className="flex text-[20px] leading-tight font-medium">
        Profitez des commodités offertes par notre établissement :
      </span>
      <div className="flex flex-col space-y-3">
        {conveniences.map((convenience) => {
          return (
            <div
              key={convenience.convenience_id.toString()}
              className="flex flex-row space-x-3 items-center"
            >
              <img
                className="flex w-8 h-8 object-contain"
                src={convenience.convenience_icon}
                alt={convenience.convenience_slug}
              />
              <span className="flex text-[18px]">
                {convenience.convenience_name}
              </span>
            </div>
          );
        })}
      </div>
      <div className="flex w-full mx-3 my-2 mb-0 self-center h-[1px] bg-black/10" />
    </div>
  );
};

export default CoinConvenience;
