import React from "react";

const PaymentModes = () => {
  // const { cardPaymentForm, updatePaymentForm } = useCoinHook();
  const checkedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      className="w-6 h-6 stroke-teal-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
      />
    </svg>
  );
  return (
    <div className="flex flex-col space-y-2 pt-5 pb-10">
      <div className="flex">
        <span className="flex text-[13px] font-medium">Modes de paiement</span>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-2 items-start">
          {checkedIcon}
          <div className="flex flex-col">
            <span className="flex text-[14px]">Paiement Mobile</span>
            <span className="flex text-[14px] font-semibold">
              Orange Money - MTN Money - Moov Money
            </span>
          </div>
        </div>
        <div className="flex flex-row space-x-2 items-start">
          {checkedIcon}
          <div className="flex flex-col">
            <span className="flex text-[14px]">Wallets</span>
            <span className="flex text-[14px] font-semibold">Wave</span>
          </div>
        </div>
        <div className="flex flex-row space-x-2 items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={"black"}
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
            />
          </svg>
          <div className="flex flex-col flex-1 space-y-[10px]">
            <span className="flex text-[14px] pt-[1.5px] max-w-[98%]">
              Pour activer le paiement par carte, remplissez les champs
              ci-dessous:
            </span>
            <input
              className="flex min-w-0 w-[97%] text-[15px] font-medium border rounded-md px-[8px] py-2 outline-primary outline-1"
              placeholder=" Ville"
              type="text"
              alt="Ville"
              // value={cardPaymentForm.ville}
              onChange={(value) => {
                // updatePaymentForm("ville")(value.target.value);
              }}
            />
            <input
              className="flex min-w-0 w-[97%] text-[15px] font-medium border rounded-md px-[10px] py-2 outline-primary outline-1"
              placeholder=" Adresse"
              type="text"
              alt="Adresse"
              // value={cardPaymentForm.adresse}
              onChange={(value) => {
                // updatePaymentForm("adresse")(value.target.value);
              }}
            />
            <input
              className="flex min-w-0 w-[97%] text-[15px] font-medium border rounded-md px-[10px] py-2 outline-primary outline-1"
              placeholder=" Code postal"
              type="text"
              alt="Code postal"
              // value={cardPaymentForm.code_postal}
              onChange={(value) => {
                // updatePaymentForm("code_postal")(value.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModes;
