import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  CategoryModel,
  ReservationModel,
  UserModel,
} from "../../models/models";
import { ToastErrorNotifier, ToastSuccessNotifier } from "../../constants";
import {
  getAllReservation,
  getUserData,
  login,
  logout,
  signup,
  updateFavoris,
  updateProfile,
  updateUser,
} from "./functions/auth";

const LOCAL_DATASTORE_NAME = "coinchicclient-auth";

type ContextProps = {
  tokens: string | null;
  isAuthenticated: boolean;
  isLoadingLogin: boolean;
  isLoadingSignUp: boolean;
  isLoadingLogout: boolean;
  isUpdatingFavoris: boolean;
  user: UserModel | null;
  isRefreshingUser: boolean;
  reservations: Array<ReservationModel>;
  isLoadingReservation: boolean;
  isRefreshingReservation: boolean;
  login: (credentials: { login: string; password: string }) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  signup: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  logout: (cb?: () => void) => Promise<void>;
  refetchUser: () => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  refreshToken: (tokens: string) => Promise<any>;
  updateFavoris: (category: CategoryModel, action: string) => Promise<any>;
  updateProfile: (image_data: any) => Promise<any>;
  updateUser: (update: any) => Promise<any>;
  getAllReservation: () => Promise<any>;
  refetchAllReservation: () => Promise<any>;
};

export const useAuthStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      tokens: null,
      isAuthenticated: false,
      isLoadingLogin: false,
      isLoadingSignUp: false,
      isLoadingLogout: false,
      user: null,
      isRefreshingUser: false,
      isUpdatingFavoris: false,
      reservations: [],
      isLoadingReservation: true,
      isRefreshingReservation: false,
      refreshToken: async (tokens: string) => {
        set(
          produce((state: ContextProps) => {
            state.tokens = tokens;
          })
        );
      },
      login: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = true;
          })
        );
        const res = await login(credentials);
        const { success = false, message = "", data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.user = data;
              state.tokens = res.tokens;
            }
          })
        );
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = false;
            if (success) {
              ToastSuccessNotifier({ message: "Connexion reussie !" });
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
              ToastErrorNotifier({
                message: message.toString(),
                position: "top-center",
              });
            }
          })
        );
        const state = get();
        state.getAllReservation()
        return res;
      },
      signup: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = true;
          })
        );
        const res = await signup(credentials);
        const { success = false, message = "", data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.user = data;
              state.tokens = res.tokens;
            }
          })
        );
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = false;
            if (success) {
              ToastSuccessNotifier({ message: "Inscription reussie !" });
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
              ToastErrorNotifier({
                message: message.toString(),
                position: "top-center",
              });
            }
          })
        );
        const state = get();
        state.getAllReservation()
        return res;
      },
      logout: async (cb) => {
        const state = get();
        if (state.isLoadingLogout) {
          return;
        }
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogout = true;
          })
        );
        await logout();
        set(
          produce((state: ContextProps) => {
            state.tokens = null;
            state.reservations = [];
            state.isLoadingReservation = true;
            state.isLoadingLogout = false;
            state.isAuthenticated = false;
            state.user = null;
          })
        );
        !!cb && cb();
      },
      refetchUser: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = true;
          })
        );
        const res = await getUserData();
        const { success = false, message = "", data = null } = res;
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = false;
            if (success) {
              state.user = data;
              if (!state.isAuthenticated) {
                state.isAuthenticated = true;
              }
            } else {
              state.isAuthenticated = false;
              ToastErrorNotifier({
                message: message.toString(),
                position: "top-center",
              });
              state.user = null;
            }
          })
        );
        return res;
      },
      updateFavoris: async (category, action = "remove") => {
        set(
          produce((state: ContextProps) => {
            if (action === "add") {
              state.user = {
                ...state.user,
                user_favorites: state.user?.user_favorites
                  .filter(
                    (e) =>
                      e.category_id?.toString() !==
                      category.category_id.toString()
                  )
                  .concat(category) ?? [category],
              } as UserModel;
            } else if (action === "remove") {
              state.user = {
                ...state.user,
                user_favorites:
                  state.user?.user_favorites.filter(
                    (e) =>
                      e.category_id?.toString() !==
                      category.category_id.toString()
                  ) ?? [],
              } as UserModel;
            }
          })
        );
        const res = await updateFavoris(
          category.category_id.toString(),
          action
        );
        const data = (res?.data ?? []) as Array<CategoryModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.user = {
                ...state.user,
                user_favorites: data,
              } as UserModel;
            }
          })
        );
      },
      updateProfile: async (image_data) => {
        const res = await updateProfile(image_data);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.user = res.data;
            })
          );
        } else {
          ToastErrorNotifier({
            message: "Echec de la modification de votre profile",
          });
        }
      },
      updateUser: async (update) => {
        const res = await updateUser(update);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.user = res.data;
            })
          );
        }
        return res;
      },
      getAllReservation: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingReservation = true;
          })
        );
        const res = await getAllReservation();
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.reservations = res.data;
              state.isLoadingReservation = false;
            })
          );
        }
        return res;
      },
      refetchAllReservation: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshingReservation = true;
          })
        );
        const res = await getAllReservation();
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.reservations = res.data;
              state.isRefreshingReservation = false;
            })
          );
        }
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ath")),
    }
  )
);
