import React from "react";
import { ImageModel } from "../../../models/models";
import PresentationChildCarousel from "./carousel";
import LogoIcon from "../../../assets/logoicon.png";
import FavoriteButton from "./favoriteButton";
import { usePremiumCategoryItemDimensions } from "../../../functions/generateCategoryWidth";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CategoryDataInterface } from "../../../configs/stores/categories";

type Props = {
  item: CategoryDataInterface;
};

const PresentationChild = ({ item }: Props) => {
  const { category, images } = item;
  const { category_type: categoryType, category_company: company } = category;
  const PremiumCategoryItemDimensions = usePremiumCategoryItemDimensions();
  return (
    <div className="flex flex-col relative">
      <a
        href={
          "/coin/" +
          company.company_slug +
          "?category=" +
          categoryType.categorytype_slug
        }
        className="flex flex-col w-full rounded-[16px] overflow-hidden"
        style={{
          height: PremiumCategoryItemDimensions,
          width: PremiumCategoryItemDimensions,
        }}
      >
        <div
          style={{
            display: "flex",
          }}
          className="flex h-full w-full rounded-[16px] overflow-hidden"
        >
          <PresentationChildCarouselContainer images={images} />
          <div className="flex z-[2] w-full top-0 pb-4 pt-4 flex-row items-center justify-between space-x-4 px-4 absolute bg-gradient-to-t rounded-t-[18px] from-transparent via-black/30 to-black/40">
            <span className="flex text-white text-[18px] font-semibold line-clamp-1 leading-tight">
              {categoryType?.categorytype_name}
            </span>
            {/* <FavoriteButton category={category} /> */}
          </div>
          <div className="flex w-full z-[2] bottom-0 pb-4 pt-4 flex-row items-center space-x-4 px-4 rounded-b-[16px] absolute bg-gradient-to-b from-transparent via-black/50 to-black/70">
            <div className="flex max-h-[35px] min-h-[35px] max-w-[35px] min-w-[35px] shadow-md shadow-black/30 ring-white ring-2 rounded-full bg-transparent overflow-hidden">
              <LazyLoadImage
                src={company.company_logo.image_link.toString()}
                className="flex max-h-[35px] min-h-[35px] max-w-[35px] min-w-[35px]"
                alt={company.company_logo.image_id.toString()}
                placeholderSrc={company.company_logo.image_miniature.toString()}
                height={35}
                width={35}
                effect="blur"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <span className="flex text-white text-[16px] font-semibold line-clamp-1 leading-none">
                {company.company_name}
              </span>
              <div className="flex flex-row w-full items-center space-x-[4px] -mt-px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                  className="flex w-[13px] h-[13px] -mt-[2px]"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-white text-start text-[13px] font-medium line-clamp-1">
                  {company?.company_country?.locality_name ?? "localisation"}{" "}
                  {" - "}{" "}
                  {company?.company_locality_precision ?? "localisation"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </a>
      <div className="flex absolute right-[15px] z-4 top-[13px]">
        <FavoriteButton category={item.category} />
      </div>
    </div>
  );
};

type PropsImgs = {
  images: Array<ImageModel>;
};

function PresentationChildCarouselContainer({ images }: PropsImgs) {
  return (
    <div className={`flex flex-1 h-full w-full aspect-square flex-col`}>
      {images.length !== 0 ? (
        <PresentationChildCarousel images={images} />
      ) : (
        <div className="flex flex-1 h-full w-full aspect-square flex-col">
          <RepeatLogo />
        </div>
      )}
    </div>
  );
}

const ImageLecoin = () => {
  const PremiumCategoryItemDimensions = usePremiumCategoryItemDimensions();
  const dims = (PremiumCategoryItemDimensions - 90) / 3;
  return (
    <div
      style={{ height: dims, width: dims }}
      className="flex flex-col col-span-1 "
    >
      <img
        src={LogoIcon}
        className="flex h-full w-full flex-1 opacity-5 object-contain select-none pointer-events-none"
        alt={"LogoIcon"}
      />
    </div>
  );
};

function RepeatLogo() {
  return (
    <div className="grid h-full w-full grid-cols-3 grid-rows-3 gap-2 p-5 rounded-[16px] aspect-square">
      {Array.from({ length: 9 }).map((e, index) => {
        return <ImageLecoin key={index.toString()} />;
      })}
    </div>
  );
}

export default PresentationChild;
