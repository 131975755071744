import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useCurrentShowItemsLengthPerRow } from "../../../../../../../../functions/useLayoutDimension";

type Props = {
  [x: string]: any;
};

const FormDayInput = ({ ...props }: Props) => {
  const { control, watch } = useFormContext();
  const flex = useCurrentShowItemsLengthPerRow();
  const isDepart = props.slug === "departure_day";
  const arrivalValue = watch("arrival_day", moment());
  let minDate = isDepart ? arrivalValue : moment();
  return (
    <Controller
      control={control}
      render={({
        field: { onChange, onBlur, value },
        formState: {
          errors: { [props.slug]: error = null },
        },
      }) => (
        <React.Fragment>
          <div className="flex flex-col w-full space-y-2">
            <MobileDatePicker
              minDate={minDate}
              onChange={onChange}
              value={value}
              orientation={flex > 2 ? "landscape" : "portrait"}
              {...props}
            />
            {!!error && (
              <span className="flex text-red-500 text-[13px] ml-3 leading-none">
                {error.message?.toString()}
              </span>
            )}
          </div>
        </React.Fragment>
      )}
      name={props.slug}
      {...props}
    />
  );
};

export default FormDayInput;
