import React from "react";
import Profile from "./profile";
import Form from "./form";

const UserProfile = () => {
  return (
    <React.Fragment>
      <div
        className={`flex flex-row bg-green-500/0 pb-8 lg:py-4 w-full border-b justify-center min-h-[calc(100vh-170px)]`}
      >
        <div className="flex flex-col bg-blue-500/0 h-full w-full px-4 xl:px-5 2xl:px-20 max-w-[2520px]">
          <div className="flex bg-white shadow-md px-5 lg:px-8 py-5 lg:py-8 rounded-[16px] flex-col w-full lg:w-fit">
            <span className="flex font-medium text-[20px] pb-[15px] border-b">
              Profil de l'utilisateur
            </span>
            <div className="flex flex-col space-y-3 space-x-0 items-center lg:items-start lg:flex-row lg:space-x-3 lg:space-y-0">
              <Profile />
              <Form />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
