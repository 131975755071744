import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  CategoryModel,
  CommentModel,
  CompanyModel,
  ImageModel,
  MenuCardModel,
  MenuModel,
  ServiceModel,
} from "../../../../models/models";
import SkeletonDisposition from "./skeleton";
import CoinHeader from "./header";
import CoinBody from "./body";
import EtablissmentConnexes from "./connexeEtablissment";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useCoinStore } from "../../../../configs/stores/coin";

type CoinContextContextProps = {
  isLoading: Boolean;
  company: CompanyModel;
  categories: Array<CategoryModel>;
  category: CategoryModel;
  images: Array<ImageModel>;
  evaluation: String;
  userevaluation: String;
  menucards: Array<{ menucard: MenuCardModel; menus: Array<MenuModel> }>;
  services: Array<ServiceModel>;
  comments: Array<CommentModel>;
  startPayment: (data: any) => void;
  clearForm: () => void;
  addedServices: Array<ServiceModel>;
  addedMenus: Array<{ menu: MenuModel; length: number }>;
  addMenu: (menu: MenuModel) => void;
  removeMenu: (menu: MenuModel) => void;
  addService: (menu: ServiceModel) => void;
  removeService: (menu: ServiceModel) => void;
  coinFormMethods: UseFormReturn<FieldValues, any, undefined>;
  cardPaymentForm: {
    ville: string;
    adresse: string;
    code_postal: string;
  };
  updatePaymentForm: (name: string) => (value: any) => void;
  updateUserEvalution: (evaluation: string, userEvaluation: string) => void;
};

const CoinContext = React.createContext<CoinContextContextProps>(
  {} as CoinContextContextProps
);

export function useCoinHook() {
  return React.useContext(CoinContext);
}

const Coin = () => {
  const user = useAuthStore((e) => e.user);
  const coin = useCoinStore((e) => e.coin);
  const isLoading = useCoinStore((e) => e.isLoading);
  const getCoinData = useCoinStore((e) => e.getCoinData);
  const { companySlug } = useParams();
  const [searchParams] = useSearchParams();
  const category = coin?.category;

  useOneEffect(() => {
    getCoinData(
      companySlug ?? "",
      searchParams.get("category") ?? undefined,
      user?.user_id?.toString() ?? undefined
    );
  }, []);

  return (
    <React.Fragment>
      {!isLoading ? (
        <div className="flex w-full flex-col xl:space-y-8 xl:pt-3">
          {!!category && (
            <>
              <Helmet>
                <title>
                  {category
                    ? category.category_type.categorytype_name + " - "
                    : ""}
                  {category.category_company.company_name}
                </title>
                <meta
                  name="description"
                  content={category?.category_description.toString() ?? ""}
                />
                <meta name="robots" content="index, follow" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0"
                />
              </Helmet>
              <CoinHeader />
            </>
          )}
          {coin && (
            <>
              <CoinBody />
              <EtablissmentConnexes />
            </>
          )}
          {!coin && (
            <div className="flex flex-col w-full justify-center items-center h-[80vh]">
              <div className="flex flex-col rounded-xl shadow-md bg-white space-y-4 leading-tight items-center p-10 px-14">
                <h1 className="flex text-[90px] max-lg:text-[35px] font-bold leading-none">
                  404
                </h1>
                <h1 className="flex text-[40px] max-lg:text-[22px] font-bold ">
                  Page introuvable
                </h1>
                <a
                  className="flex px-5 py-3 rounded-lg bg-primary active:bg-active hover:bg-hover transition-all duration-150"
                  href="/"
                >
                  <span className="flex text-white text-[17px]">
                    Aller a l'accueil
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      ) : (
        <SkeletonDisposition />
      )}
    </React.Fragment>
  );
};

export default Coin;
