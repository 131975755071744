import React from "react";
import { ReservationModel } from "../../../../../../../models/models";

type Props = {
  reservation: ReservationModel;
};

const ReservationCost = ({ reservation }: Props) => {
  const [isOpen, setisOpen] = React.useState(false);
  const services = reservation.reservation_services;
  const category = reservation.reservation_category;
  const sommmeServices = services.reduce(
    (prev, curr) => prev + parseFloat(curr.service_price.toString()),
    0
  );
  return (
    <div className="flex flex-col space-y-2">
      <div
        onClick={() => setisOpen(!isOpen)}
        className="group flex flex-row items-center space-x-2 justify-between h-[30px]"
      >
        <div className="flex flex-row items-center space-x-[2px]">
          {/* <div className="flex duration-300 transition-all rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              className="w-[20px] h-[20px] stroke-primary"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              )}
            </svg>
          </div> */}
          <div className="flex flex-row space-x-1 text-primary">
            <span className="flex text-[15px] font-medium">
              Frais de réservation
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="flex text-[15px] font-semibold">
            {(sommmeServices *
              parseFloat(
                category.category_billing?.billing_pricing_percent?.toString() ??
                  "1"
              )) /
              100 +
              parseInt(
                category.category_billing?.billing_pricing_price?.toString() ??
                  "1"
              )}{" "}
            FCFA
          </span>
        </div>
      </div>
      {/* <div
        style={{ display: isOpen ? "flex" : "none" }}
        className="flex flex-col space-y-2 pl-[8px] text-[14px] border-l ml-[9px]"
      >
        <div className="flex flex-row justify-between">
          <span className="line-clamp-1">Frais de base</span>
          <div className="flex flex-row items-center space-x-1">
            <span className="flex">
              {category.category_billing.billing_pricing_price}{" "}
              {category.category_billing.billing_currency.currency_symbol}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <span className="line-clamp-1">Reservation services</span>
          <div className="flex flex-row items-center space-x-1">
            <span className="flex">
              {sommmeServices} {"FCFA"} x{" "}
              {category.category_billing.billing_pricing_percent}%
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ReservationCost;
