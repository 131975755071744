import React from "react";
import { useAuthStore } from "../../../../../configs/stores/auth";
import ReservationsWait from "./wait";
import ReservationsPaied from "./paied";

const UserReservations = () => {
  const refetchAllReservation = useAuthStore((e) => e.refetchAllReservation);
  const [isLoading, setIsRefetching] = React.useState(false);
  function refetch() {
    setIsRefetching(true);
    refetchAllReservation().then(() => {
      setIsRefetching(false);
    });
  }
  return (
    <React.Fragment>
      <div
        className={`flex flex-row bg-green-500/0 pb-8 lg:py-4 w-full border-b justify-center min-h-[calc(100vh-170px)]`}
      >
        <div className="flex flex-col bg-blue-500/0 h-full w-full px-4 xl:px-5 2xl:px-20 max-w-[2520px]">
          <div className="flex flex-col space-y-7">
            <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row justify-between items-center bg-white px-8 py-4 rounded-xl">
              <span className="flex text-[20px] font-medium">
                Vos réservations
              </span>
              <button
                onClick={refetch}
                className="flex text-[15px] font-medium text-primary hover:text-hover active:text-active"
              >
                {!isLoading ? "Actualiser" : "Actualisation..."}
              </button>
            </div>
            <div className="flex flex-col space-y-5">
              <ReservationsWait />
              <ReservationsPaied />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserReservations;
