import { createTheme } from "@mui/material/styles";
import { primaryColor } from "../../constants";
export const themeMui = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  shape: {
    borderRadius: 6,
  },
});
