import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRefLayoutDimension } from "../../../../../../../functions/useLayoutDimension";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
function SampleNextArrow(props: any) {
  return null;
}

function SamplePrevArrow(props: any) {
  return null;
}

function CustomAppendDot({ dots = Object() }) {
  return (
    <div className="dots-container w-full h-[18%] rounded-t-lg pt-5 absolute top-0 transition-all bg-gradient-to-t from-transparent to-black/40">
      <ul className="flex justify-center gap-[6px]">{dots}</ul>
    </div>
  );
}

function CustomPagingDot({
  index = 0,
  active = false,
  slide = (i: number) => {},
}) {
  return (
    <div
      className={`dots-item cursor-pointer transition-all h-[9px] w-[9px] rounded-full ${
        active
          ? "dots-item-active bg-white w-[17px] shadow-lg"
          : " bg-slate-400/70"
      }`}
      onClick={function (c) {
        slide(index);
      }}
    ></div>
  );
}

const NextArrow = ({ onClick = () => {} }) => {
  return (
    <div
      className={` transition-all opacity-50 group-hover:opacity-100 block absolute top-[45%] right-0 p-[13px] mr-4 rounded-full bg-slate-100 cursor-pointer`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="rgba(0,0,0,0.8)"
        className="w-[17px] h-[17px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  );
};

const PrevArrow = ({ onClick = () => {} }) => {
  return (
    <div
      className={` transition-all opacity-50 group-hover:opacity-100 block absolute top-[45%] left-0 p-[13px] ml-4 rounded-full bg-slate-100 cursor-pointer`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="rgba(0,0,0,0.8)"
        className="w-[17px] h-[17px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </div>
  );
};

function ImageSlide(params: any) {
  const { ...props } = params;
  return (
    <div
      {...props}
      style={{
        display: "flex",
        ...props.style,
        height: props.height,
        minHeight: props.height,
        overflow: "hidden",
        borderRadius: 18,
        background: "white",
      }}
      className="overflow-hidden"
    >
      <LazyLoadImage
        src={props.image.image_link.toString()}
        alt={props.image.image_id.toString()}
        placeholderSrc={props.image.image_miniature.toString()}
        style={{ objectFit: "cover" }}
        height={props.height}
        width={"100%"}
        effect="blur"
      />
    </div>
  );
}

function ImagesCarroussel() {
  const { element, width } = useRefLayoutDimension<HTMLDivElement>();
  const coin = useCoinStore((e) => e.coin);
  const images = coin?.images ?? [];
  // const { images } = useCoinHook();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isFullScreen, setisFullScreen] = React.useState(false);
  let sliderRef: Slider | null;
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
    appendDots: (dots: any) =>
      images.length > 1 ? <CustomAppendDot dots={dots} /> : <div />,
    customPaging: (i: number) => (
      <CustomPagingDot index={i} active={currentSlide === i} slide={goto} />
    ),
  };
  function next() {
    if (!!sliderRef) {
      sliderRef.slickNext();
    }
  }
  function prev() {
    if (!!sliderRef) {
      sliderRef.slickPrev();
    }
  }
  function goto(i: number) {
    if (!!sliderRef) {
      sliderRef.slickGoTo(i, false);
    }
  }
  const handleFullScreen = (id = "") => {
    const image = document.getElementById(id); // Remplacez "my-image" par l'ID de votre élément image
    if (image) {
      if (image.requestFullscreen && document.exitFullscreen) {
        if (!isFullScreen) {
          image.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    }
  };
  React.useEffect(() => {
    const menuItCat = document.getElementById("listImgl");
    const curDoc = document.getElementById("selectedImgl_" + currentSlide);
    if (curDoc && menuItCat) {
      if (currentSlide === 0) {
        menuItCat.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      } else {
        menuItCat.scrollTo({
          behavior: "smooth",
          top: curDoc.offsetTop - curDoc.offsetHeight,
        });
      }
    }
    document.addEventListener("fullscreenchange", (event) => {
      if (document.fullscreenElement) {
        setisFullScreen(true);
      } else {
        setisFullScreen(false);
      }
    });
  }, [currentSlide]);
  return (
    <div
      ref={element}
      style={{ height: (width * 7) / 18 }}
      className="flex w-full flex-row rounded-[18px] overflow-hidden space-x-[25px]"
    >
      <div
        style={{ height: (width * 7) / 18, width: width * 0.75 }}
        className={`group relative flex flex-col select-none rounded-[18px] bg-white overflow-hidden`}
        id="testestes"
      >
        <Slider
          ref={(c) => {
            sliderRef = c;
          }}
          {...settings}
        >
          {images
            .filter((e) => !!e?.image_ispreview)
            .map((img, index) => (
              <ImageSlide
                key={img.image_id.toString()}
                alt={img.image_id.toString()}
                src={img.image_link.toString()}
                image={img}
                isfullscreen={isFullScreen.toString()}
                height={isFullScreen ? "100%" : (width * 7) / 18}
              />
            ))}
          {images
            .filter((e) => !e?.image_ispreview)
            .map((img, index) => (
              <ImageSlide
                key={img.image_id.toString()}
                alt={img.image_id.toString()}
                src={img.image_link.toString()}
                image={img}
                isfullscreen={isFullScreen.toString()}
                height={isFullScreen ? "100%" : (width * 7) / 18}
              />
            ))}
        </Slider>
        {currentSlide !== 0 && images.length > 1 && (
          <PrevArrow onClick={prev} />
        )}
        {currentSlide + 1 !== images.length && <NextArrow onClick={next} />}
        <button
          onClick={function () {
            handleFullScreen("testestes");
          }}
          className="self-center cursor-pointer flex transition-all opacity-60 group-hover:opacity-100 flex-row px-5 py-2 items-center gap-4 mb-5 rounded-xl absolute bottom-0 bg-black/30"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex h-5 w-5"
          >
            <path
              d="M2 10C2 9.44772 1.55228 9 1 9V9C0.447715 9 0 9.44772 0 10V11.5C0 12.8807 1.11929 14 2.5 14H4C4.55228 14 5 13.5523 5 13V13C5 12.4477 4.55228 12 4 12H3.5C2.67157 12 2 11.3284 2 10.5V10ZM0 4C0 4.55228 0.447715 5 1 5V5C1.55228 5 2 4.55228 2 4V3.5C2 2.67157 2.67157 2 3.5 2H4C4.55228 2 5 1.55228 5 1V1C5 0.447715 4.55228 0 4 0H2.5C1.11929 0 0 1.11929 0 2.5V4ZM12 10.5C12 11.3284 11.3284 12 10.5 12H10C9.44772 12 9 12.4477 9 13V13C9 13.5523 9.44772 14 10 14H11.5C12.8807 14 14 12.8807 14 11.5V10C14 9.44772 13.5523 9 13 9V9C12.4477 9 12 9.44772 12 10V10.5ZM10 0C9.44772 0 9 0.447715 9 1V1C9 1.55228 9.44772 2 10 2H10.5C11.3284 2 12 2.67157 12 3.5V4C12 4.55228 12.4477 5 13 5V5C13.5523 5 14 4.55228 14 4V2.5C14 1.11929 12.8807 0 11.5 0H10Z"
              fill="#fff"
            />
          </svg>
          <span className="flex justify-center text-white text-[15px]">
            {isFullScreen ? "Sortir du plein ecran" : "Affichage plein ecran"}
          </span>
        </button>
      </div>
      <div
        id={"listImgl"}
        className="flex flex-1 flex-col w-full py-[6px] pl-1 pr-2 overflow-auto space-y-5 scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
      >
        {images
          .filter((e) => !!e?.image_ispreview)
          .map((img, index) => (
            <div
              id={"selectedImgl_" + index}
              key={index}
              onClick={function () {
                goto(index);
              }}
              style={{ minHeight: 200 }}
              className={`flex w-full flex-col bg-white transition-all object-cover cursor-pointer rounded-[12px] overflow-hidden ${
                currentSlide === index
                  ? "ring-4 ring-primary border-none"
                  : " scale-100 border"
              }`}
            >
              <LazyLoadImage
                src={img.image_link.toString()}
                alt={img.image_id.toString()}
                placeholderSrc={img.image_miniature.toString()}
                style={{ minHeight: 200, objectFit: "cover" }}
                width={"100%"}
                effect="blur"
              />
            </div>
          ))}
        {images
          .filter((e) => !e?.image_ispreview)
          .map((img, index) => (
            <div
              id={"selectedImgl_" + index}
              key={index}
              onClick={function () {
                goto(index);
              }}
              style={{ minHeight: 200 }}
              className={`flex w-full flex-col bg-white transition-all object-cover cursor-pointer rounded-[12px] overflow-hidden ${
                currentSlide === index
                  ? "ring-4 ring-primary border-none"
                  : " scale-100 border"
              }`}
            >
              <LazyLoadImage
                src={img.image_link.toString()}
                alt={img.image_id.toString()}
                placeholderSrc={img.image_miniature.toString()}
                style={{ minHeight: 200, objectFit: "cover" }}
                width={"100%"}
                effect="blur"
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ImagesCarroussel;
