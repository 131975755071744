import React from "react";
import { useRefLayoutDimension } from "../../../../../functions/useLayoutDimension";

const MobileSkeleton = () => {
  const { element, width } = useRefLayoutDimension<HTMLDivElement>();
  return (
    <div className="flex xl:hidden -mt-5 flex-col bg-white">
      {/** Header */}
      <div className="flex flex-row border-b px-5 pl-4 py-4 justify-between items-center">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex h-[40px] w-[40px] rounded-full justify-center items-center bg-grey animate-pulse"></div>
          <div className="flex flex-col space-y-[6px]">
            <span className="font-bold w-[150px] h-[12px] text-lg leading-tight bg-grey rounded-md animate-pulse" />
            <span className="leading-tight w-[100px] h-[12px] font-medium text-[14px] bg-grey rounded-md animate-pulse" />
          </div>
        </div>
      </div>
      {/** Category */}
      <div className="flex flex-col transition-all w-full">
        <div className="flex flex-row gap-2 items-center px-4 py-3 active:bg-slate-400/20 rounded-md">
          <div className="flex w-[130px] h-[20px] bg-grey rounded-md animate-pulse" />
        </div>
      </div>
      {/** Images */}
      <div className="flex flex-col">
        <div
          ref={element}
          className="flex flex-col xl:hidden w-full bg-white pb-6"
        >
          <div style={{ width }} className="flex flex-col bg-white">
            <div
              style={{ width, height: (width * 3) / 3.3, maxHeight: 500 }}
              className={`flex flex-col relative bg-grey rounded-md animate-pulse`}
            />
            <div className="flex w-full px-5 py-4 overflow-auto space-x-4">
              {Array.from({ length: 7 }).map((_, index) => (
                <div key={index} className="flex">
                  <div
                    className={`flex transition-all cursor-pointer bg-grey rounded-md animate-pulse`}
                    style={{
                      height: 75,
                      minWidth: 100,
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex w-full self-center h-[1px] bg-black/10" />
          </div>
          <div className="flex flex-col h-[400px] px-6 space-y-5 pt-6 bg-white">
            {Array.from({
              length: 7,
            }).map((e, index) => {
              return (
                <div
                key={index}
                  style={{
                    width: `calc(100% - ${(
                      (index * 2) *
                      10
                    ).toString()}px)`,
                  }}
                  className="flex h-[25px] rounded-xl bg-grey animate-pulse"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSkeleton;
