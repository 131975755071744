import React from "react";
import Logo from "../../assets/logoicon.png";
import TextInputSign from "../../components/inputs/textInput";
import PasswordInputSign from "../../components/inputs/passwordInput";
import PhoneCountryInputSign from "../../components/inputs/phoneCountryInput";
import { isEmail, isNumber, isPassword } from "../../constants";
import LoginModal from "../../components/modals/models/login";
import { navigateLink } from "../../components/buttons/navigationLinkManager";
import { useAuthStore } from "../../configs/stores/auth";
import { useLocation, useNavigate } from "react-router-dom";

const SignupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setisOpen] = React.useState(false);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const signup = useAuthStore((e) => e.signup);
  const isLoading = useAuthStore((e) => e.isLoadingSignUp);
  const [formData, setFormData] = React.useState({
    user_name: "",
    user_surname: "",
    user_email: "",
    user_password: "",
    user_country: "",
    user_phone: "",
    rememberMe: false,
  });
  const [errorState, setErrorState] = React.useState({
    user_name: { state: false, msg: "" },
    user_surname: { state: false, msg: "" },
    user_email: { state: false, msg: "" },
    user_password: { state: false, msg: "" },
    user_phone: { state: false, msg: "" },
    user_country: { state: false, msg: "" },
    generalMsg: "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (!slug.includes("remember")) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.user_name.length === 0) {
      errorOccured = true;
      let msg = "Entrer un nom correcte";
      setErrorState((e) => ({ ...e, user_name: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_name: { state: false, msg: "" } }));
    }
    if (formData.user_surname.length === 0) {
      errorOccured = true;
      let msg = "Entrer un prénom correcte";
      setErrorState((e) => ({ ...e, user_surname: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_surname: { state: false, msg: "" } }));
    }
    if (formData.user_phone.length === 0 || !isNumber(formData.user_phone)) {
      errorOccured = true;
      let msg = "Entrer un numero correcte";
      setErrorState((e) => ({ ...e, user_phone: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_phone: { state: false, msg: "" } }));
    }
    if (formData.user_country.length === 0) {
      errorOccured = true;
      let msg = "Sélectionner un pays";
      setErrorState((e) => ({
        ...e,
        user_country: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({ ...e, user_country: { state: false, msg: "" } }));
    }
    if (formData.user_email.length === 0 || !isEmail(formData.user_email)) {
      errorOccured = true;
      let msg = "Entrer une adresse email correcte";
      setErrorState((e) => ({ ...e, user_email: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_email: { state: false, msg: "" } }));
    }
    if (
      formData.user_password.length === 0 ||
      !isPassword(formData.user_password)
    ) {
      errorOccured = true;
      let msg =
        "Minimum 10 caracteres contenant majuscules ,minuscules et chiffres";
      setErrorState((e) => ({ ...e, user_password: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        user_password: { state: false, msg: "" },
      }));
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    let data = {
      user_name: formData.user_name,
      user_surname: formData.user_surname,
      user_email: formData.user_email,
      user_password: formData.user_password,
      user_locality: formData.user_country,
      user_phone: formData.user_phone,
    };
    signup(data);
  }
  function closeLogin() {
    setisOpen(false);
  }
  React.useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        if (!!location.state?.from) {
          navigate(location.state.from);
        } else {
          navigateLink("/");
        }
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col space-y-7 overflow-y-auto h-screen bg-activeGrey">
        <div className="flex flex-1 flex-col py-16 space-y-5 w-full items-center justify-center bg-activeGrey">
          <form
            onSubmit={submitForm}
            className="flex flex-col p-6 space-y-6 border bg-white rounded-xl w-[450px] max-w-[90vw] shadow-md"
          >
            <div className="flex flex-row w-full pl-2 space-x-5 items-center">
              <img className="flex h-[55px]" src={Logo} alt="logo" />
              <div className="flex flex-col leading-tight">
                <span className="flex text-center text-[22px] font-semibold max-sm:text-xl">
                  LeCoinChicDuMoment
                </span>
                <span className="flex text-center text-[18px] font-semibold max-sm:text-xl">
                  Inscrivez-vous
                </span>
              </div>
            </div>
            <TextInputSign
              type={"text"}
              errorState={errorState.user_name}
              placeholder="Nom"
              onTextChange={onValueChange("user_name")}
            />
            <TextInputSign
              type={"text"}
              errorState={errorState.user_surname}
              placeholder="Prénom"
              onTextChange={onValueChange("user_surname")}
            />
            <PhoneCountryInputSign
              errorState={{
                state:
                  errorState.user_phone.state || errorState.user_country.state,
                msg:
                  errorState.user_phone.msg + " " + errorState.user_country.msg,
              }}
              placeholder="Numero de téléphone"
              onSelectCountry={onValueChange("user_country")}
              onTextChange={onValueChange("user_phone")}
            />
            <TextInputSign
              type={"email"}
              errorState={errorState.user_email}
              placeholder="Adresse email"
              onTextChange={onValueChange("user_email")}
            />
            <PasswordInputSign
              placeholder="Mot de passe"
              title="Mot de passe"
              onTextChange={onValueChange("user_password")}
              errorState={errorState.user_password}
            />
            <button
              disabled={isLoading}
              onClick={submitForm}
              className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-white text-[18px] font-medium bg-primary hover:bg-hover rounded-[20px] shadow-sm border"
            >
              <span className="-mt-[1px]">Inscription{isLoading && "..."}</span>
            </button>
          </form>
          <div className="flex flex-row space-x-1 items-center px-5">
            <span className="flex text-[15px]">Avez-vous déjà un compte ?</span>
            <span
              onClick={() => setisOpen(true)}
              className="flex cursor-pointer text-primary hover:text-hover active:text-active text-[17px] font-semibold underline-offset-2 underline"
            >
              Connectez-vous !
            </span>
          </div>
          <div className="flex flex-row space-x-1 items-center">
            <a
              href={"/"}
              className="flex w-fit space-x-3 space-y-3 items-center text-[17px] cursor-pointer font-medium text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>
              <span className="flex underline underline-offset-2">
                Retourner a l'accueil
              </span>
            </a>
          </div>
        </div>
        {/* <div className="flex footer min-h-[70px] w-full bg-primary"></div> */}
      </div>
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default SignupPage;
