import React from "react";
import { useAuthStore } from "../../../../../../../configs/stores/auth";
import { useCoinStore } from "../../../../../../../configs/stores/coin";

const CoinComment = () => {
  const user = useAuthStore((e) => e.user);
  const newComment = useCoinStore((e) => e.newComment);
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [commentText, setCommentText] = React.useState("");
  function onSubmit(e: any) {
    e.preventDefault();
    if (commentText.length > 1) {
      setIsLoading(true);
      newComment(commentText).then((e) => {
        setIsLoading(false);
        setCommentText("");
      });
    } else {
      alert("Entrer votre commentaire !");
    }
  }
  return (
    <div className="flex w-full flex-row flex-wrap px-5 space-y-3 pt-4 bg-white">
      <div className="flex flex-row items-center space-x-3">
        {!!user?.user_profile ? (
          <div className="flex flex-1 h-[30px] w-[30px]">
            <img
              className="flex flex-1 rounded-full object-cover"
              src={user.user_profile.image_link.toString()}
              alt="profile"
            />
          </div>
        ) : (
          <div className="flex rounded-full h-[30px] w-[30px] bg-[rgba(150,150,150,.8)] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-5 h-5"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        )}
        <span className="flex text-[18px] leading-tight font-medium">
          Votre commentaire :
        </span>
      </div>
      <form
        className="flex w-full items-start flex-col space-y-4 mb-4"
        onSubmit={!isLoading ? onSubmit : undefined}
      >
        <textarea
          ref={ref}
          className="flex w-full h-[130px] rounded-md text-[17px] outline-none border focus:border-black/60 p-2"
          name="comment"
          placeholder="Entrer votre commentaire..."
          value={commentText}
          onChange={(e) => {
            setCommentText(e.target.value);
          }}
        />
        <button
          className="flex w-full justify-center py-3 rounded-md bg-primary active:bg-active"
          type="submit"
        >
          {!isLoading ? (
            <span className="flex text-white text-[18px] font-medium">
            Envoyer
            </span>
          ) : (
            <svg
              className="animate-spin h-5 w-5 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={"white"}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={"white"}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </form>
      <div className="flex w-full mx-1 my-2 self-center h-[1px] bg-black/10" />
    </div>
  );
};

export default CoinComment;
