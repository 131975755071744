import React from "react";

const SearchCompanyLoading = () => {
  return (
    <div className="flex flex-1 flex-col space-y-3 px-3 py-3 overflow-y-auto overflow-hidden">
      {Array.from({ length: 10 }).map((_, index) => {
        return (
          <div
            key={index}
            className="flex flex-row px-3 py-4 space-x-4 items-center animate-pulse bg-grey rounded-lg"
          >
            <div className="flex h-[50px] w-[50px] bg-activeGrey rounded-full" />
            <div className="flex flex-1 flex-col space-y-2">
              <div className="flex w-[50%] h-[15px] bg-activeGrey animate-pulse rounded-md" />
              <div className="flex w-[30%] h-[15px] bg-activeGrey animate-pulse rounded-md" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchCompanyLoading;
