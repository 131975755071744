import React from "react";
import ConnexeEtablissment from "./connexe";
import { useSearchParams } from "react-router-dom";

const MobileConnexe = () => {
  const [searchParams] = useSearchParams();
  const [selectedCtgMenu, setselectedCtgMenu] = React.useState("");
  React.useEffect(() => {
    const query = searchParams.get("category");
    if (query && query !== selectedCtgMenu) {
      setselectedCtgMenu(query);
    }
  }, [searchParams, selectedCtgMenu]);
  return (
    <div className="flex xl:hidden flex-col px-5 space-y-6">
      <span className="flex text-[22px] font-semibold">
        Quelques établissements similaires
      </span>
      <ConnexeEtablissment />
      <a href={`/?category=${selectedCtgMenu}`} className="flex w-fit self-center">
        <button className="flex py-2 px-6 mt-2 flex-row gap-3 items-center rounded-lg self-center text-white bg-primary active:bg-active cursor-pointer w-fit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
            />
          </svg>
          <span className="flex text-[18px]">Afficher plus d'établissement</span>
        </button>
      </a>
    </div>
  );
};

export default MobileConnexe;
