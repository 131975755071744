import React from "react";
import { primaryColor } from "../../../../../../../constants";
import LoginModal from "../../../../../../../components/modals/models/login";
import { useCoinStore } from "../../../../../../../configs/stores/coin";
import { useAuthStore } from "../../../../../../../configs/stores/auth";
import { CategoryModel } from "../../../../../../../models/models";

const FavorisButton = () => {
  const [isOpen, setisOpen] = React.useState(false);
  const coin = useCoinStore((e) => e.coin);
  const category = (coin?.category ?? {}) as CategoryModel;
  const isUpdatingFavoris = useAuthStore((e) => e.isUpdatingFavoris);
  const updateFavoris = useAuthStore((e) => e.updateFavoris);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const favoris = useAuthStore((e) => e.user?.user_favorites ?? []);
  function closeLogin() {
    setisOpen(false);
  }
  function checkFavorite() {
    if (!isAuthenticated) {
      setisOpen(true);
    } else {
      const exist = favoris.find(
        (e) => e.category_id === category?.category_id
      );
      updateFavoris(category, !exist ? "add" : "remove");
    }
  }
  return (
    <React.Fragment>
      <button
        onClick={checkFavorite}
        className="transition-all flex flex-1 max-w-[300px] border py-2 rounded-md flex-row gap-2 justify-center items-center"
      >
        {!isUpdatingFavoris ? (
          !favoris.find((fav) => fav.category_id === category?.category_id) ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.9}
                stroke="black"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
              </svg>
              <span className="flex text-[15px] font-semibold">
                Ajouter aux favoris
              </span>
            </>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth={1.9}
                className="w-5 h-5 fill-primary"
              >
                <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
              </svg>
              <span className="flex text-[15px] font-semibold">
                Retirer des Favoris
              </span>
            </>
          )
        ) : (
          <svg
            className="animate-spin h-5 w-5 text-black/50"
            xmlns="http://www.w3.org/2000/svg"
            fill={"transparent"}
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke={primaryColor}
              strokeWidth="2"
            ></circle>
            <path
              className="opacity-75"
              fill={primaryColor}
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
      <LoginModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default FavorisButton;
