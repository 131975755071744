import axios from "axios";
import { chunkFile, compressChunk } from "../constants";

export async function uploadEtudeData(
  data: any,
  cb = function (reponse: any) {}
) {
  // https://cloudservices.awanze.com 
  const result = await axios.post(
    `https://awanzecloudservices.vercel.app/v1/store/manage/upload`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  if (!result.data) {
    let rtr = {
      success: false,
      data: result,
      msg: "Erreur survenue !",
    };
    cb(rtr);
    return rtr;
  }
  if (result.data) {
    let rtr = { ...result.data, success: true };
    cb(rtr);
    return rtr;
  } else {
    let rtr = {
      success: false,
      data: result.data,
      msg: "Erreur survenue !",
    };
    cb(rtr);
    return rtr;
  }
}

export async function uploadFiles(
  allFiles: Array<File>,
  folder: string,
  uploading = (index: number) => {},
  uploaded = (index: number) => {}
) {
  const datas = allFiles.map((file) => ({
    name: file.name,
    type: file.name.split(".").reverse()[0],
    file: file,
  }));
  let rponse = [];
  for (let idx = 0; idx < datas.length; idx++) {
    const data = datas[idx];
    const tempPartsFolder = data.name + "-tempDoc";
    let res = await new Promise(async (resolve, reject) => {
      uploading(idx);
      const fileChunks = chunkFile(data.file, 2 * 1024 * 1024);
      const compressedChunks = await Promise.all(
        fileChunks.map((chunk) => compressChunk(chunk))
      );
      async function sendChunks(
        fileChunks: Array<any>,
        fileName: any,
        maxConcurrency = 5
      ) {
        const totalChunks = fileChunks.length;
        let currentChunkIndex = 0;

        function createTask(chunk: any, chunkIndex: number) {
          return async () => {
            try {
              const formDa = new FormData();
              formDa.append("chunkIndex", chunkIndex.toString());
              formDa.append("totalChunks", totalChunks.toString());
              formDa.append("fileName", fileName);
              formDa.append("tempPartsFolder", tempPartsFolder);
              formDa.append(
                "folderKey",
                folder //`LeCoinChicDuMoment/uploads/conveniences`
              );
              formDa.append("file", chunk);
              await uploadEtudeData(formDa, function (reponse) {
                if (reponse?.data?.file_id) {
                  uploaded(idx);
                  resolve({
                    file_id: reponse.data.file_id,
                    download_url: reponse.data.download_url,
                    name: data.name,
                    type: data.type,
                  });
                }
              });
            } catch (err) {
              // console.log(`Error uploading chunk ${chunkIndex}:`, err);
              return createTask(chunk, chunkIndex); // Return a new task to retry
            }
          };
        }

        const tasks = fileChunks.map((chunk: any, chunkIndex) =>
          createTask(chunk, chunkIndex)
        );

        while (currentChunkIndex < tasks.length) {
          const activeTasks = tasks.slice(
            currentChunkIndex,
            currentChunkIndex + maxConcurrency
          );
          await Promise.all(activeTasks.map((task) => task()));
          currentChunkIndex += maxConcurrency;
        }
      }
      sendChunks(compressedChunks, data.file.name);
    });
    if (res) {
      rponse.push(res);
    }
  }
  return rponse;
}
