import React from "react";
import { useCurrentShowItemsLengthPerRow } from "../../../../../functions/useLayoutDimension";
import CategoryItemLaptop from "../../../../../components/categoryItem/laptop";
import CategoryTypeRowLoading from "./loadingItem";
import { useLoadOnAppear } from "../../../../../functions/useLoadOnAppear";
import { CategoryDataInterface } from "../../../../../configs/stores/categories";
import { CategoryTypeModel } from "../../../../../models/models";

type Props = {
  item: {
    type: CategoryTypeModel;
    categories: Array<CategoryDataInterface>;
  };
};

const CategoryTypeRow = ({ item: { type, categories } }: Props) => {
  const MaxItemLenghtPerRow = useCurrentShowItemsLengthPerRow();
  const { element, isVisible } = useLoadOnAppear<HTMLDivElement>({});

  if (categories.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="flex flex-col" ref={element}>
        {isVisible && (
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 256 256"
                className="flex fill-primary h-[35px]"
              >
                <path d="M200,224H150.54A266.56,266.56,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25a88,88,0,0,0-176,0c0,31.4,14.51,64.68,42,96.25A266.56,266.56,0,0,0,105.46,224H56a8,8,0,0,0,0,16H200a8,8,0,0,0,0-16ZM128,72a32,32,0,1,1-32,32A32,32,0,0,1,128,72Z"></path>
              </svg>
              <span className="flex text-[20px] font-semibold ml-3">
                {type.categorytype_name}
              </span>
            </div>
            <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-2 mini:grid-cols-3 normal:grid-cols-4 big:grid-cols-5 toobig:grid-cols-6 gap-6">
              {categories.slice(0, MaxItemLenghtPerRow).map((item) => {
                return (
                  <CategoryItemLaptop
                    key={
                      "CategoryTypeRow_" + item.category.category_id.toString()
                    }
                    item={item}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!isVisible && <CategoryTypeRowLoading />}
      </div>
    </React.Fragment>
  );
};

export default CategoryTypeRow;
