import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { CarouselSlide } from "./side";
import { ANY } from "../../../constants";

const CategoryItemLaptopCarousel = ({ images = Array(ANY) }) => {
  const [isHover, setisHover] = React.useState(false);
  function enter() {
    const wdth = window.innerWidth;
    wdth >= 950 && setisHover(true);
  }
  function out() {
    !!isHover && setisHover(false);
  }
  return (
    <div
      className="flex flex-1 flex-col rounded-t-[18px] overflow-hidden"
      onMouseEnter={enter}
      onMouseOver={enter}
      onMouseLeave={out}
    >
      <Swiper
        style={{
          "--swiper-navigation-color": isHover ? "#fff" : "transparent",
          "--swiper-navigation-size": "24px",
          "--swiper-navigation-sides-offset": "16px",
          "--swiper-pagination-color": "rgba(250,250,250,0.9)",
          "--swiper-pagination-bullet-inactive-color": "#fff",
          "--swiper-pagination-bullet-inactive-opacity": "0.6",
          "--swiper-pagination-bottom": "18px",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={true}
        loop={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {images
          .filter((e) => !!e?.image_ispreview)
          .map((image, index) => {
            return (
              <SwiperSlide key={image.image_id.toString()}>
                <CarouselSlide image={image} />
              </SwiperSlide>
            );
          })}
        {images
          .filter((e) => !e?.image_ispreview)
          .map((image, index) => {
            return (
              <SwiperSlide key={image.image_id.toString()}>
                <CarouselSlide image={image} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default CategoryItemLaptopCarousel;
