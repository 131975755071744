import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageModel } from "../../../../../../../models/models";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SampleNextArrow(props: any) {
  return null;
}

function SamplePrevArrow(props: any) {
  return null;
}

function CustomAppendDot({ dots = Object() }) {
  return (
    <div className="dots-container w-full h-[15%] pt-3 absolute top-0 transition-all bg-gradient-to-t from-transparent to-black/40">
      <ul className="flex justify-center gap-[6px]">{dots}</ul>
    </div>
  );
}

function CustomPagingDot({
  index = 0,
  active = false,
  slide = (i: number) => {},
}) {
  return (
    <div
      className={`dots-item cursor-pointer transition-all h-[9px] w-[9px] rounded-full ${
        active
          ? "dots-item-active bg-white w-[17px] shadow-lg"
          : " bg-slate-400/70"
      }`}
      onClick={function (c) {
        slide(index);
      }}
    ></div>
  );
}

const NextArrow = ({ onClick = () => {} }) => {
  return (
    <div
      className={` transition-all group-hover:block hidden absolute top-[45%] right-0 p-[9px] mr-4 rounded-full bg-slate-100 cursor-pointer`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="rgba(0,0,0,0.6)"
        className="w-[16px] h-[16px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  );
};

const PrevArrow = ({ onClick = () => {} }) => {
  return (
    <div
      className={` transition-all group-hover:block hidden absolute top-[45%] left-0 p-[9px] ml-4 rounded-full bg-slate-100 cursor-pointer`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="rgba(0,0,0,0.6)"
        className="w-[16px] h-[16px]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </div>
  );
};

function ImageSlide(params: any) {
  const { ...props } = params;
  return (
    <div
      {...props}
      style={{
        display: "flex",
        ...props.style,
        height: props.height,
        maxHeight: 500,
        background: "white",
      }}
      className="overflow-hidden"
    >
      <LazyLoadImage
        src={props.image.image_link.toString()}
        alt={props.image.image_id.toString()}
        placeholderSrc={props.image.image_miniature.toString()}
        style={{
          maxHeight: 500,
          height: props.height,
          objectFit: "cover",
          cursor: "pointer",
        }}
        width={"100%"}
        effect="blur"
      />
    </div>
  );
}

type Props = {
  images: Array<ImageModel>;
  height: number;
  width: number;
};

function ImagesCarroussel({ images, height = 0, width = 0 }: Props) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  let sliderRef: Slider | null;
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
    appendDots: (dots: any) =>
      images.length > 1 ? <CustomAppendDot dots={dots} /> : <div />,
    customPaging: (i: number) => (
      <CustomPagingDot index={i} active={currentSlide === i} slide={goto} />
    ),
  };
  function next() {
    if (!!sliderRef) {
      sliderRef.slickNext();
    }
  }
  function prev() {
    if (!!sliderRef) {
      sliderRef.slickPrev();
    }
  }
  function goto(i: number) {
    if (!!sliderRef) {
      sliderRef.slickGoTo(i, false);
    }
  }
  React.useEffect(() => {
    const menuItCat = document.getElementById("listImg");
    const curDoc = document.getElementById("selectedImg_" + currentSlide);
    if (curDoc && menuItCat) {
      if (currentSlide === 0) {
        menuItCat.scrollTo({
          behavior: "smooth",
          left: curDoc.offsetLeft - 15,
        });
      } else {
        menuItCat.scrollTo({
          behavior: "smooth",
          left:
            curDoc.offsetLeft -
            (menuItCat.offsetWidth - curDoc.offsetWidth) / 2,
        });
      }
    }
  }, [currentSlide]);
  return (
    <div style={{ width }} className="flex flex-col bg-white">
      <div
        style={{ width, height, maxHeight: 500 }}
        className={`group flex flex-col relative`}
      >
        <Slider
          ref={(c) => {
            sliderRef = c;
          }}
          {...settings}
        >
          {images
            .filter((e) => !!e?.image_ispreview)
            .map((img, index) => (
              <ImageSlide key={index} image={img} height={height} />
            ))}
          {images
            .filter((e) => !e?.image_ispreview)
            .map((img, index) => (
              <ImageSlide key={index} image={img} height={height} />
            ))}
        </Slider>
        {currentSlide !== 0 && images.length > 1 && (
          <PrevArrow onClick={prev} />
        )}
        {currentSlide + 1 !== images.length && <NextArrow onClick={next} />}
      </div>
      <div
        id={"listImg"}
        style={{
          display: images.length !== 1 ? "flex" : "none",
        }}
        className="flex w-full px-5 py-4 overflow-auto space-x-4"
      >
        {images
          .filter((e) => !!e?.image_ispreview)
          .map((img, index) => (
            <div
              id={"selectedImg_" + index}
              key={index}
              onClick={function () {
                goto(index);
              }}
              className="flex"
            >
              <div
                className={`flex transition-all bg-white cursor-pointer rounded-sm overflow-hidden ${
                  currentSlide === index
                    ? "ring-4 ring-primary scale-105"
                    : " scale-100"
                }`}
                style={{
                  height: 75,
                  minWidth: 100,
                  width: 100,
                  objectFit: "cover",
                  backgroundColor: "",
                }}
              >
                <LazyLoadImage
                  src={img.image_link.toString()}
                  alt={img.image_id.toString()}
                  placeholderSrc={img.image_miniature.toString()}
                  style={{
                    height: 75,
                    minWidth: 100,
                    width: 100,
                    objectFit: "cover",
                    backgroundColor: "",
                  }}
                  width={"100%"}
                  effect="blur"
                />
              </div>
            </div>
          ))}
        {images
          .filter((e) => !e?.image_ispreview)
          .map((img, index) => (
            <div
              id={"selectedImg_" + index}
              key={index}
              onClick={function () {
                goto(index);
              }}
              className="flex"
            >
              <div
                className={`flex transition-all bg-white cursor-pointer rounded-sm overflow-hidden  ${
                  currentSlide === index
                    ? "ring-4 ring-primary scale-105"
                    : " scale-100"
                }`}
                style={{
                  height: 75,
                  minWidth: 100,
                  width: 100,
                  objectFit: "cover",
                  backgroundColor: "",
                }}
              >
                <LazyLoadImage
                  src={img.image_link.toString()}
                  alt={img.image_id.toString()}
                  placeholderSrc={img.image_miniature.toString()}
                  style={{
                    height: 75,
                    minWidth: 100,
                    width: 100,
                    objectFit: "cover",
                    backgroundColor: "",
                  }}
                  width={"100%"}
                  effect="blur"
                />
              </div>
            </div>
          ))}
      </div>
      <div className="flex w-full self-center h-[1px] bg-black/10" />
    </div>
  );
}

export default ImagesCarroussel;
