import React from "react";
import MenuCategories from "./categories";
import { useGetScrollPos } from "../../../functions/useGetScrollPos";
import SearchCompany from "../../modals/models/searchcompany";

const Mobile = () => {
  const { atZero } = useGetScrollPos();
  const [isOpen, setisOpen] = React.useState(false);
  function closeSearch() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div
        className={`flex md:hidden flex-col bg-white fixed top-[44px] left-0 w-full pt-[0px] my-4 space-y-2 z-[222] items-center ${
          !atZero ? "shadow-md" : ""
        }`}
      >
        <MenuCategories />
        <div className="flex flex-row absolute -top-[8px] right-[0px] h-full px-2 w-[40px] bg-gradient-to-r from-transparent via-black/10 to-black/20 backdrop-blur-[.5px]" />
        {/* <div className="flex flex-row absolute -top-[8px] right-[0px] h-full px-2 w-[100px] bg-gradient-to-r from-transparent via-black/20 to-black/20 backdrop-blur-[.5px]" /> */}
        {/* <div className="flex flex-row absolute -top-[5.8px] right-[1px] h-[90%] px-2 bg-white rounded-l-full space-x-1 shadow-sm items-center justify-center">
          <svg
            onClick={() => setisOpen(true)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
          <span className="flex text-[15px] font-medium">Filtrer</span>
        </div> */}
      </div>
      <SearchCompany
        open={isOpen}
        defaultOpenFilter
        handleClose={closeSearch}
      />
    </React.Fragment>
  );
};

export default Mobile;
