import React from "react";
// import { useCoinHook } from "../..";
import { useSearchParams } from "react-router-dom";
import OurContactModal from "../../../../../../components/modals/models/ourcontacts";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CompanyModel } from "../../../../../../models/models";
import { useCoinStore } from "../../../../../../configs/stores/coin";

const CoinHeaderLaptop = () => {
  const [searchParams] = useSearchParams();
  const coin = useCoinStore((e) => e.coin);
  const company = (coin?.company ?? {}) as CompanyModel;
  const categories = coin?.categories ?? [];
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div className="hidden flex-row bg-white mt-[10px] py-5 px-6 rounded-xl xl:flex self-center w-[85%] max-w-[1500px] 2xl:py-5 justify-between">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex h-[62px] w-[62px] rounded-full justify-center items-center bg-[#ccc]">
            {company?.company_logo ? (
              <div
                style={{ boxShadow: "0 0 10px rgba(0,0,0,.2)" }}
                className="flex h-full w-full rounded-full bg-white p-[3.5px] ring-white ring-2 overflow-hidden"
              >
                <LazyLoadImage
                  className="flex w-full h-full rounded-full border-[rgba(0,0,0,.1)]"
                  src={company.company_logo.image_link.toString()}
                  alt={company.company_slug.toString()}
                  placeholderSrc={company.company_logo.image_miniature.toString()}
                  height={"100%"}
                  width={"100%"}
                  effect="blur"
                />
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={"white"}
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-lg leading-tight">
              {company.company_name}
            </span>
            {!!company?.company_locality_precision && (
              <span className="leading-tight font-medium text-[14px]">
                {company?.company_country?.locality_name ?? "localisation"}{" "}
                {" - "} {company?.company_locality_precision ?? "localisation"}
              </span>
            )}
          </div>
        </div>
        <div className="flex transition-all flex-row items-center space-x-4">
          {categories.map((item, index) => {
            return (
              <a
                key={index}
                href={"?category=" + item.category_type.categorytype_slug}
                className={`flex ${
                  searchParams.get("category") ===
                  item.category_type.categorytype_slug
                    ? "font-semibold scale-[1.1] text-primary underline underline-offset-4"
                    : "font-medium"
                }`}
              >
                <span>{item.category_type.categorytype_name}</span>
              </a>
            );
          })}
          <div className="flex border-r h-[40px] ml-2 bg-black" />
          <button
            className="flex transition-all px-4 flex-row items-center justify-center gap-2 py-1 pr-[35px] hover:bg-hoverGrey active:bg-activeGrey border rounded-lg"
            onClick={() => setisOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={"black"}
              className="w-5 h-5 m-2"
            >
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clipRule="evenodd"
              />
            </svg>
            <span className="flex text-[17px] font-medium">Contactez-nous</span>
          </button>
        </div>
      </div>
      <OurContactModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default CoinHeaderLaptop;
