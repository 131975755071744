import React from "react";
import { generateImageMiniature } from "../../../../../constants";
import { uploadFiles } from "../../../../../functions/uploadFiles";
import { getPropValue } from "../../../../../functions/getPropValue";
import { useAuthStore } from "../../../../../configs/stores/auth";

const Profile = () => {
  const user = useAuthStore((e) => e.user);
  const updateProfile = useAuthStore((e) => e.updateProfile);
  const [isLoading, setisLoading] = React.useState(false);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    setisLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      if (!!reader.result) {
        let files: File[] = [];
        files.push(image);
        const reponses = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/user/profile/${user?.user_id}`
        );
        const dts = reponses.filter((r: any) => !!r.file_id);
        if (dts.length !== 0) {
          let miniature = await generateImageMiniature(files[0], {
            width: 10,
            height: 10,
          });
          const imageData = {
            image_parent: user?.user_id,
            image_parent_model: "category",
            image_miniature: miniature,
            image_link: getPropValue(dts[0], "download_url"),
            image_key: getPropValue(dts[0], "file_id"),
          };
          await updateProfile(imageData);
          setisLoading(false);
        }
      }
    };
    reader.onerror = () => {
      setisLoading(false);
      console.error("something went wrong!");
    };
  }
  return (
    <div className="flex flex-col space-y-5 w-full lg:w-[300px] pt-5 items-center">
      <span className="flex font-medium text-[16px]">Photo de profile</span>
      <div className="flex w-[150px] h-[150px] border overflow-hidden bg-slate-600/10 shadow-sm rounded-full select-none">
        {!!user?.user_profile ? (
          <div className="flex flex-1 rounded-full">
            <img
              className="flex flex-1 rounded-full object-cover"
              src={user.user_profile.image_link.toString()}
              alt="profile"
            />
          </div>
        ) : (
          <div className="flex flex-1 rounded-full bg-[rgba(150,150,150,.8)] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-[50px] h-[50px]"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        )}
      </div>
      <button
        onClick={pickImage}
        disabled={isLoading}
        className="flex border w-[70%] rounded-[14px] items-center justify-center px-4 py-3 duration-150 transition-all active:text-active hover:text-hover hover:border-hover select-none"
      >
        <span className="flex text-[15px] font-medium">
          {!isLoading ? "Modifier l'image" : "Modification..."}
        </span>
      </button>
      <input
        id="fileType"
        type="file"
        accept="image/*" //accept="image/*"
        className="hidden"
        onChange={handleChange}
      />
    </div>
  );
};

export default Profile;
