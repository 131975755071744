import React from "react";
import ModalCustom from "../../modal";
import { useCoinStore } from "../../../../configs/stores/coin";
import AddedServiceItem from "./item";

const CoinAddedServicesModal = ({ handleClose = () => {}, open = false }) => {
  const addedServices = useCoinStore((e) => e.addedServices);
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <React.Fragment>
        <div className="flex flex-col p-5 pt-2 space-y-4 w-[450px] max-w-[80vw]">
          <div className="flex w-full justify-start pt-0 pb-1">
            <h2 className="flex text-center text-lg font-semibold tracking-tight max-sm:text-xl">
              Services ajoutées
            </h2>
          </div>
          <div className="flex flex-col space-y-[24px] pr-1 max-h-[350px] overflow-y-auto">
            {addedServices.map((e) => {
              return (
                <AddedServiceItem key={e.service_id.toString()} service={e} />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    </ModalCustom>
  );
};

export default CoinAddedServicesModal;
