import React from "react";

const FavorisLoading = () => {
  return (
    <div className="flex bg-white p-5 px-5 items-start space-x-4 flex-row w-[500px] max-w-full rounded-[10px]">
      <div className="flex p-1 ring-2 ring-primary rounded-full bg-transparent overflow-hidden">
        <div className="flex h-[35px] w-[35px] ring-2 ring-white/30 bg-activeGrey rounded-full animate-pulse" />
      </div>
      <div className="flex flex-col flex-1 space-y-2 pt-2 leading-tight">
        <div className="flex h-[18px] w-full bg-activeGrey rounded-sm animate-pulse" />
        <div className="flex h-[18px] w-[70%] bg-activeGrey rounded-sm animate-pulse" />
        <div className="flex h-[18px] w-[40%] bg-activeGrey rounded-sm animate-pulse" />
      </div>
    </div>
  );
};

export default FavorisLoading;
