import React from "react";
import { useRefLayoutDimension } from "../../../../../functions/useLayoutDimension";

const LaptopSkeleton = () => {
  const { element, width } = useRefLayoutDimension<HTMLDivElement>();
  return (
    <React.Fragment>
      <div className="hidden xl:flex flex-col space-y-8 pt-3">
        {/** Header */}
        <div className="flex flex-row bg-activeGrey animate-pulse mt-[10px] py-5 px-6 rounded-xl self-center w-[85%] max-w-[1500px] 2xl:py-5 justify-between">
          <div className="flex flex-row items-center space-x-4">
            <div className="flex h-[62px] w-[62px] rounded-full justify-center items-center bg-grey animate-pulse"></div>
            <div className="flex flex-col space-y-2">
              <span className="font-bold w-[200px] h-[15px] text-lg leading-tight bg-grey rounded-md animate-pulse" />
              <span className="leading-tight w-[150px] h-[15px] font-medium text-[14px] bg-grey rounded-md animate-pulse" />
            </div>
          </div>
          <div className="flex transition-all flex-row items-center space-x-4">
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <span key={index} className="flex w-[70px] h-[20px] bg-grey rounded-md animate-pulse" />
              );
            })}
            <div className="flex border-r h-[40px] ml-2 bg-black" />
            <div className="flex transition-all px-4 flex-row items-center justify-center gap-2 py-1 border rounded-lg  bg-grey animate-pulse">
              <span className="flex text-[17px] font-medium text-transparent">
                Contactez-nous
              </span>
            </div>
          </div>
        </div>
        {/** Illustration */}
        <div className="hidden xl:flex flex-col w-full space-y-5 items-center">
          <div className="flex self-center flex-col space-y-7 w-[85%] max-w-[1500px] ">
            <div
              ref={element}
              style={{ height: (width * 7) / 18 }}
              className="flex w-full flex-row rounded-[18px] overflow-hidden space-x-[25px]"
            >
              <div
                style={{ height: (width * 7) / 18, width: width * 0.75 }}
                className={`group relative flex flex-col rounded-[18px] select-none bg-activeGrey animate-pulse`}
              ></div>
              <div className="flex flex-1 flex-col w-full py-[6px] pl-1 pr-2 overflow-auto space-y-5 scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100">
                {Array.from({ length: 3 }).map((img, index) => (
                  <div
                    key={index}
                    className={`flex w-full h-[200px] flex-col transition-all object-cover cursor-pointer rounded-[12px] bg-activeGrey animate-pulse`}
                  />
                ))}
              </div>
            </div>
            {/** Category Datas*/}
            <div className="flex flex-row justify-between mt-[15px] pb-10 space-x-[30px]">
              <div className="flex flex-1 items-start flex-col h-[1000px] px-6 space-y-6 rounded-[18px] py-5 pt-8 bg-activeGrey animate-pulse">
                {Array.from({
                  length: 3,
                }).map((e, index) => {
                  return (
                    <div
                    key={index}
                      style={{
                        width: `calc(100% - ${(
                          (index * 10 + 5) *
                          10
                        ).toString()}px)`,
                      }}
                      className="flex h-[45px] rounded-xl bg-grey animate-pulse"
                    />
                  );
                })}
              </div>
              <div className="flex w-[27%] sticky h-[400px] top-[100px] min-w-[350px] flex-col space-y-4 shadow-md px-[27px] py-5 mr-3 rounded-[18px] bg-activeGrey animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LaptopSkeleton;
