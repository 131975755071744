import React from "react";
import MapHeader from "./header";
import MapTile from "./tile";

type MapContextProps = {
  map: any;
};

const MapContext = React.createContext<MapContextProps>({} as MapContextProps);

export function useMapHook() {
  return React.useContext(MapContext);
}

type MapPageProps = {
  withPosition?: boolean;
};

function MapPage({ withPosition }: MapPageProps) {
  return (
    <MapContext.Provider value={{ map: "" }}>
      <div className="flex flex-1 relative w-full flex-col bg-activeGrey">
        <MapHeader />
        <MapTile />
      </div>
    </MapContext.Provider>
  );
}

export default MapPage;
