import React from "react";

const JobsLink = () => {
  return (
    <>
      <a
        className={`hidden p-2 px-4 space-x-2 items-center rounded-full select-none transition-all bg-black hover:bg-hover xl:flex`}
        href={"https://jobs.lecoinchicdumoment.com/"}
        target="_blank"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 64 64"
          width="256"
          height="256"
          className="flex w-[18px] h-[18px] fill-white"
        >
          <path d="M40.9 11.8c-.4-4.2-2.4-7.3-6.3-8.9-1.8-.7-3.6-1.1-5.5-1.1s-3.8.3-5.5 1.1c-4 1.7-6 4.7-6.3 8.9-.1.6 0 1.2 0 1.8 0 4.4 1 8.5 2.7 12.5.9 2 2.1 3.7 3.7 5.2 1.6 1.6 3.5 2.4 5.5 2.3 2 .1 3.9-.7 5.5-2.3 1.5-1.5 2.8-3.2 3.7-5.2 1.7-4 2.7-8.2 2.7-12.5-.2-.6-.2-1.2-.2-1.8zM41.9 60c-2.8.3-5.5.5-8.3.6-.7 0-1.4 0-2.1.1 0 0-5.5 0-6.1-.1-3.6-.1-7.1-.4-10.7-.9-3.6-.5-7.2-1.3-10.7-2.4-.5-.2-1-.3-1.5-.5C.7 56 1 56.4 1 54.4v-1.1c.1-2.8.4-5.6 1.2-8.2.3-.9.6-1.8 1.1-2.4l.1-.1c.6-.7 1.3-1.3 2.1-1.6 2.4-.6 4.7-1.2 7.1-1.8l8.4-2.1c.2 0 .4 0 .7-.1 0 .1.1.3.1.4.5 2 1.1 4 1.7 6 .7 2.4 1.6 4.7 2.4 7.1.1.3.2.5.3.8.3-2.1.7-5.6 1-7.5.1-.9.2-1.4.2-1.5-.1-.3-.2-.6-.4-.9v-.1c-.1-.2-.1-.3-.2-.5l-.1-.1v-.1c-.1-.2-.2-.4-.2-.7 0-.1 0-.3.1-.4 0-.2.1-.4.2-.6v-.1c.1-.2.3-.3.4-.3h4.2c.1 0 .2 0 .3.1 0 0 .1.1.1.2s.1.1.1.2v.2c.1.2.2.4.2.6 0 .3-.1.5-.2.7 0 .1-.1.1-.1.2-.1.2-.1.3-.2.5v.1c-.1.3-.2.6-.4.8 0 .1.3 2.6.7 5.2.2 1.4.4 2.8.5 3.8.1-.2.3-.5.3-.8.8-2.3 1.6-4.7 2.4-7.1.6-2 1.1-4 1.7-6 0-.1.1-.3.1-.4.3 0 .5 0 .7.1 3.1.8 6.2 1.6 9.3 2.3.5.1 1 .3 1.6.4-6 .4-10.7 5.4-10.7 11.5.1 3.7 1.7 6.8 4.1 8.9z"></path>
          <path d="M62.4 59.4c-.8-.6-1.6-1.3-2.4-1.9-.2-.1-.3-.3-.5-.4-.7-.6-1.5-1.1-2.2-1.7 2.2-4.4.5-9.2-3.1-11.5-3.7-2.2-8.4-1.5-11.1 1.8-2.8 3.3-2.7 8.1.2 11.4 2.8 3.2 8.2 4.1 12.2.7.1.1.1.2.2.2.6.5 1.3 1 1.9 1.5 1 .8 1.9 1.5 2.9 2.3.7.6 1.7.5 2.2-.2.5-.7.4-1.6-.3-2.2zM50.2 58c-3.4.3-6.7-2.2-7.2-5.9s2.2-7 6-7.4c3.6-.4 6.9 2.3 7.3 5.9.4 3.6-2.3 7.1-6.1 7.4z"></path>
        </svg>
        <span className="flex text-white text-[15px] font-medium">Emplois</span>
      </a>
      <a
        className={`flex p-[9px] rounded-full select-none transition-all bg-black hover:bg-hover max-md:hidden xl:hidden`}
        href={"https://jobs.lecoinchicdumoment.com/"}
        target="_blank"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 64 64"
          width="256"
          height="256"
          className="flex w-[18px] h-[18px] fill-white"
        >
          <path d="M40.9 11.8c-.4-4.2-2.4-7.3-6.3-8.9-1.8-.7-3.6-1.1-5.5-1.1s-3.8.3-5.5 1.1c-4 1.7-6 4.7-6.3 8.9-.1.6 0 1.2 0 1.8 0 4.4 1 8.5 2.7 12.5.9 2 2.1 3.7 3.7 5.2 1.6 1.6 3.5 2.4 5.5 2.3 2 .1 3.9-.7 5.5-2.3 1.5-1.5 2.8-3.2 3.7-5.2 1.7-4 2.7-8.2 2.7-12.5-.2-.6-.2-1.2-.2-1.8zM41.9 60c-2.8.3-5.5.5-8.3.6-.7 0-1.4 0-2.1.1 0 0-5.5 0-6.1-.1-3.6-.1-7.1-.4-10.7-.9-3.6-.5-7.2-1.3-10.7-2.4-.5-.2-1-.3-1.5-.5C.7 56 1 56.4 1 54.4v-1.1c.1-2.8.4-5.6 1.2-8.2.3-.9.6-1.8 1.1-2.4l.1-.1c.6-.7 1.3-1.3 2.1-1.6 2.4-.6 4.7-1.2 7.1-1.8l8.4-2.1c.2 0 .4 0 .7-.1 0 .1.1.3.1.4.5 2 1.1 4 1.7 6 .7 2.4 1.6 4.7 2.4 7.1.1.3.2.5.3.8.3-2.1.7-5.6 1-7.5.1-.9.2-1.4.2-1.5-.1-.3-.2-.6-.4-.9v-.1c-.1-.2-.1-.3-.2-.5l-.1-.1v-.1c-.1-.2-.2-.4-.2-.7 0-.1 0-.3.1-.4 0-.2.1-.4.2-.6v-.1c.1-.2.3-.3.4-.3h4.2c.1 0 .2 0 .3.1 0 0 .1.1.1.2s.1.1.1.2v.2c.1.2.2.4.2.6 0 .3-.1.5-.2.7 0 .1-.1.1-.1.2-.1.2-.1.3-.2.5v.1c-.1.3-.2.6-.4.8 0 .1.3 2.6.7 5.2.2 1.4.4 2.8.5 3.8.1-.2.3-.5.3-.8.8-2.3 1.6-4.7 2.4-7.1.6-2 1.1-4 1.7-6 0-.1.1-.3.1-.4.3 0 .5 0 .7.1 3.1.8 6.2 1.6 9.3 2.3.5.1 1 .3 1.6.4-6 .4-10.7 5.4-10.7 11.5.1 3.7 1.7 6.8 4.1 8.9z"></path>
          <path d="M62.4 59.4c-.8-.6-1.6-1.3-2.4-1.9-.2-.1-.3-.3-.5-.4-.7-.6-1.5-1.1-2.2-1.7 2.2-4.4.5-9.2-3.1-11.5-3.7-2.2-8.4-1.5-11.1 1.8-2.8 3.3-2.7 8.1.2 11.4 2.8 3.2 8.2 4.1 12.2.7.1.1.1.2.2.2.6.5 1.3 1 1.9 1.5 1 .8 1.9 1.5 2.9 2.3.7.6 1.7.5 2.2-.2.5-.7.4-1.6-.3-2.2zM50.2 58c-3.4.3-6.7-2.2-7.2-5.9s2.2-7 6-7.4c3.6-.4 6.9 2.3 7.3 5.9.4 3.6-2.3 7.1-6.1 7.4z"></path>
        </svg>
      </a>
    </>
  );
};

export default JobsLink;
