import { useState, useEffect } from "react";

function usePersistState<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Récupérer la valeur du stockage local (localStorage) lors de l'initialisation
  const storedValue = localStorage.getItem(key);
  // Utiliser la valeur stockée ou la valeur par défaut si aucune valeur n'est stockée
  const initial = storedValue ? JSON.parse(storedValue) : defaultValue;

  // Créer un état local
  const [value, setValue] = useState<T>(initial);

  // Utiliser useEffect pour mettre à jour la valeur dans le stockage local chaque fois qu'elle change
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default usePersistState;
