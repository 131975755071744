import React from "react";
import CategoriesPresentation from "../../../../../components/presentation";
import CategoryItemLaptop from "../../../../../components/categoryItem/laptop";
import CategoryItemLaptopLoading from "../../../../../components/categoryItem/laptop/loading";
import ShowMore from "./more";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { useCategoryStore } from "../../../../../configs/stores/categories";

const HomeSpecificCategory = () => {
  const [searchParams] = useSearchParams();
  const categoryTypeSlug = useCategoryStore((e) => e.categoryTypeSlug);
  const categoriesAll = useCategoryStore((e) => e.categories);
  const isLoading = useCategoryStore((e) => e.isloadingCategories);
  const categories =
    categoriesAll.filter((e) => e.typeSlug === categoryTypeSlug)?.[0]
      ?.categories ?? [];
  const isLoadingPremiumCategories = useCategoryStore(
    (e) => e.isLoadingPremiumCategories
  );
  const getAllCategories = useCategoryStore((e) => e.getAllCategories);
  const premiumCategoriesAll = useCategoryStore((e) => e.premiumCategories);
  const premiumCategories =
    premiumCategoriesAll.filter((e) => e.typeSlug === categoryTypeSlug)?.[0]
      ?.categories ?? [];
  const updateCategoryTypeSlug = useCategoryStore(
    (e) => e.updateCategoryTypeSlug
  );
  React.useEffect(() => {
    const categoryQuery = searchParams.get("category");
    if (!!categoryQuery && categoryQuery !== categoryTypeSlug) {
      updateCategoryTypeSlug(categoryQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("category")]);

  React.useEffect(() => {
    if (searchParams.get("category") === categoryTypeSlug) {
      getAllCategories(categoryTypeSlug, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTypeSlug]);

  return (
    <React.Fragment>
      <div className="flex flex-col w-full items-center pb-[50px]">
        <Helmet>
          <title>
            {categories[0]?.category.category_type.categorytype_name
              .toString()
              .toUpperCase() ??
              "Le Coin Chic Du Moment - Réserver un coin chic maintenant"}
          </title>
          <meta
            name="description"
            content="Trouvez les meilleures adresses touristiques du pays et réservez-les facilement dès maintenant pour vos moments inoubliables."
          />
          <meta
            name="keywords"
            content="le coin chic du moment, LeCoinChicDuMoment, lecoinchicdumoment, chicdumoment, chic coin, reservation en ligne, reservation, plateforme, carte d'etablissement, coin, coin chic, chic coin, coins, chics, espace, rapide, facile, simple, categorie, boutique en ligne, achats en ligne, hotel, restaurant, residence meublee, circuit touristique, espace enfant, espace event, night club"
          />
          <meta name="robots" content="index, follow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="canonical" href="https://www.lecoinchicdumoment.com/" />
        </Helmet>
        <div className="flex flex-col w-full space-y-7 max-w-[2520px] px-5 xl:px-5 2xl:px-20">
          <CategoriesPresentation
            categories={premiumCategories}
            isLoading={isLoadingPremiumCategories}
          />
          <div className="flex pt-[20px]">
            <span className="flex text-[22px] font-semibold leading-snug">
              Parcourez parmi les meilleurs établissements enregistrés, réservez
              le lieu de votre choix.
            </span>
          </div>
          <div className="flex flex-col space-y-10">
            <div className="grid w-full phone:grid-cols-1 tablette:grid-cols-2 mini:grid-cols-3 normal:grid-cols-4 big:grid-cols-5 toobig:grid-cols-6 gap-6">
              {categories.length !== 0 &&
                categories.map((item) => {
                  return (
                    <CategoryItemLaptop
                      key={
                        "HomeSpecificCategories_" +
                        item.category.category_id.toString()
                      }
                      item={item}
                    />
                  );
                })}
              {categories.length === 0
                ? isLoading &&
                  Array.from({ length: 20 }).map((_, index) => {
                    return (
                      <CategoryItemLaptopLoading
                        key={
                          "HomeSpecificCategoriesLoading_" + index.toString()
                        }
                      />
                    );
                  })
                : null}
            </div>
          </div>
          <div className="flex flex-row justify-center pt-10">
            <ShowMore />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeSpecificCategory;
