import React from "react";
import { useSearchParams } from "react-router-dom";
import MenuBar from "../../../../components/menu";
import HomeAllCategories from "./all";
import HomeSpecificCategory from "./specific";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCategoryStore } from "../../../../configs/stores/categories";

const HomePage = () => {
  const categoryTypes = useBasicsStore((e) => e.categoryTypes);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryTypeSlug = useCategoryStore((e) => e.categoryTypeSlug);
  const updateCategoryTypeSlug = useCategoryStore(
    (e) => e.updateCategoryTypeSlug
  );

  React.useEffect(() => {
    if (!searchParams.get("category")) {
      searchParams.set("category", "all");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const categoryQuery = searchParams.get("category");
    if (
      categoryTypes.length !== 0 &&
      !!categoryQuery &&
      categoryQuery !== "all"
    ) {
      const exist = categoryTypes.find(
        (cat) =>
          cat.categorytype_slug.toString() ===
          (categoryQuery ?? categoryTypeSlug)
      );
      if (!exist) {
        searchParams.set("category", "all");
        setSearchParams(searchParams);
      } else {
        updateCategoryTypeSlug(categoryQuery);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTypes]);

  return (
    <React.Fragment>
      <div className="flex flex-col w-full space-y-6 pb-4 max-md:pt-[25px] max-xl:pt-[40px] xl:pt-[60px]">
        <MenuBar />
        {categoryTypeSlug === "all" && <HomeAllCategories />}
        {categoryTypeSlug !== "all" && <HomeSpecificCategory />}
      </div>
    </React.Fragment>
  );
};

export default HomePage;
