import React from "react";
import { useLocation } from "react-router-dom";
import { useGetScrollPos } from "../../functions/useGetScrollPos";
import LogoHeader from "./logo";
import AccountHeader from "./accountHeader";
import AddEtablissment from "./addEtb";
import JobsLink from "./jobsLink";
// import GoToMap from "./map";
import SearchInput from "./search";
import SearchIconButton from "./search/icon";

const Header = () => {
  const { atZero } = useGetScrollPos();
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <header
        className={`flex flex-row bg-white h-[60px] xl:h-[70px] fixed z-[999] top-0 left-0 w-full border-b justify-center ${
          !atZero && pathname !== "/" ? "shadow-md" : ""
        }`}
      >
        <div className="flex  flex-row h-full w-full px-4 xl:px-5 2xl:px-20 justify-between items-center max-w-[2520px]">
          <LogoHeader />
          <SearchInput />
          <div className="flex flex-row space-x-3 max-md:space-x-[12px] items-center">
            <SearchIconButton />
            {/* <GoToMap /> */}
            <JobsLink />
            <AddEtablissment />
            <AccountHeader />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
