import React from "react";
import CoinMenuCard from "./menucard";
import { useCoinStore } from "../../../../../../../configs/stores/coin";

const CoinMenuCards = () => {
  const coin = useCoinStore((e) => e.coin);
  const menucards = coin?.menucards ?? [];
  return (
    <div
      className="flex flex-col px-2 mt-5 space-y-5"
      style={{
        display: menucards.length !== 0 ? "flex" : "none",
      }}
    >
      <span className="flex text-[20px] leading-tight font-medium">
        Explorez les menus en parcourant les cartes :
      </span>
      <div className="flex flex-col space-y-4">{/* w-[90%]">*/}
        {menucards.map((e, i) => {
          return (
            <CoinMenuCard
              key={e.menucard.menucard_id.toString()}
              menucard={e}
            />
          );
        })}
      </div>
      <div className="flex w-full mx-3 my-2 mb-0 self-center h-[1px] bg-black/10" />
    </div>
  );
};

export default CoinMenuCards;
