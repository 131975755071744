import React from "react";
import ImgeSvg from  "../../../../assets/emptyList.svg"

const SearchCompanyNoResults = () => {
  return (
    <div className="flex flex-1 select-none space-y-5 flex-col items-center justify-center">
        <img src={ImgeSvg} alt="ImgeSvg" className="flex h-[100px]" />
      <span className="flex text-[15px] text-center px-5 max-w-[340px]">
        Aucun établissement ne correspond aux termes de recherche spécifiés
      </span>
    </div>
  );
};

export default SearchCompanyNoResults;
