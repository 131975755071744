import { getUrl } from "../../apiRequest";

export function getDefaultDatas() {
  return getUrl("/default/all");
}

export function getAllCategoryType() {
  return getUrl("/default/categorytypes");
}

export function getAllLangs() {
  return getUrl("/default/langs");
}

export function getAllCountries() {
  return getUrl("/default/countries");
}
