import React from "react";
import ImagesCarroussel from "./caroussel";
import CoinDescription from "./description";
import CoinConvenience from "./convenience";
import CoinServices from "./services/services";
import CoinLocalisation from "./localisation";
import CoinSocialnetwork from "./socialNetwork";
import CoinEvaluation from "./evaluation";
import CoinComment from "./comment";
import CoinReservationForm from "./reservationform";
import CoinMenuCards from "./menus";
import CoinPlace from "./place";

const CoinBodyLaptop = () => {
  return (
    <div className="hidden xl:flex flex-col w-full space-y-5 items-center">
      <div className="flex self-center flex-col space-y-7 w-[85%] max-w-[1500px] ">
        <ImagesCarroussel />
        <div className="flex flex-row justify-between mt-[15px] space-x-[30px]">
          <div className="flex flex-1 flex-col bg-white px-6 rounded-[18px] py-5 pt-8">
            <CoinDescription />
            <CoinPlace />
            <CoinConvenience />
            <CoinMenuCards />
            <CoinServices />
            <CoinLocalisation />
            <CoinSocialnetwork />
            <CoinEvaluation />
            <CoinComment />
            <a href="/" className="flex w-fit mt-10">
              <button className=" flex flex-row space-x-2 items-center py-[10px] px-2 w-fit rounded-md hover:bg-black/5 active:bg-black/10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  className="w-4 h-4 stroke-primary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                  />
                </svg>
                <span className="flex font-medium text-[18px] text-primary">
                  Retour à l'accueil
                </span>
              </button>
            </a>
          </div>
          <CoinReservationForm />
        </div>
      </div>
    </div>
  );
};

export default CoinBodyLaptop;
