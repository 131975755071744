import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  CategoryModel,
  CommentModel,
  CompanyModel,
  ImageModel,
  MenuCardModel,
  MenuModel,
  PlaceModel,
  ReservationModel,
  ServiceModel,
} from "../../models/models";
import {
  checkPlaces,
  getAllComment,
  getCoinDatas,
  getConnexeCategoriesByTypeSlug,
  newComment,
  newReservation,
  updateEvaluation,
  updateToPaiedReservation,
} from "./functions/coin";
import { ToastWarnNotifier } from "../../constants";
import { CategoryDataInterface } from "./categories";
import { encrypt } from "../encryption";
import moment from "moment";

const LOCAL_DATASTORE_NAME = "coinchicclient-coin";

export type CategoryDatas = {
  category: CategoryModel;
  categories: Array<CategoryModel>;
  company: CompanyModel;
  images: Array<ImageModel>;
  menucards: Array<{ menucard: MenuCardModel; menus: Array<MenuModel> }>;
  services: Array<ServiceModel>;
  evaluation: string | String;
  evaluationlenght: string | String;
  userevaluation: string | String;
  comments: number;
  places: Array<PlaceModel>;
};

type ContextProps = {
  isLoading: boolean;
  isLoadingConnexeCategories: boolean;
  isLoadingComments: boolean;
  isUpdating: boolean;
  coin: CategoryDatas | null;
  cardPaymentForm: {
    ville: string;
    adresse: string;
    code_postal: string;
  };
  addedServices: Array<ServiceModel>;
  comments: Array<CommentModel>;
  connexes: Array<CategoryDataInterface>;
  addedPlaces: Array<{ place: PlaceModel; length: number }>;
  getCoinData: (
    companySlug: string,
    category?: string,
    user?: string
  ) => Promise<any>;
  addService: (service: ServiceModel) => Promise<any>;
  removeService: (serviceId: string) => Promise<any>;
  addPlace: (place: PlaceModel) => Promise<any>;
  incrementPlace: (placeId: string) => Promise<any>;
  decrementPlace: (placeId: string) => Promise<any>;
  removePlace: (placeId: string) => Promise<any>;
  updateEvaluation: (value: string) => Promise<any>;
  newComment: (comment: string, response?: string) => Promise<any>;
  getComments: () => Promise<any>;
  getConnexes: (max?: number) => Promise<any>;
  checkPlaces: (
    day: string,
    places: Array<{ place: string; length: number }>
  ) => Promise<{
    success: boolean;
    data: Array<{
      place: string;
      request: number;
      remainder: number;
    }>;
  }>;
  setCardPaymentForm: (slug: string, value: string) => void;
  newReservation: (data: any) => Promise<ReservationModel | null>;
  updateToPaiedReservation: (data: any) => Promise<ReservationModel | null>;
};

export const useCoinStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      coin: null,
      isLoading: false,
      isLoadingConnexeCategories: false,
      isLoadingComments: false,
      isUpdating: false,
      addedServices: [],
      addedPlaces: [],
      comments: [],
      connexes: [],
      cardPaymentForm: {
        ville: "",
        adresse: "",
        code_postal: "",
      },
      getCoinData: async (companySlug, categorie, user) => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getCoinDatas(companySlug, categorie, user);
        const data = (res?.data ?? {}) as CategoryDatas;
        set(
          produce((state: ContextProps) => {
            state.coin = data;
            state.addedPlaces = state.addedPlaces.filter(
              (e) =>
                e.place.place_category.category_id === data.category.category_id
            );
            state.addedServices = state.addedServices.filter(
              (e) =>
                e.service_category.category_id === data.category.category_id
            );
            state.comments = state.comments.filter(
              (e) =>
                e.comment_category.category_id === data.category.category_id
            );
            state.isLoading = false;
          })
        );

        const state = get();
        state.getConnexes();
        state.getComments();
      },
      addService: async (service) => {
        set(
          produce((state: ContextProps) => {
            state.addedServices = state.addedServices
              .filter((e) => e.service_id !== service.service_id)
              .concat(service);
          })
        );
      },
      removeService: async (serviceId) => {
        set(
          produce((state: ContextProps) => {
            state.addedServices = state.addedServices.filter(
              (e) => e.service_id !== serviceId
            );
          })
        );
      },
      addPlace: async (place) => {
        set(
          produce((state: ContextProps) => {
            state.addedPlaces = state.addedPlaces
              .filter((e) => e.place.place_id !== place.place_id)
              .concat({ place, length: 1 });
          })
        );
      },
      incrementPlace: async (placeId) => {
        const state = get();
        const place = state.addedPlaces.find(
          (e) => e.place.place_id === placeId
        );
        if (!place) {
          return;
        }
        set(
          produce((state: ContextProps) => {
            state.addedPlaces = state.addedPlaces.map((e) => {
              if (place.place.place_id === e.place.place_id) {
                return { ...e, length: e.length + 1 };
              }
              return e;
            });
          })
        );
      },
      decrementPlace: async (placeId) => {
        const state = get();
        const place = state.addedPlaces.find(
          (e) => e.place.place_id === placeId
        );
        if (!place) {
          return;
        }
        if (place.length > 1) {
          set(
            produce((state: ContextProps) => {
              state.addedPlaces = state.addedPlaces.map((e) => {
                if (place.place.place_id === e.place.place_id) {
                  return { ...e, length: e.length - 1 };
                }
                return e;
              });
            })
          );
        } else {
          state.removePlace(place.place.place_id);
        }
      },
      removePlace: async (placeId) => {
        set(
          produce((state: ContextProps) => {
            state.addedPlaces = state.addedPlaces.filter(
              (e) => e.place.place_id !== placeId
            );
          })
        );
      },
      updateEvaluation: async (value) => {
        const state = get();
        const category = (state.coin?.category ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.isUpdating = true;
          })
        );
        const res = await updateEvaluation(
          value,
          category.category_id.toString()
        );
        const data = res?.data ?? {
          evaluation: "0",
          evaluationlength: "0",
          userevaluation: "0",
        };
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.coin = {
                ...state.coin,
                evaluation: data.evaluation,
                evaluationlength: data.evaluationlength,
                userevaluation: data.userevaluation,
              } as CategoryDatas;
            }
            state.isUpdating = false;
          })
        );
      },
      getComments: async () => {
        const state = get();
        const category = (state.coin?.category ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.isLoadingComments = true;
          })
        );
        const res = await getAllComment(category.category_id.toString());
        const data = res?.data ?? Array<CommentModel>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.comments = data;
            }
            state.isLoadingComments = false;
          })
        );
      },
      getConnexes: async (max = 6) => {
        const state = get();
        const category = (state.coin?.category ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.isLoadingConnexeCategories = true;
          })
        );
        const res = await getConnexeCategoriesByTypeSlug(
          category.category_type.categorytype_slug.toString(),
          max
        );
        const data = res?.data ?? Array<CategoryDataInterface>;
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.connexes = data;
            }
            state.isLoadingConnexeCategories = false;
          })
        );
      },
      newComment: async (comment, response) => {
        const state = get();
        const category = (state.coin?.category ?? {}) as CategoryModel;
        const res = await newComment(
          comment,
          category.category_id.toString(),
          response
        );
        const data = (res?.data ?? {}) as CommentModel;
        state.getComments();
        set(
          produce((state: ContextProps) => {
            if (res.success) {
              state.coin = {
                ...state.coin,
                comments: state.comments.concat(data).length,
              } as CategoryDatas;
              state.comments = state.comments.concat(data);
            }
          })
        );
      },
      checkPlaces: async (day, places) => {
        const state = get();
        const category = (state.coin?.category ?? {}) as CategoryModel;
        const res = await checkPlaces(
          category.category_id.toString(),
          day,
          places
        );
        const data = (res?.data ?? []) as Array<{
          place: string;
          request: number;
          remainder: number;
        }>;
        if (!res.success) {
          let placeIdNames = state.addedPlaces.map((e) => ({
            place: e.place.place_id,
            name: e.place.place_name,
          }));
          const dayMoment = moment(day);
          let from = "";
          let to = "";
          if (dayMoment.isoWeekday() >= 1 && dayMoment.isoWeekday() <= 5) {
            // console.log("C'est un jour de semaine.")
            const premierJourSemaine = dayMoment.clone().startOf("week");
            const cinquiemeJourSemaine = dayMoment
              .clone()
              .startOf("isoWeek")
              .add(4, "days");
            from = premierJourSemaine.format("DD/MM/YYYY");
            to = cinquiemeJourSemaine.format("DD/MM/YYYY");
          } else {
            // console.log("C'est un week-end.");
            const sixiemeJourSemaine = dayMoment
              .clone()
              .startOf("isoWeek")
              .add(5, "days");
            const dernierJourSemaine = dayMoment
              .clone()
              .startOf("isoWeek")
              .add(6, "days");
            from = sixiemeJourSemaine.format("DD/MM/YYYY");
            to = dernierJourSemaine.format("DD/MM/YYYY");
          }
          data.forEach((e) => {
            let nameE =
              placeIdNames.find((n) => n.place === e.place)?.name ??
              "Table inexistante";
            ToastWarnNotifier({
              message: `${nameE}, ${e.remainder} places restantes du ${from} au ${to}. Reéssayez dans 5 minutes.`,
            });
          });
        }
        return { success: Boolean(res.success), data };
      },
      setCardPaymentForm: (slug, value) => {
        set(
          produce((state: ContextProps) => {
            state.cardPaymentForm = { ...state.cardPaymentForm, [slug]: value };
          })
        );
      },
      newReservation: async (data) => {
        if (!data) {
          ToastWarnNotifier({
            message: "Erreur survenue",
          });
          return;
        }
        const res = await newReservation(data);
        const result = res.data;
        return result;
      },
      updateToPaiedReservation: async (data) => {
        if (!data) {
          ToastWarnNotifier({
            message: "Erreur survenue",
          });
          return;
        }
        const enc = encrypt(
          { token: data },
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        );
        const res = await updateToPaiedReservation({ data: enc });
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.addedPlaces = [];
              state.addedServices = [];
            })
          );
        }
        const result = res.data;
        return result;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_cn")),
    }
  )
);
