import React from "react";
import { useRefLayoutDimension } from "../../../../../../functions/useLayoutDimension";
import ImagesCarroussel from "./carousel";
import CoinDescription from "./description";
import CoinConvenience from "./convenience";
import CoinServices from "./services";
import CoinLocalisation from "./localisation";
import CoinSocialnetwork from "./socialNetwork";
import CoinEvaluation from "./evaluation";
import CoinComment from "./comment";
import CoinReserver from "./reservationform";
import OurContactModal from "../../../../../../components/modals/models/ourcontacts";
import { useCoinStore } from "../../../../../../configs/stores/coin";
import CoinPlace from "./place";
import CoinMenuCards from "./menus";

const CoinBodyMobile = () => {
  const { element, width } = useRefLayoutDimension<HTMLDivElement>();
  const coin = useCoinStore((e) => e.coin);
  const images = coin?.images ?? [];
  const [isOpen, setisOpen] = React.useState(false);
  function closeLogin() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div
        ref={element}
        className="flex flex-col xl:hidden w-full bg-white pb-6"
      >
        <ImagesCarroussel
          images={images}
          height={(width * 3) / 3.3}
          width={width}
        />
        <CoinDescription />
        <CoinPlace />
        <CoinConvenience />
        <CoinMenuCards />
        <CoinServices />
        <CoinLocalisation />
        <CoinSocialnetwork />
        <CoinEvaluation />
        <CoinComment />
        <div
          onClick={() => setisOpen(true)}
          className="flex self-auto md:self-center md:min-w-[450px] mt-6 flex-row mx-5 items-center justify-center gap-2 py-2 pr-[35px] border rounded-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={"black"}
            className="w-5 h-5 m-2"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd"
            />
          </svg>
          <span className="flex text-[19px] font-medium">Contactez-nous</span>
        </div>
        <a href="/" className="flex flex-col w-full mt-6">
          <button className="flex self-auto md:self-center md:min-w-[450px]flex-row mx-5 items-center justify-center gap-2 py-[10px] px-5  bg-primary active:bg-active rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              className="w-5 h-5 stroke-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
              />
            </svg>
            <span className="flex text-[17px] font-medium text-white">
              Retour à l'accueil
            </span>
          </button>
        </a>
        <CoinReserver />
      </div>
      <OurContactModal open={isOpen} handleClose={closeLogin} />
    </React.Fragment>
  );
};

export default CoinBodyMobile;
