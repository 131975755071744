import React from "react";
import { MenuModel } from "../../../../../../../../../models/models";

type Props = {
  menu: MenuModel;
};

const CoinMenu = ({ menu }: Props) => {
  return (
    <div className="keen-slider__slide number-slide bg-blue-500 max-w-[250px] min-w-[250px] w-[250px] h-[250px] flex flex-col border rounded-xl relative overflow-hidden">
      <img
        className="flex flex-1 object-cover bg-blue-700"
        src={menu.menu_image.image_link.toString()}
        alt={menu.menu_id.toString()}
      />
      <div className="flex absolute text-white leading-tight bg-black/25 w-full bottom-0 flex-col px-2 pl-4 pr-3 pt-2 pb-3 space-y-1 overflow-y-auto">
        <span className="flex text-[17px] font-[600] line-clamp-2">{menu.menu_name}</span>
        <span className="flex text-[18px] font-[600]">{menu.menu_price} FCFA</span>
      </div>
    </div>
  );
};

export default CoinMenu;
