import React from "react";
import ModalCustom from "../../modal";
import TextInputSign from "../../../inputs/textInput";
import PasswordInputSign from "../../../inputs/passwordInput";
import Logo from "../../../../assets/logoicon.png";
import { ToastErrorNotifier } from "../../../../constants";
import { useAuthStore } from "../../../../configs/stores/auth";

const LoginModal = ({ handleClose = () => {}, open = false }) => {
  const login = useAuthStore((e) => e.login);
  const isLoading = useAuthStore((e) => e.isLoadingLogin);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const [formData, setFormData] = React.useState({
    login: "",
    password: "",
    rememberMe: false,
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.login.length === 0) {
      errorOccured = true;
      let msg = "Email ou Téléphone incorrecte";
      ToastErrorNotifier({ message: msg });
    }
    if (formData.password.length === 0) {
      errorOccured = true;
      let msg = "Mot de passe incorrecte";
      ToastErrorNotifier({ message: msg });
    }
    return errorOccured;
  }
  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    let data = {
      login: formData.login,
      password: formData.password,
    };
    login(data);
  }
  React.useEffect(() => {
    if (isAuthenticated) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <ModalCustom open={open} handleClose={!isLoading ? handleClose : undefined}>
      <form
        className="flex flex-col p-5 pb-3 pt-2 space-y-4 max-sm:min-w-[90vw] max-sm:max-w-[400px] w-[400px] max-sm:w-full"
        onSubmit={submitForm}
      >
        <div className="flex w-full justify-center pt-3">
          <img className="flex h-12" src={Logo} alt="logo" />
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center text-2xl font-bold tracking-tight max-sm:text-xl">
            Connectez-vous !
          </h2>
        </div>
        <TextInputSign
          type={"text"}
          placeholder="Email ou Téléphone"
          onTextChange={onValueChange("login")}
        />
        <PasswordInputSign
          placeholder="Mot de passe"
          errorState={{ state: false, msg: "" }}
          onTextChange={onValueChange("password")}
        />
        <button
          disabled={isLoading}
          type="submit"
          className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-white text-[18px] font-medium bg-primary hover:bg-hover rounded-[20px] shadow-sm border"
        >
          <span className="-mt-[1px]">
            {!isLoading ? "Se connecter" : "Connexion..."}
          </span>
        </button>
        <div className="flex flex-col items-center space-y-4">
          <a
            href={"/signup"}
            className="flex text-[15px] w-fit underline underline-offset-4 font-medium text-[#F08400]"
          >
            Créer un compte
          </a>
          <a
            href={"/forgot-password"}
            className="flex w-fit text-[15px] cursor-pointer font-semibold text-darkgrey/70 active:text-darkgrey/40"
          >
            Mot de passe oublié?
          </a>
        </div>
      </form>
    </ModalCustom>
  );
};

export default LoginModal;
