import React from "react";
import {
  MenuCardModel,
  MenuModel,
} from "../../../../../../../../models/models";
import "./style.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useDimensions } from "../../../../../../../../hooks/useDimensions";
import CoinMenu from "./menu/index";

type Props = {
  menucard: { menucard: MenuCardModel; menus: Array<MenuModel> };
};

const CoinMenuCard = ({ menucard: { menucard, menus } }: Props) => {
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const [isOpen, setisOpen] = React.useState(false);
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  return (
    <div className="flex flex-col w-full">
      <div
        onClick={() => setisOpen(!isOpen)}
        className="flex cursor-pointer select-none items-start flex-row bottom-0 w-full space-x-2"
      >
        <div className="flex flex-row space-x-3 items-start">
          <div className="flex min-h-[40px] h-[40px] min-w-[40px] w-[40px] border rounded-full overflow-hidden">
            <img
              className="flex h-full w-full"
              src={menucard.menucard_image.image_link.toString()}
              alt={menucard.menucard_id.toString()}
            />
          </div>
          <div className="flex flex-col space-y-1 pt-2">
            <span className="flex text-black text-[18.5px] font-semibold leading-none">
              {menucard.menucard_name}
            </span>
            <div className="flex flex-col leading-none">
              <span className={`text-black leading-tight text-[13px] ${isOpen?"":"line-clamp-2"}`}>
                {menucard.menucard_description}
              </span>
              <span className="text-black leading-tight line-clamp-2 text-[13px] underline">
                Voir plus
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row space-x-2 pt-4"
      >
        <div className="flex flex-row w-full py-2">
          <div ref={refDim} className="flex flex-1">
            <div
              ref={ref}
              className="keen-slider flex"
              style={{ maxWidth: dimensions?.width ?? 10 }}
            >
              {menus.map((e) => {
                return <CoinMenu menu={e} key={e.menu_id.toString()} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinMenuCard;
